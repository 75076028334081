import FlexBox from '@/atomic-components/flex-box';
import DownArrowIcon from '@/assets/navigations/down-arrow';
import { useState } from 'react';
import { LencoImage } from '@/atomic-components/image';
import { Box, Typography } from '@mui/material';
import { getStyle } from '@/utils/getStyle';
import { getColor } from '@/utils/getColor';

type CustomAccordionProps = {
	icon?: any;
	title: {
		name: string;
		style: string;
		color: string;
	};
	children: any;
	image?: any;
	divider?: boolean;
	showBackground?: boolean;
	expandCategories?: () => void;
	height: string;
	parent?: boolean;
};

const CustomAccordion = (props: CustomAccordionProps) => {
	const openCategories = props.height === 'initial';

	const backgroundColor = props.showBackground ? getColor('Minty -6') : '';

	const { name: title, style, color } = props.title;

	return (
		<Box
			sx={{
				width: '100%',
				overflow: 'hidden',
				height: props.height,
			}}
		>
			<FlexBox
				direction='column'
				sx={{
					alignItems: 'center',
					justify: 'center',
					background: props.showBackground ? getColor('Minty -6') : 'none',
				}}
			>
				<FlexBox
					sx={{
						justifyContent: 'space-between',
						width: '100%',
						alignItems: 'center',
					}}
					onClick={(e: any) => {
						e.preventDefault();
						props.expandCategories && props.expandCategories();
					}}
				>
					<FlexBox justify='start' sx={{ alignItems: 'center' }}>
						{props?.icon ? (
							<FlexBox
								sx={{
									paddingTop: '6px',
								}}
							>
								{props.icon}
							</FlexBox>
						) : (
							<Box
								sx={{
									width: '40px',
								}}
							>
								<LencoImage
									url={props?.image?.url}
									aspectRatio={props?.image?.aspectRatio}
								/>
							</Box>
						)}
						<Typography
							sx={{
								...getStyle(style),
								color: getColor(color),
							}}
						>
							{title}
						</Typography>
					</FlexBox>
					<Box
						sx={{
							paddingRight: '8px',
						}}
					>
						{openCategories ? (
							<Box sx={{ transform: openCategories ? 'rotate(180deg)' : '0' }}>
								<DownArrowIcon strokeColor={'#069BAA'} />
							</Box>
						) : (
							<Box sx={{ transform: openCategories ? 'rotate(180deg)' : '0' }}>
								<DownArrowIcon strokeWidth={!props?.parent ? '1.5' : ''} />
							</Box>
						)}
					</Box>
				</FlexBox>
				{props?.divider && (
					<Box
						sx={{
							borderBottom: props?.parent
								? `1px solid ${getColor('Gray -5')}`
								: `1px solid ${getColor('Minty -5')}`,
							width: '100%',
						}}
					/>
				)}
			</FlexBox>

			{props.children}
		</Box>
	);
};

export default CustomAccordion;
