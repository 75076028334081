import { unlmtdPlanService } from '@/services/unlmtd/plan';
import { getPlanFulfilled, getPlanPending, getPlanRejected, PlanSelectorStatus, showOrHidePlanSelectorSlice,setSelectedPlanIdInStore } from '@/store/unlmtd/slices/planConfigSlice';
import { getDataFromLocalStorage } from '@/utils/DataToLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import useSubscription from '../useSubscription';
import { useState } from 'react';


type PlanHooks = {
    selectedPlanId:any,
    plans:any,
    currentPlan:any,
    planSelectorStatus:any,
    planActions:{
        getPlans:any,
        setPlan:any,
        removePlan:any,
        getPlanApiState:any,
        showOrHidePlanSelector:any
    },
}



const usePlan = () : PlanHooks => {
    const plan = useSelector((state: any) => state?.plans);
    const currentselectedPlan = useSelector((state: any) => state?.plans?.selectedPlanId) 
    const {subscriptionActions} = useSubscription();
    const setSelectedPlanId = (id:string | null)=>{
        dispatch(setSelectedPlanIdInStore(id))
    }

    const selectedPlanId = currentselectedPlan || getDataFromLocalStorage('selectedPlan') as string || null;
   

   const planSelectorStatus = useSelector((state: any) => state?.plans?.planSelectorStatus);

   

   let currentPlan;

    const dispatch = useDispatch();

    const getPlanApiState = () =>{
        return plan?.status
    }

    const refetchPlanId = ()=>{
        setSelectedPlanId(getDataFromLocalStorage('selectedPlan') as string || '0')
    }

    const getPlans = async () => {
        dispatch(getPlanPending());
        try {
            const response = await unlmtdPlanService.getPlans(selectedPlanId as string)
            dispatch(getPlanFulfilled(response.data?.data?.plans));
            if(response.data?.data?.subscribedPlan){
                // setSubscribedPlan(response.data?.data?.subscribedPlan)
                setSelectedPlanId(response.data?.data?.subscribedPlan?.id)
                await subscriptionActions.getSubscription() 
            }
            currentPlan = response.data?.data?.plans?.filter((plan: any) => plan.id == selectedPlanId)[0];
        } catch (error: any) {
            dispatch(getPlanRejected(error.toString()));
        }
    }
   
    const setPlan = (planId: string) => {
        setSelectedPlanId(planId);
        localStorage.setItem('selectedPlan', planId);
    }

    const removePlan = () => {
        localStorage.removeItem('selectedPlan');
        setSelectedPlanId(null);
    }

    const showOrHidePlanSelector = (action:keyof typeof PlanSelectorStatus) => {
        dispatch(showOrHidePlanSelectorSlice(action))
    }
    return {
        selectedPlanId,
        plans:plan?.plans,
        currentPlan,
        planSelectorStatus,
        planActions:{
            getPlans,
            setPlan,
            removePlan,
            getPlanApiState,
            showOrHidePlanSelector
        }
    };
};

export default usePlan;