import { Box } from '@mui/system';
import { useRouter } from 'next/router';
import { LencoImage } from '@/atomic-components/image';
import FlexBox from '@/atomic-components/flex-box';
import { memo } from 'react';

function LogoHeader(props: { page?: string; pincode?: String }) {
	// const [pincode, setPincode] = useState<string | null>();
	const router = useRouter();

	function navigateToHome() {
		if (router.route != '/') {
			router.push('/');
		}
	}

	return (
		<FlexBox align='center' width='100%' onClick={navigateToHome}>
			<Box mt='14px' mb='4px' height='14px' width='130px'>
				<LencoImage
					lazy={false}
					url={
						'https://assets.furlenco.com/s3-furlenco-images/grogu/evolve-mweb-header-logo.png'
					}
				/>
			</Box>
		</FlexBox>
	);
}

export default memo(LogoHeader);
