import { useLencoSelector } from '@/cutom-hooks/use-lenco-selector';
import { AppConfig } from '@/store/appConfig/reducer';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import { APP_HISTORY } from '@/store/appConfig/actionTypes';
import { useRouter } from 'next/router';

type UseAppConfig = {
  appConfig: AppConfig;
  appConfigActions: {
    addHistory: (url: string) => void;
    goBackOrGoHome: () => void; // go to home, if app history is empty (user landed on current page directly), else go back
  };
};

export function useAppConfig(): UseAppConfig {
  const dispatch = useLencoDispatch();
  const appConfig = useLencoSelector((store) => store.appConfig);
  const router = useRouter();

  function addHistory(url: string) {
    dispatch({
      type: APP_HISTORY.ADD_TO_APP_HISTORY,
      payload: url
    });
  }
  // made some changes in this function
  function goBackOrGoHome() {
    if (!appConfig.history || appConfig.history.length === 0) {
      router.push('/');
    } else {
      router.back();
    }
  }
  

  return {
    appConfig,
    appConfigActions: {
      addHistory,
      goBackOrGoHome
    }
  };
}