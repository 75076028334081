import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import FlexBox from '../../../atomic-components/flex-box';
import { getStyle } from '../../../utils/getStyle';
import OTPInput from './otp-input';
import { getColor } from '../../../utils/getColor';
import { startCounter } from '../../../utils/common';
import WriteSymbol from '../../../assets/login/pen.svg';
import { authService } from '@/services/auth';
import LencoButton from '@/atomic-components/button';
import useLencoState from '@/cutom-hooks/useLencoState';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import useMoengage from '@/hooks/useMoengage';
import { AuthFlowSteps } from '..';
import { useUser } from '@/hooks/useUser';
import { NotificationSeverity } from '@/components/NotifyToast';
import { useNotification } from '@/hooks/useNotification';
import { emitMoeEvent } from '@/utils/events';

const VerifyOTP = ({
	setCurrentStep,
	userDetails,
	setUserDetails,
	resendOTP,
	onLoginSuccess,
}: any) => {
	const counterRef = useRef(null);
	const [otp, setOTP] = useState('');
	const [isTimeOver, setIsTimeOver] = useState(false);
	const isVerifyingOtp = useLencoState<boolean>(false);
	const [error, setError] = useState('');
	const [count, setCount] = useState(0);
	const moengage = useMoengage();
	const { userActions } = useUser();
	const { notifyActions } = useNotification();

	const accountInfo = userDetails.email
		? userDetails.email
		: `+91 ${userDetails.mobile}`;

	const isSubmitDisabled = useMemo(
		() => otp?.length != userDetails?.otp_length || isVerifyingOtp.value,
		[otp.length, isVerifyingOtp.value]
	);

	let setTimeIntervalCount: any;
	function resetTimer() {
		setIsTimeOver(false);
		setTimeIntervalCount = startCounter(0.5, counterRef, () => {
			setIsTimeOver(true), setCount(0), setError('');
		});
	}

	function handleResend() {
		resendOTP(userDetails);
		setCount(count + 1);
		setError('');
		resetTimer();
	}

	async function verifyOTP() {
		emitMoeEvent('OTP Submit Clicked', {
			type: 'phone',
		});

		if (otp.length < 4) {
			notifyActions.notifyUser({
				severity: NotificationSeverity.ERROR,
				message: 'Invalid OTP entered',
			});
			return;
		}

		const account = !!userDetails?.email
			? userDetails?.email
			: userDetails?.mobile;
		var payload = {
			account: account,
			otp: otp,
		};

		try {
			isVerifyingOtp.setValue(true);
			const res = await authService.verifyOtp(payload);
			const data = res.data?.data;

			if (!!data) {
				const hasRegistered = data.has_registered;

				moengage.updateUserId(res.data?.data?.user?.id as number);

				setUserDetails((prev: any) => ({
					...prev,
					has_registered: hasRegistered,
				}));

				if (data && hasRegistered) {
					emitMoeEvent('Login Completed', {
						login_type: 'old',
					});

					notifyActions.notifyUser({
						severity: NotificationSeverity.SUCCESS,
						message: 'Successfully logged in',
					});
					userActions.getUser();
					onLoginSuccess && onLoginSuccess();
				} else if (data && !hasRegistered) {
					setCurrentStep(AuthFlowSteps.EMAIL_LOGIN);
				} else if (error) {
					setError('');
				}
				setError('');
			}
		} catch (err) {
			const error = (err as any).response?.data?.error;
			setCount(0);
			setError(error?.message);
		}
		isVerifyingOtp.setValue(false);
	}

	useEffect(() => {
		setTimeIntervalCount = startCounter(0.5, counterRef, () =>
			setIsTimeOver(true)
		);

		return () => {
			clearInterval(setTimeIntervalCount);
		};
	}, []);

	return (
		<Box>
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '30px',
					fontFamily: 'Recline',
					fontStyle: 'normal',
					lineHeight: '0',
					marginBottom: '8px',
					color: `${getColor(`Gray 0`)}`,
				}}
			>
				Verify{' '}
			</Typography>
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '30px',
					fontFamily: 'Recline',
					fontStyle: 'normal',
					marginBottom: '12px',
					color: `${getColor(`Gray 0`)}`,
				}}
			>
				using
				<Typography
					component='span'
					sx={{
						color: getColor('Minty -1'),
						marginLeft: '8px',
						fontWeight: '500',
						fontSize: '30px',
						fontFamily: 'Recline',
						fontStyle: 'normal',
						marginBottom: '12px',
					}}
				>
					OTP
				</Typography>
			</Typography>
			<FlexBox
				direction='row'
				sx={{
					justifyContent: 'start',
					alignItem: 'start',
					marginBottom: '32px',
				}}
			>
				<Typography
					sx={{
						...getStyle('Small-Medium'),
						color: `${getColor('Gray -3')}`,
						lineHeight: '0px',
					}}
				>
					Sent to {accountInfo}{' '}
				</Typography>
				{!userDetails.email && (
					<Box
						sx={{ lineHeight: '0px', marginLeft: '4px' }}
						onClick={() => setCurrentStep(AuthFlowSteps.SIGN_IN)}
					>
						<WriteSymbol />
					</Box>
				)}
			</FlexBox>

			<OTPInput
				length={userDetails.otp_length}
				autoFocus
				onChangeOTP={setOTP}
				otpError={error}
			/>
			{count > 0 && (
				<Typography
					sx={{
						...getStyle('Small-Regular'),
						marginTop: '12px',
						color: `${getColor('Success +1')}`,
					}}
				>
					OTP sent again
				</Typography>
			)}
			{error && (
				<Typography
					sx={{
						...getStyle('Small-Regular'),
						color: `${getColor('Danger +1')}`,
						marginTop: '12px',
					}}
				>
					{error}
				</Typography>
			)}
			<FlexBox mt='24px'>
				<LencoButton
					isLoading={isVerifyingOtp.value!}
					disabled={isSubmitDisabled!}
					sx={{ width: '230px' }}
					onClick={verifyOTP}
				>
					SUBMIT
				</LencoButton>
			</FlexBox>
			<FlexBox sx={{ width: '100%' }}>
				<Typography
					sx={{
						...getStyle('Small-Regular'),
						marginTop: '16px',
						marginLeft: '0px',
						color: isTimeOver ? getColor('Minty -1') : '#666666',
						textAlign: 'center',
					}}
					onClick={() => isTimeOver && handleResend()}
				>
					Resend OTP{' '}
				</Typography>
				{!isTimeOver && (
					<Typography
						sx={{
							...getStyle('Small-SemiBold'),
							margin: '16px 0px 0px 12px',
							color: '#666666',
							textAlign: 'center',
						}}
						ref={counterRef}
					>
						00:30
					</Typography>
				)}
			</FlexBox>
		</Box>
	);
};

export default VerifyOTP;
