import { ICart } from '@/models/cart/get-cart';
import { IGenericApiResponse } from '@/models/generics';

export enum CART_TYPE {
  RENT = 'RENTAL',
  BUY = 'BUY',
}


export enum LineOfProduct {
  RENT = "RENT",
  BUY_NEW = 'BUY_NEW',
  BUY_REFURBISHED = 'BUY_REFURBISHED'
}


export type GetCartApiResponseData = { cart: ICart; };
export type GetCartApiResponse = IGenericApiResponse<GetCartApiResponseData>;