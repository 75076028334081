
import FlexBox from "@/atomic-components/flex-box";
import { LencoImage } from "@/atomic-components/image";
import PageWrapper from "@/components/unlmtd/page-wrapper";
import SubscriptionCard from "@/components/unlmtd/subscription/subscription-card";
import useSubscription from "@/hooks/unlmtd/useSubscription";
import { getOS } from "@/utils/getOS";
import { getStyle } from "@/utils/getStyle";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import SubscriptionBack from '@/assets/unlmtd/subscription-back.svg';

const subscriptionImageStates={
  'kyc-pending': {
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/complete_kyc.png',
    href:'https://furlenco.sng.link/Aabqe/julj/2h4w'
  },
  'CANCELLATION_REQUESTED':{
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/manage_subscription.png',
    href:'https://furlenco.sng.link/Aabqe/auz8/2q23'
  },
  'TO_BE_FULFILLED':{
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/track_order.png',
    href:'https://furlenco.sng.link/Aabqe/xi5c/xrp2'
  },
  'CREATED':{
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/track_order.png',
    href:'https://furlenco.sng.link/Aabqe/xi5c/xrp2'
  },
  'FULFILLMENT_IN_PROGRESS':{
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/track_order.png',
    href:'https://furlenco.sng.link/Aabqe/xi5c/xrp2'
  },
  'ACTIVATED':{
    image:'https://assets.furlenco.com/s3-furlenco-images/unlmtd-evolve/subscription_states/manage_subscription.png',
    href:'https://furlenco.sng.link/Aabqe/auz8/2q23'
  },
  
}


function Subscriptions() {
    const router = useRouter();
    const theme=useTheme();
    const [platform, setPlatform] = useState('');
    useEffect(() => {
        setPlatform(getOS());
    },[])

    const {subscription} = useSubscription();

  const handleAppStore = () => (window.location.href = 'https://furlenco.sng.link/Aabqe/julj/2h4w?_force_redirect=1');

    if(!subscription?.subscriptionPlans?.length){
          return <Box sx={{padding:'0 24px'}}>
            <FlexBox sx={{flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'calc(100vh)'}}>
              <Typography sx={{...getStyle('H9A-Medium'),color:theme?.colors?.lencoSecondaryN5,pb:'20px'}}>Seems like you dont have any subscription at this moment</Typography>
              <Link href='/unlmtd' style={{...getStyle('H14-Medium'),border:`1px solid ${theme?.colors?.lencoPrimaryP1}`,padding:'18px',borderRadius:'30px',color:theme?.colors?.lencoSecondaryN1}}>Start Shopping</Link>
            </FlexBox>
              </Box>;
    }
    
    return (
      <Box sx={{minHeight:'100vh',width:'100vw'}}>
        {subscription?.subscriptionPlans?.filter((plan:any)=>{return plan?.subscriptionPlanState != 'CANCELLED'})?.map((subscriptionPlan:any,index:number)=>(
          <Box key={index} >
            <Link href='/'>
            <Box sx={{position:'fixed',bottom:'7%',left:'50%',transform:'translate(-50%)',zIndex:'999'}}>
                <Typography sx={{...getStyle('Small-Medium'),color:'white'}}><span><SubscriptionBack/></span> Back to Home </Typography>
            </Box>
            </Link>
            <>
            {console.log(subscriptionPlan?.subscriptionPlanState)}
            {subscriptionPlan?.status?.value == 'kyc-pending'?<Link href={subscriptionImageStates[subscriptionPlan?.status?.value as keyof typeof subscriptionImageStates].href}>
            <LencoImage lazy={false} url={subscriptionImageStates[subscriptionPlan?.status?.value as keyof typeof subscriptionImageStates].image} imageCss={{
              width:'100vw',
              height:'auto'
            }}/>
            </Link>:
            <Link href={subscriptionImageStates[subscriptionPlan?.subscriptionPlanState as keyof typeof subscriptionImageStates]?.href}>
            <LencoImage lazy={false} url={subscriptionImageStates[subscriptionPlan?.subscriptionPlanState as keyof typeof subscriptionImageStates].image} imageCss={{
              width:'100vw',
              height:'auto'
            }}/>
            </Link>
            }
            </>
            
          </Box>  
        ))}
        
      </Box>
      );
}

// Subscriptions.avoidBaseLayout = true

// Subscriptions.getLayout = (page:any) => {
//     return page;
// }




export default Subscriptions;