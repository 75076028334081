export const WishlistIcon = () => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M15.3329 22.2239C15.8216 22.2239 16.2181 22.6205 16.2181 23.1092C16.2181 23.5979 15.8216 23.9945 15.3329 23.9945L8.66684 23.9982C8.17815 23.9982 7.78158 23.6016 7.78158 23.1129C7.78158 22.6242 8.17815 22.2275 8.66684 22.2275L15.3329 22.2239ZM24 7.20993C24 9.99073 22.7711 12.3959 21.1 14.3786C18.4368 17.5389 14.6439 19.6101 13.0797 20.3696L13.0263 20.3932C12.7054 20.5415 12.3479 20.6163 12.0001 20.6163C11.6335 20.6163 11.2561 20.5331 10.9212 20.368L10.9203 20.3696C9.66785 19.7612 6.98103 18.3454 4.59208 16.182C2.13414 13.9558 0 10.9492 0 7.20993C0 5.24829 0.730177 3.45794 1.90368 2.15536C3.1008 0.827318 4.75819 0.00158691 6.58335 0.00158691C8.01722 0.00158691 9.33582 0.493711 10.4115 1.33176C11.0014 1.79129 11.5162 2.35591 11.9348 2.9997C12.3545 2.36267 12.8702 1.8037 13.456 1.34722C14.5439 0.499729 15.8707 0.00158691 17.2778 0.00158691C19.1612 0.00158691 20.8434 0.817318 22.0567 2.13175C23.262 3.43747 24 5.23773 24 7.20993Z'
				fill='#777777'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M19.7456 13.2401C21.1723 11.5471 22.2219 9.51649 22.2219 7.20994C22.2219 5.69496 21.6632 4.32173 20.7511 3.33332C19.8663 2.37481 18.643 1.77954 17.2777 1.77954C16.2735 1.77954 15.3249 2.13648 14.5462 2.74305C13.7381 3.37267 13.1076 4.27062 12.7777 5.32098C12.6937 5.58968 12.4834 5.8144 12.1942 5.9044C11.7265 6.05005 11.2289 5.78866 11.0832 5.32098C10.7501 4.26062 10.1284 3.35665 9.32108 2.72759C8.55461 2.13019 7.61165 1.77954 6.58324 1.77954C5.28279 1.77954 4.09142 2.37898 3.22272 3.34276C2.3319 4.33146 1.77783 5.70051 1.77783 7.20994C1.77783 10.3453 3.63735 12.9298 5.77936 14.8697C7.99091 16.8725 10.5134 18.2004 11.6909 18.7722L11.69 18.7738L11.7013 18.7793C11.7798 18.8184 11.885 18.8384 12 18.8384C12.1013 18.8384 12.1956 18.8229 12.2701 18.7922L12.3089 18.7722C13.7695 18.0631 17.3052 16.1358 19.7456 13.2401Z'
				fill='white'
			/>
		</svg>
	);
};
