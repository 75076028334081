type PropertyName = string | number | symbol;

export function groupBy<T>(collection: T[], iteratee: (item: T) => any): Record<string, T[]> {
  const destination: Record<string, T[]> = {};

  collection.forEach((item) => {
    const value = iteratee(item);

    if (!destination.hasOwnProperty(value)) {
      destination[value] = [];
    }
    destination[value].push(item);
  });

  return destination;
}

export function keyBy<T>(collection: T[], iteratee: (item: T) => any): Record<string, T> {
  const destination: Record<string, T> = {};

  collection.forEach((item) => {
    const value = iteratee(item);

    if (value) {
      destination[value] = item;
    }
  });

  return destination;
}

export function omit<T extends object, K extends PropertyName[]>(
  object: T | null | undefined,
  ...paths: K
): Pick<T, Exclude<keyof T, K[number]>> {
  const destination: any = { ...object };

  if (Array.isArray(paths) && paths.length > 0) {
    paths.forEach((path) => {
      delete destination[path as PropertyName];
    });
  }

  return destination as Pick<T, Exclude<keyof T, K[number]>>;
}