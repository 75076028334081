import { IWishlistProduct } from '@/components/wishlist-component/modal';
import { ApiState } from '@/models/generics';
import { IWishlistProducts } from '@/services/wishlist/models/wishlist';
import { GET_WISHLISTS } from "./actionTypes";

export interface IWishlistStore {
  loading: boolean,
  getWishlistApiState: ApiState,
  wishlists?: IWishlistProduct[];
  wishlistedProductsMap: Record<number, IWishlistProduct>;
  error: boolean,
}

const initialState: IWishlistStore = {
  loading: false,
  getWishlistApiState: ApiState.IDLE,
  wishlists: [],
  error: false,
  wishlistedProductsMap: {}
};

export default function WishlistsReducer(state = initialState, action: any): IWishlistStore {
  switch (action.type) {
    case GET_WISHLISTS.LOADING:
      return {
        ...state,
        getWishlistApiState: ApiState.LOADING,
        loading: true
      };
    case GET_WISHLISTS.SUCCESS:
      return {
        ...state,
        loading: false,
        getWishlistApiState: ApiState.SUCCESS,
        wishlists: action.payload,
        wishlistedProductsMap: action.wishlistedProductsMap
      };
    case GET_WISHLISTS.FAILED:
      return {
        ...state,
        getWishlistApiState: ApiState.FAILED,
        error: true,
      };
    default: return state;
  }
}