import { useNotification } from '@/hooks/useNotification';
import NotifyToast from '@/components/unlmtd/NotifyToast';
import { Box } from '@mui/material';

const Notifications = () => {
	const { notifications, notifyActions } = useNotification();

	function onClose(id: string) {
		notifyActions.closeNotification(id);
	}

	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: '50px',
				zIndex: 100000,
				left: '50%',
				transform: 'translate(-50%, 0)',
			}}
		>
			{notifications?.map((notification) => {
				return (
					<NotifyToast
						id={notification.id}
						key={notification.id}
						severity={notification.severity}
						message={notification.message}
						ctaText={notification?.ctaText}
						ctaLink={notification?.ctaLink}
						onClose={() => onClose(notification.id)}
					/>
				);
			})}
		</Box>
	);
};

export default Notifications;
