import { Button, Typography } from '@mui/material';
import FlexBox from '@/atomic-components/flex-box';
import { getColor } from '@/utils/getColor';
import LoaderComponent from './lenco-loader';
import { BubbleLoader } from '@/atomic-components/loaders/bounce-loader';

interface ILencoLoaderProps {
	isErrored?: boolean;
	hasError?: boolean;
	height?: string;
	sx?: any;
}

function LencoLoaderComponent(props?: ILencoLoaderProps) {
	return (
		<FlexBox
			flexDirection='column'
			justify='center'
			align='center'
			sx={{ ...props?.sx, width: '100%', height: props?.height ?? '90vh' }}
		>
			<LoaderComponent>
				<BubbleLoader />
			</LoaderComponent>
			{props?.hasError && (
				<>
					<Typography sx={{ margin: '16px', color: getColor('Gray 0') }}>
						Something went wrong!!!! Please Try Again
					</Typography>
					<Button
						sx={{
							border: `1px solid ${getColor('Gray -4')}`,
							color: getColor('Gray 0'),
							borderRadius: '24px',
							marginTop: '10%',
							padding: '8px 24px',
						}}
						onClick={() => window?.location.reload()}
					>
						RETRY
					</Button>
				</>
			)}
		</FlexBox>
	);
}

export default LencoLoaderComponent;
