import GlobalHeader from '@/components/global-header';
import { getColor } from '@/utils/getColor';
import { useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/material';

export type BaseLayoutProps = React.HTMLAttributes<any> & {
	sx?: SxProps;
};

const BaseLayout = (props: BaseLayoutProps) => {
	return (
		<Box sx={props.sx} className="fade-between sf">
			<Box
				sx={{
					background:
						'linear-gradient(180deg, #F3F3F3 0%, rgba(243, 243, 243, 0.0001) 99.38%)',
				}}
			>
				<GlobalHeader withLogo />
			</Box>
			{props.children}
		</Box>
	);
};

export default BaseLayout;
