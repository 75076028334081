import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import FlexBox from '../../../atomic-components/flex-box';
import useForm from '../../../cutom-hooks/useForm';
import { getColor } from '../../../utils/getColor';
import { getStyle } from '../../../utils/getStyle';
import axios from 'axios';
import EmailDrawer from './email-drawer';
import { useRouter } from 'next/router';
import ArrowRight from '../../../assets/login/arrow-right.svg';
import { authService } from '@/services/auth';
import {
	deleteKeyFromLocalStorage,
	setDataToLocalStorage,
} from '@/utils/DataToLocalStorage';
import LencoButton from '@/atomic-components/button';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import useLencoState from '@/cutom-hooks/useLencoState';
import { dataLayerPushWithEvent } from '@/utils/callcustomevent';
import useMoengage from '@/hooks/useMoengage';
import { AuthFlowSteps } from '..';
import { useUser } from '@/hooks/useUser';
import { NotificationSeverity } from '@/components/NotifyToast';
import { useNotification } from '@/hooks/useNotification';
import { emitMoeEvent } from '@/utils/events';
import { userService } from '@/services/user';
import WhatsAppIcon from '@/assets/whatsAppIcon.svg';
import TickIcon from '@/assets/white-tick.svg';

const formConfig = {
	email: {
		defaultValue: '',
		required: {
			isRequired: true,
			msg: 'Required',
		},
		valid: {
			regex: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/,
			msg: 'Enter a valid email!',
		},
	},
	name: {
		defaultValue: '',
		required: {
			isRequired: true,
			msg: 'Required',
		},
		valid: {
			regex: /[A-Za-z][A-Za-z][A-Za-z]+$/,
			msg: 'Name must be atleast 3 character long',
		},
	},
};

const EmailLogin = ({
	setCurrentStep,
	userDetails,
	setUserDetails,
	verifyEmailId,
	onLoginSuccess,
}: any) => {
	const [emailError, setEmailError] = useState('');
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const registeringUser = useLencoState<boolean>(false);
	const [createAccountFlag, setCreateAccountFlag] = useState(false);
	const [clearInputNameField, setClearInputNameField] = useState(false);
	const [clearInputEmailField, setClearEmailInputField] = useState(false);
	const moengage = useMoengage();
	const { userActions } = useUser();
	const { notifyActions } = useNotification();

	const [whatsAppConsentStatus, setWhatsAppConsentStatus] = useState(true);

	function updateWhatsappConsent() {
		try {
			userService.getUserWhatsAppConsent({
				user_consent: {
					opt_in: whatsAppConsentStatus,
				},
			});
		} catch (err) {
			console.log('something went wrong', err);
		}
	}

	async function verify() {
		emitMoeEvent('New Signup Details Submitted');

		registeringUser.setValue(true);
		const payload = {
			contact_no: userDetails.mobile,
			email_id: values.email,
			name: values.name,
		};

		try {
			const res = await authService.registerUser(payload);

			if (res?.data?.data?.has_registered && res?.data?.success) {
				emitMoeEvent('Login Completed', {
					login_type: 'new',
				});

				notifyActions.notifyUser({
					severity: NotificationSeverity.SUCCESS,
					message: 'Successfuly registered',
				});
				moengage.updateUserId(res.data?.data?.user?.id as number);
				userActions.getUser();
				updateWhatsappConsent();
				onLoginSuccess && onLoginSuccess();
			}
		} catch (err: any) {
			if (err?.response?.data?.error?.name === 'EMAIL_ALREADY_REGISTERED') {
				setUserDetails((prev: any) => ({
					...prev,
					email: values.email,
					name: values.name,
				}));
				values.email = '';
				setDrawerOpen(true);
			} else {
				setEmailError(err?.response?.data?.error?.message);
			}
		}
		registeringUser.setValue(false);
	}

	const { values, errors, handleChange, handleSubmission } = useForm(
		formConfig,
		verify
	);
	let inputEmailRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputEmailRef.current) {
			inputEmailRef.current.focus();
		}
	}, []);

	function onChange(e: any) {
		const regex = /^[a-zA-Z]+([\sa-zA-Z]+)*$/;
		const { value, name } = e.target;
		setEmailError('');

		if (name === 'name') {
			setClearInputNameField(false);
			if (value.match(regex) || value === '') {
				handleChange(e);
			}
		} else if (name === 'email') {
			setClearEmailInputField(false);
			handleChange(e);
		}
	}
	const checkWhatsAppConsentStatus = () => {
		setWhatsAppConsentStatus(!whatsAppConsentStatus);
	};

	const border = errors.email ? `1px solid ${getColor('Danger +1')}` : 'none';
	const nameFieldBorder = errors.name
		? `1px solid ${getColor('Danger +1')}`
		: 'none';
	const isDisabled = useMemo(
		() =>
			!!errors.email ||
			!!errors.name ||
			registeringUser.value ||
			!values.name ||
			!values.email,
		[errors, registeringUser, values]
	);

	useEffect(() => {
		emitMoeEvent('New Signup Page Opened ');
	}, []);

	return (
		<FlexBox direction='column' align='start' sx={{ width: '100%' }}>
			{!createAccountFlag ? (
				<>
					<Typography
						sx={{
							color: getColor('Gray 0'),
							fontWeight: '500',
							fontSize: '30px',
							fontFamily: 'Recline',
							lineHeight: '0',
							marginBottom: '8px',
						}}
					>
						{' '}
						Looks like{' '}
					</Typography>
					<Typography
						sx={{
							color: getColor('Gray 0'),
							fontWeight: '500',
							fontSize: '30px',
							fontFamily: 'Recline',
							marginBottom: '32px',
						}}
					>
						{' '}
						you are{' '}
						<Box
							component='span'
							sx={{ color: getColor('Minty -1'), fontFamily: 'Recline' }}
						>
							New
						</Box>{' '}
					</Typography>
				</>
			) : (
				<>
					<Typography
						sx={{
							color: getColor('Gray 0'),
							fontWeight: '500',
							fontSize: '30px',
							lineHeight: '0',
							marginBottom: '8px',
							fontFamily: 'Recline',
						}}
					>
						{' '}
						Create a{' '}
					</Typography>
					<Typography
						sx={{
							color: getColor('Gray 0'),
							fontWeight: '500',
							fontSize: '30px',
							marginBottom: '32px',
							fontFamily: 'Recline',
						}}
					>
						{' '}
						New{' '}
						<Box
							component='span'
							sx={{ color: getColor('Minty -1'), fontFamily: 'Recline' }}
						>
							Account
						</Box>{' '}
					</Typography>
				</>
			)}
			<form onSubmit={handleSubmission} style={{ width: '100%' }}>
				<FlexBox
					direction='column'
					align='start'
					sx={{
						width: '100%',
						height: '56px',
						borderRadius: '8px',
						paddingLeft: '24px',
						backgroundColor: getColor('Gray -6'),
						border: `${nameFieldBorder}`,
						align: 'center',
					}}
				>
					<Typography
						sx={{ ...getStyle('Tiny-Regular'), color: getColor('Gray -3') }}
					>
						Name
					</Typography>
					<TextField
						name='name'
						autoFocus
						variant='standard'
						value={values.name ? values.name : ''}
						autoComplete='false'
						InputProps={{
							disableUnderline: true,
						}}
						sx={{ width: '90%' }}
						onChange={onChange}
					/>
				</FlexBox>
				{errors.name && (
					<Typography
						sx={{
							...getStyle('Small-Regular'),
							marginTop: '8px',
							color: `${getColor('Danger +1')}`,
						}}
					>
						{errors.name}
					</Typography>
				)}
				<FlexBox
					direction='column'
					align='start'
					sx={{
						border: `${border}`,
						width: '100%',
						marginTop: '12px',
						height: '56px',
						borderRadius: '8px',
						paddingLeft: '24px',
						backgroundColor: getColor('Gray -6'),
						align: 'center',
					}}
				>
					<Typography
						sx={{ ...getStyle('Tiny-Regular'), color: getColor('Gray -3') }}
					>
						E-mail ID
					</Typography>
					<TextField
						sx={{ width: '90%' }}
						name='email'
						variant='standard'
						autoComplete='false'
						value={clearInputEmailField ? '' : values.email}
						InputProps={{
							disableUnderline: true,
						}}
						ref={inputEmailRef}
						onChange={onChange}
					/>
				</FlexBox>
				{(errors.email || emailError) && (
					<Typography
						sx={{
							...getStyle('Small-Regular'),
							marginTop: '8px',
							color: `${getColor('Danger +1')}`,
						}}
					>
						{emailError || errors.email}
					</Typography>
				)}
				<FlexBox sx={{ marginTop: '16px', justifyContent: 'start' }}>
					<FlexBox
						component='span'
						sx={{
							width: '16px',
							height: '16px',
							border:
								!whatsAppConsentStatus && `1px solid ${getColor('Gray -2')}`,
							borderRadius: '4px',
							marginRight: '8px',
							background: whatsAppConsentStatus
								? getColor('Minty 0')
								: getColor(''),
						}}
						onClick={checkWhatsAppConsentStatus}
					>
						{whatsAppConsentStatus && (
							<Box sx={{ position: 'relative', top: '-2px' }}>
								<TickIcon />
							</Box>
						)}
					</FlexBox>
					<Typography
						sx={{ ...getStyle('Small-Medium'), color: getColor('Gray -1') }}
					>
						Get promo & order updates on
					</Typography>
					<Box component='span' sx={{ marginRight: '4px', marginLeft: '4px' }}>
						<WhatsAppIcon />
					</Box>
					<Typography
						sx={{ ...getStyle('Small-Medium'), color: getColor('Gray -1') }}
					>
						Whatsapp
					</Typography>
				</FlexBox>
				{/* <FlexBox sx={{...getStyle('P-SemiBold'),width:'230px',color:'#FFFFFF', height:'48px', backgroundColor: '#069BAA', borderRadius:'24px', textAlign:'8pxcenter', marginTop:'28px'}}><Box >Submit</Box></FlexBox> */}
				{/* <button type='submit'>submit</button> */}
				<FlexBox margin='24px 0px'>
					<button type='submit' className='email' style={{ width: '100%' }}>
						<LencoButton
							isLoading={registeringUser.value!}
							disabled={isDisabled as boolean}
							width='230px !important'
						>
							CREATE ACCOUNT
						</LencoButton>
					</button>
				</FlexBox>
			</form>
			{isDrawerOpen && (
				<EmailDrawer
					setCurrentStep={setCurrentStep}
					verifyEmailId={verifyEmailId}
					userDetails={userDetails}
					isOpen={isDrawerOpen}
					closeDrawer={(createFlag?: boolean) => {
						createFlag
							? (setCreateAccountFlag(true),
							  setClearInputNameField(true),
							  setClearEmailInputField(true))
							: '';

						setDrawerOpen(false);
					}}
				/>
			)}
			<style jsx>{`
				button.email {
					outline: none;
					background: none;
					border: 0;
				}
			`}</style>
		</FlexBox>
	);
};

export default EmailLogin;
