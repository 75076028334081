import GlobalHeader from '@/components/unlmtd/global-header';
import { Box, SxProps ,useTheme} from '@mui/material';

export type BaseLayoutProps = React.HTMLAttributes<any> & {
	sx?: SxProps;
};

const UnlmtdBaseLayout = (props: BaseLayoutProps) => {
	const theme=useTheme();
	return (
		<Box sx={{...props.sx,backgroundColor:theme?.colors?.lencoPrimaryP4}} className='fade-between-reverse unlmtd'>
			<Box
				sx={{
					backgroundColor:theme?.colors?.lencoPrimaryP4
				}}
			>
				<GlobalHeader withLogo />
			</Box>
			<Box sx={{background:theme?.colors?.lencoPrimaryP4}}>
			{props.children}

			</Box>
		</Box>
	);
}

export default UnlmtdBaseLayout;
