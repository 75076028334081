import { ICart } from '@/models/cart/get-cart';
import { ApiState, IGenericApiResponse } from '@/models/generics';
import { IOrder } from '@/services/checkout/models/create-cart-order';
import { ADD_ITEM_TO_CART, APPLY_COUPON, APPLY_VAS, CREATE_CART_CHECKOUT_ORDER, GET_CART, REMOVE_COUPON, REMOVE_ITEM_FROM_CART, UPDATE_CART, UPDATE_CART_ITEM_QUANTITY, UPDATE_CART_ITEM_TENURE } from "./actionTypes";

export interface ICartState {
    loadingCart: boolean,
    getCartApiState: ApiState,
    cart?: ICart,
    getCartError?: IGenericApiResponse<null>,
    displayErrorMessage?: string,
    errorLoadingCart: boolean,
    creatingCartOrder: boolean,
    cartCheckoutOrder?: IOrder,
    applyingVas?: boolean,
    removingItemFromCart?: boolean,
    isApplyingCoupon: boolean,
}

const initialState: ICartState = {
    loadingCart: false,
    getCartApiState: ApiState.IDLE,
    cart: undefined,
    getCartError: undefined,
    errorLoadingCart: false,
    creatingCartOrder: false,
    cartCheckoutOrder: undefined,
    applyingVas: false,
    isApplyingCoupon: false,
    displayErrorMessage: undefined,
};

export default function cartReducer(state = initialState, action: any): ICartState {
    switch (action.type) {
        case GET_CART.LOADING:
            return {
                ...state,
                getCartApiState: ApiState.LOADING,
                loadingCart: true,
            };
        case GET_CART.SUCCESS:
            return {
                ...state,
                getCartApiState: ApiState.SUCCESS,
                loadingCart: false,
                cart: action.payload,
                errorLoadingCart: false,
                getCartError: undefined,
                displayErrorMessage: undefined,
            };
        case GET_CART.FAILED:
            return {
                ...state,
                getCartApiState: ApiState.FAILED,
                loadingCart: false,
                errorLoadingCart: true,
                getCartError: action.payload,
                displayErrorMessage: action.displayErrorMessage,
            };
        case ADD_ITEM_TO_CART.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case REMOVE_COUPON.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case UPDATE_CART_ITEM_QUANTITY.SUCCESS:
            return {
                ...state,
                loadingCart: false,
                cart: action.payload,
                errorLoadingCart: false,
            };
        case REMOVE_ITEM_FROM_CART.LOADING:
            return {
                ...state,
                removingItemFromCart: true,
            };
        case REMOVE_ITEM_FROM_CART.SUCCESS:
            return {
                ...state,
                removingItemFromCart: false,
                loadingCart: false,
                cart: action.payload,
                errorLoadingCart: false,
            };
        case REMOVE_ITEM_FROM_CART.FAILED:
            return {
                ...state,
                removingItemFromCart: false,
            };
        case UPDATE_CART.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case UPDATE_CART_ITEM_TENURE.SUCCESS:
            return {
                ...state,
                cart: action.payload,
            };
        case CREATE_CART_CHECKOUT_ORDER.LOADING:
            return {
                ...state,
            };
        case CREATE_CART_CHECKOUT_ORDER.SUCCESS:
            return {
                ...state,
                cartCheckoutOrder: action.payload,
            };
        case APPLY_VAS.LOADING:
            return {
                ...state,
                applyingVas: true,
            };
        case APPLY_VAS.SUCCESS:
            return {
                ...state,
                applyingVas: false,
                cart: action.payload,
            };
        case APPLY_VAS.FAILED:
            return {
                ...state,
                applyingVas: false,
            };
        case APPLY_COUPON.LOADING:
            return {
                ...state,
                isApplyingCoupon: true,
            };
        case APPLY_COUPON.SUCCESS:
            return {
                ...state,
                isApplyingCoupon: false,
                cart: action.payload,
            };
        case APPLY_COUPON.FAILED:
            return {
                ...state,
                isApplyingCoupon: false,
            };
        default: return state;
    }
}