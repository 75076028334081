import { createTheme } from '@mui/material';
import { getColor } from './getColor';
import { getStyle } from './getStyle';
import { GenericColors,BuyColors,RentalColors, UnlmtdColors } from '@/constants/sf-colors';

export const mainTheme = createTheme({
  name:'sf',
  colors:{
    ...GenericColors
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: '100%',
          height: '48px',
          background: 'transparent',
          borderRadius: '30px',
          lineHeight: '16px',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: getColor('Minty 0'),
            color: '#fff',
            ...getStyle('P-SemiBold'),
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            border: `1px solid ${getColor('Minty 0')}`,
            color: getColor('Minty 0'),
            ...getStyle('P-SemiBold'),
          }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'error' && {
            backgroundColor: getColor('Danger -1'),
            ...getStyle('P-SemiBold'),
          }),
        }),
      },
    },
  },
});

export const unlmtdTheme = createTheme({
  name:'unlmtd',
   colors:{
    ...UnlmtdColors.primary,
    ...UnlmtdColors.secondary
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: '100%',
          height: '48px',
          background: 'transparent',
          borderRadius: '30px',
          lineHeight: '16px',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: getColor('Minty 0'),
            color: '#fff',
            ...getStyle('P-SemiBold'),
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            border: `1px solid ${getColor('Minty 0')}`,
            color: getColor('Minty 0'),
            ...getStyle('P-SemiBold'),
          }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'error' && {
            backgroundColor: getColor('Danger -1'),
            ...getStyle('P-SemiBold'),
          }),
        }),
      },
    },
  },
});
