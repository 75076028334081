import { ApiState } from '@/models/generics';
import { IWishlistProduct } from '@/services/wishlist/models/wishlist';
import { getWishlistsAction } from '@/store/wishlist/action';
import { wishlistService } from '@/services/wishlist';
import { useLencoDispatch } from '../cutom-hooks/use-lenco-dispatch';
import { useLencoSelector } from '../cutom-hooks/use-lenco-selector';
import { useRef } from 'react';

type UseWishlist = {
	getWishlistApiState: ApiState;
	wishlist: IWishlistProduct[];
	wishlistedProductsMap?: Record<number, IWishlistProduct>;
	wishlistActions: {
		getWishlists: () => void;
		addItemToWishList: (catalogId: number,query?:any) => Promise<any>;
		removeItemFromWishList: (catalogId: number) => Promise<any>;
		isProductWishlisted: (catalogId: number) => boolean;
	};
};

export function useWishlist(query?:any): UseWishlist {

	const { wishlist, wishlistedProductsMap, getWishlistApiState } =
		useLencoSelector((store) => ({
			wishlist: store.wishlist?.wishlists ?? [],
			wishlistedProductsMap: store.wishlist?.wishlistedProductsMap,
			getWishlistApiState: store.wishlist?.getWishlistApiState,
		}));
	const dispatch = useLencoDispatch();

	function getWishlists() {
		if(window.location.pathname.includes('unlmtd')){
			dispatch(getWishlistsAction({lineOfProduct:'UNLMTD'}));
		}
		else{
			dispatch(getWishlistsAction());
			
		}
	}

	function addItemToWishList(catalogId: number) {
		return wishlistService.addToWishList(catalogId,query).then(getWishlists);
	}

	function removeItemFromWishList(catalogId: number) {
		return wishlistService.deleteFromWishlist(catalogId).then(getWishlists);
	}

	function isProductWishlisted(catalogId: number) {
		if (
			wishlistedProductsMap &&
			wishlistedProductsMap.hasOwnProperty(catalogId)
		) {
			return true;
		} else {
			return false;
		}
	}

	return {
		wishlist,
		getWishlistApiState,
		wishlistedProductsMap,
		wishlistActions: {
			getWishlists,
			addItemToWishList,
			removeItemFromWishList,
			isProductWishlisted,
		},
	};
}
