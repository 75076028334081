import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import UserData, { IUserState } from './user/reducer';
import locationReducer, { ILocationStoreState } from './location/reducer';
import WishlistsReducer, { IWishlistStore } from './wishlist/reducer';
import AppConfigReducer, { AppConfig } from './appConfig/reducer';
import cartReducer, { ICartState } from './cart/reducer';
import SeoState from '@/store/seo/reducer';
import HaptikReducer from '@/store/haptik/reducer';


export interface IStoreState {
  user: IUserState,
  location: ILocationStoreState;
  cart: ICartState;
  wishlist: IWishlistStore;
  appConfig: AppConfig;
  seoData: {
    data: {
      title: string;
      description: string;
      schemas: [];
    };
  };
  routerConfig: {
    isHaptikEnabled: boolean;
  };
}

export function makeStore() {
  return configureStore({
    reducer: {
      user: UserData,
      cart: cartReducer,
      location: locationReducer,
      wishlist: WishlistsReducer,
      seo: SeoState,
      appConfig: AppConfigReducer,
      routerConfig: HaptikReducer
    },
  });
}

const store = makeStore();

export type TAppDispatch = typeof store.dispatch;

export type GroguState = ReturnType<typeof store.getState>;

export type GroguDispatch = typeof store.dispatch;

export type GroguThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  GroguState,
  unknown,
  Action<string>
>;

export default store;