import { getStyle } from '@/utils/getStyle';
import { Box } from '@mui/system';
import { useRouter } from 'next/router';
import { getColor } from '@/utils/getColor';
import CartIcon from './CartIcon';
import SearchIcon from '@/assets/UnlmtdSearchIcon.svg';
import { WishlistIcon } from './WishlistIcon';
import { useCart } from '@/hooks/unlmtd/useCart';
import { memo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Collapse, useTheme } from '@mui/material';
import { useUser } from '@/hooks/useUser';
import { useLogin } from '@/hooks/useLogin';
import { dataLayerPushWithEvent } from '@/utils/callcustomevent';
import useSubscription from '@/hooks/unlmtd/useSubscription';

const routePathOptionsExclusions = {
	cartIcon: {
		paths: ['/cart','/unlmtd/cart','/unlmtd/my/subscriptions',],
	},
};

function GlobalHeaderRightGroup() {
	const router = useRouter();
	const { totalCartItems } = useCart();
	const { isLoggedIn } = useUser();
	const { loginActions } = useLogin();
	const theme = useTheme();
	const { subscription } = useSubscription();
	const hasSubscription = subscription?.subscriptionPlans?.length;
	const showCartIcon = !routePathOptionsExclusions.cartIcon.paths.includes(
		router.asPath
	);

	const wishlistIconClicked = () => {
		if (isLoggedIn) {
			router?.push('/my/wishlist');
		} else {
			loginActions.showLoginFlow();
		}
	};

	const handleClickOnCartIcon = () => {
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'Cart Clicked',
			moe_event_data: {
				screen_name: '',
				items_count: totalCartItems,
			},
		});
		router?.push('/cart');
	};

	return (
		<Box
			sx={{
				display: 'flex',
				right: '0px',
			}}
		>
			<TransitionGroup
				style={{
					alignItems: 'center',
					flexDirection: 'row',
					display: 'flex',
				}}
			>
				<Collapse orientation='horizontal'>
					<Box onClick={() => router?.push('/unlmtd/search')} sx={{ height:'32px',mr: '4px' }}>
						<SearchIcon />
					</Box>
				</Collapse>
				{!hasSubscription && <Collapse orientation='horizontal'>
					<Box
						sx={{ position: 'relative', mr: '4px',height:'32px' }}
						onClick={() => router?.push('/unlmtd/my/wishlist')}
					>
						<WishlistIcon />
					</Box>
				</Collapse>}
				{(showCartIcon) && (
					!hasSubscription && (
						<Collapse orientation='horizontal' in={showCartIcon}>
						<Box
							sx={{ position: 'relative',height:'32px' }}
							onClick={() => router?.push('/unlmtd/cart')}
						>
							{' '}
							<CartIcon />
							{totalCartItems > 0 && (
								<Box
									sx={{
										color: theme?.colors?.lencoPrimaryN6,
										position: 'absolute',
										top: -4,
										right: 0,
										background: theme?.colors?.lencoSecondaryP0,
										...getStyle('Tiny-Medium'),
										borderRadius: '100%',
										padding: '1px 5px',
									}}
								>
									{totalCartItems}
								</Box>
							)}
						</Box>
					</Collapse>
					)
					
				)}
			</TransitionGroup>
		</Box>
	);
}

export default memo(GlobalHeaderRightGroup);
