import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiState, ViewStates } from '@/models/generics';
import { IGhostUser, IPanemUser } from '@/services/meta/models/meta';
import { IGetAddressesApiResponseData } from '@/services/user/models/addresses';
import { IProfileMenuApiResponseData } from '@/services/user/models/profile-menu.types';
import { CREATE_USER_ADDRESS, GET_PROFILE_MENU, GET_USER, GET_USER_ADDRESSES, LOGOUT_USER } from '@/store/user/actionTypes';
import { APP_HISTORY, AUTH_FLOW_TRIGGER, NOTIFCATION_TOAST } from '@/store/appConfig/actionTypes';
import { NotifyToastProps } from '@/components/NotifyToast';

interface IUserState {
  getUserApiState: ApiState;
  getAddressessApiState: ApiState;
  isLoggedIn: boolean;
  ghostUser?: IGhostUser;
  user?: IPanemUser;
  loadingProfileMenu: boolean;
  profileMenu?: IProfileMenuApiResponseData;
  errorLoadingProfileMenu: boolean;
  loggingOut: boolean;
  loggingOutFailed: boolean;
  loggingOutSuccess: boolean;
  addresses?: IGetAddressesApiResponseData;
  creatingAddress: boolean;
  authFlowViewState: keyof typeof ViewStates,
	notifications: NotifyToastProps[],
	history: any,
	onLogin: undefined,
	tokenValidity: 'VALID'
}

const initialState: IUserState = {
  getUserApiState: ApiState.LOADING,
  getAddressessApiState: ApiState.IDLE,
  isLoggedIn: false,
  ghostUser: undefined,
  user: undefined,
  loadingProfileMenu: true,
  profileMenu: undefined,
  errorLoadingProfileMenu: false,
  loggingOut: false,
  loggingOutFailed: false,
  loggingOutSuccess: false,
  addresses: undefined,
  creatingAddress: false,
  authFlowViewState: ViewStates.NOT_VISIBLE,
	notifications: [],
	history: [],
	onLogin: undefined,
	tokenValidity: 'VALID'
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Add your sync actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_USER.LOADING, (state) => {
        state.getUserApiState = ApiState.LOADING;
      })
      .addCase(GET_USER.SUCCESS, (state, action: any) => {
        state.getUserApiState = ApiState.SUCCESS;
        state.ghostUser = action.ghostUser;
        state.user = action.user;
        state.isLoggedIn = action.isLoggedIn;
      })
      .addCase(GET_USER.FAILED, (state) => {
        state.getUserApiState = ApiState.FAILED;
      })
      .addCase(GET_PROFILE_MENU.LOADING, (state) => {
        state.loadingProfileMenu = true;
        state.errorLoadingProfileMenu = false;
      })
      .addCase(GET_PROFILE_MENU.SUCCESS, (state, action: any) => {
        state.loadingProfileMenu = false;
        state.profileMenu = action;
      })
      .addCase(GET_PROFILE_MENU.FAILED, (state) => {
        state.loadingProfileMenu = false;
        state.errorLoadingProfileMenu = true;
      })
      .addCase(LOGOUT_USER.LOADING, (state) => {
        state.loggingOut = true;
      })
      .addCase(LOGOUT_USER.SUCCESS, (state, action: any) => {
        state.loggingOut = false;
        state.loggingOutSuccess = true;
        state.ghostUser = action.ghostUser;
        state.isLoggedIn = false;
        state.user = undefined;
      })
      .addCase(LOGOUT_USER.FAILED, (state) => {
        state.loggingOut = false;
        state.loggingOutFailed = true;
      })
      .addCase(GET_USER_ADDRESSES.LOADING, (state) => {
        state.getAddressessApiState = ApiState.LOADING;
      })
      .addCase(GET_USER_ADDRESSES.SUCCESS, (state, action: any) => {
        state.getAddressessApiState = ApiState.SUCCESS;
        state.addresses = action.payload;
      })
      .addCase(GET_USER_ADDRESSES.FAILED, (state) => {
        state.getAddressessApiState = ApiState.FAILED;
      })
      .addCase(CREATE_USER_ADDRESS.LOADING, (state) => {
        state.creatingAddress = true;
      })
      .addCase(CREATE_USER_ADDRESS.SUCCESS, (state) => {
        state.creatingAddress = false;
      })
      .addCase(CREATE_USER_ADDRESS.FAILED, (state) => {
        state.creatingAddress = false;
      })
      .addCase(AUTH_FLOW_TRIGGER.OPEN_AUTH_FLOW , (state, action: any) => {
        state.authFlowViewState = ViewStates.VISIBLE,
				state.onLogin = action.onLogin
      })
      .addCase(AUTH_FLOW_TRIGGER.CLOSE_AUTH_FLOW , (state, action: any) => {
        state.authFlowViewState = ViewStates.NOT_VISIBLE,
				state.onLogin = undefined
      })
      .addCase(NOTIFCATION_TOAST.ADD_TOAST , (state, action: any) => {
        state.notifications = [...state.notifications, action.payload]
      })
      .addCase(NOTIFCATION_TOAST.REMOVE_TOAST , (state, action: any) => {
        state.notifications = action.payload
      })
      .addCase(APP_HISTORY.ADD_TO_APP_HISTORY , (state, action: any) => {
        state.history = [...state.history, action.payload]
      })
      
  }
});

export default userSlice.reducer;
