import { Box } from '@mui/material';
import { useEffect } from 'react';

type OverlayProps = {
	isOpen: boolean;
	onClick: () => any;
};

const OverlayComponent = (props: OverlayProps) => {
	useEffect(() => {
		(window as any).document.getElementById('body').style.overflow =
			props.isOpen ? 'hidden' : 'scroll';
	}, [props.isOpen]);
	return (
		<Box
			onClick={props.onClick}
			sx={{
				height: '100vh',
				width: '100vw',
				display: props.isOpen ? 'block' : 'none',
				background: 'rgba(0,0,0,0.2)',
				overflow: 'hidden',
			}}
		/>
	);
};

export default OverlayComponent;
