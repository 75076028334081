// TODO:
// import { captureException } from "@sentry/nextjs";

export enum LocalStorageKeys {
	SELECTED_CITY_NAME = "selectedCityName",
	SELECTED_CITY_ID = "x-city-id",
	SELECTED_PINCODE =  "x-pincode"
}

export const getDataFromLocalStorage = (key: string) => {
	if (typeof window !== 'undefined') {
		return localStorage.getItem(key);
	}
	return null;
};

export const setDataToLocalStorage = (key: string, value: any) => {
	if (typeof window !== 'undefined') {
		try {
			return localStorage.setItem(key, `${value}`);
		} catch (error) {
			// TODO:
			// captureException(`Unable to setItem ${key} and value ${value}`+ error);
		}
		
	}
};

export const deleteKeyFromLocalStorage = (key: string) => {
	if (typeof window !== 'undefined') {
		return localStorage.removeItem(key);
	}
};
