import FlexBox from '@/atomic-components/flex-box';
import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import React, { ReactNode } from 'react';
import { Box } from '@mui/system';
import GlobalHeader from '../global-header';
import LencoButton from '@/atomic-components/button';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
// TODO:
// import { captureException, captureEvent } from '@sentry/nextjs';
import dynamic from 'next/dynamic';

const SomethingWentWrongIcon = dynamic(() => import('@/assets/communication/something-went-wrong.svg'));

interface IErrorScreenProps extends WithRouterProps {
	children?: any;
	title?: string;
	message?: string;
	hasError?: boolean;
	onRetry?: () => void;
}

interface IErrorScreenState {
	hasError: boolean;
}

class ErrorScreen extends React.Component<
	IErrorScreenProps,
	IErrorScreenState
> {
	constructor(props: any) {
		super(props);

		this.state = {
			hasError: false,
		};
	}
	retry = () => {
		this.setState({ hasError: false });

		if (typeof this.props.onRetry === 'function') {
			this.props.onRetry();
		}
	};
	routeChangeOnBrokenPage = () => {
		this.setState({ hasError: false });
	};
	componentDidMount() {
		this.props.router.events.on(
			'routeChangeStart',
			this.routeChangeOnBrokenPage
		);
	}
	componentWillUnmount(): void {}
	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		console.error(JSON.stringify({ error, errorInfo }));
		// TODO:
		// captureException(error);
		console.log('route -> ', this.props.router.route);
		// TODO:
		// captureEvent({ message: 'Error Screen Displayed', level: 'error' });
		this.setState({ hasError: true });
		this.props.router.events.off(
			'routeChangeStart',
			this.routeChangeOnBrokenPage
		);
	}
	render(): ReactNode {
		if (this.state.hasError || this.props.hasError) {
			return (
				<Box sx={{ height: '100%' }}>
					<FlexBox
						fullWidth
						direction='column'
						height='70vh'
						padding='24px'
						sx={{ textAlign: 'center' }}
					>
						<Box mb='50px' width='200px' height='200px'>
							<SomethingWentWrongIcon />
						</Box>
						<FlexBox
							mb='8px'
							sx={{ ...getStyle('H14-Regular'), color: getColor('Gray -5') }}
						>
							{this.props.title ?? 'OOPS'}
						</FlexBox>
						<FlexBox
							sx={{ ...getStyle('Small-Medium'), color: getColor('Gray -5') }}
						>
							{this.props.message ?? 'We are unable to load the content'}
						</FlexBox>
						{(this.state.hasError || this.props.onRetry) && (
							<LencoButton
								width='100px'
								variant='text'
								sx={{
									mt: '32px',
								}}
								onClick={this.retry}
							>
								RETRY
							</LencoButton>
						)}
					</FlexBox>
				</Box>
			);
		}
		return this.props.children;
	}
}

export default withRouter(ErrorScreen);
