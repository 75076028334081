import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import { ViewStates } from '@/models/generics';
import { AUTH_FLOW_TRIGGER } from '@/store/appConfig/actionTypes';
import { useAppConfig } from './useAppConfig';

type UseLogin = {
  authFlowViewState: ViewStates;
  onLogin?: () => void;
  loginActions: {
    showLoginFlow: (onLoginCallback?: () => void) => any;
    hideLoginFlow: () => any;
  };
};

export function useLogin(): UseLogin {
  const dispatch = useLencoDispatch();
  const {
    appConfig: { authFlowViewState, onLogin },
  } = useAppConfig();

  function hideLoginFlow() {
    dispatch({
      type: AUTH_FLOW_TRIGGER.CLOSE_AUTH_FLOW,
    });
  }

  function showLoginFlow(onLoginCallback?: () => void) {
    dispatch({
      type: AUTH_FLOW_TRIGGER.OPEN_AUTH_FLOW,
      onLogin: onLoginCallback,
    });
  }

  return {
    authFlowViewState,
    onLogin,
    loginActions: {
      showLoginFlow,
      hideLoginFlow,
    },
  };
}
