import { getLocationFromLocalStorage, saveLocationOnLocalStorage } from '@/utils/location';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS, SET_SELECTED_LOCATION } from './actionTypes';
import { TLocation } from './reducer';
import { dataLayerPush } from '@/utils/callcustomevent';

export const readUserSelectedLocationFromStorageAction = () => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SAVING
        });

        const location = getLocationFromLocalStorage();
        dataLayerPush({ city_id: location.cityId });

        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SUCCESS,
            selectedLocation: location
        });
    } catch (error) {
        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_FAILED,
        });
    }
};

export const saveUserSelectedLocationAction = (location?: TLocation) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SAVING
        });

        saveLocationOnLocalStorage(location);

        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_SUCCESS,
            selectedLocation: location
        });
    } catch (error) {
        dispatch({
            type: SET_SELECTED_LOCATION.SET_SELECTED_LOCATION_FAILED,
        });
    }
};

export const triggerCitySelection = (type: LOCATION_SELECTOR_TRIGGER_OPTIONS) => (dispatch: any) => {
    dispatch(
        {
            type
        }
    );
};

