export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export enum GET_META {
  LOADING = "GET_META_LOADING",
  SUCCESS = "GET_META_SUCCESS",
  FAILED = "GET_META_FAILED",
};

export enum GET_USER {
  LOADING = "GET_USER_LOADING",
  SUCCESS = "GET_USER_SUCCESS",
  FAILED = "GET_USER_FAILED",
};

export enum GET_PROFILE_MENU {
  LOADING = "GET_PROFILE_MENU_LOADING",
  SUCCESS = "GET_PROFILE_MENU_SUCCESS",
  FAILED = "GET_PROFILE_MENU_FAILED",
}

export enum LOGOUT_USER {
  LOADING = "GET_LOG_OUT_LOADING",
  SUCCESS = "GET_LOG_OUT_SUCCESS",
  FAILED = "GET_LOG_OUT_FAILED",
}

export enum GET_USER_ADDRESSES {
  LOADING = "GET_USER_ADDRESSES_LOADING",
  SUCCESS = "GET_USER_ADDRESSES_SUCCESS",
  FAILED = "GET_USER_ADDRESSES_FAILED",
}

export enum CREATE_USER_ADDRESS {
  LOADING = "CREATE_USER_ADDRESS_LOADING",
  SUCCESS = "CREATE_USER_ADDRESS_SUCCESS",
  FAILED = "CREATE_USER_ADDRESS_FAILED",
}

