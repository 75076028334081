
import BaseService from '@/services/base-service';
import { TGetLayoutResponse } from './models/layout';

class LayoutService extends BaseService {
  constructor() {
    super({
      serviceName: 'Layout Service'
    });
  }

  public getLayout(layoutType: string): Promise<TGetLayoutResponse> {
    return this.client.get(`/layout?type=${layoutType}`);
  }

  public getFallbackLayout(layoutType: string): Promise<TGetLayoutResponse> {
    return this.client.get(`/layout/fallback/${layoutType}`);
  }

  public getHomeLayout(): Promise<any> {
    return this.client.get('/layout?type=HOME_MWEB');
  }
  public getRentLayout(): Promise<any> {
    return this.client.get('/layout?type=RENT_MWEB');
  }
  public getBuyLayout(): Promise<any> {
    return this.client.get('/layout?type=BUY_MWEB');
  }

  public getProductBadges(): Promise<any> {
    return this.client.get(`/layout/key/Badge_V1`);
  }

  public getKeys(key_name:string): Promise<any> {
    return this.client.get(`/layout/key/${key_name}`);
  }
  public getSeoContent(cityName: string): Promise<any> {
    return this.client.get(`/layout/key/${cityName}`);
  }
  public getCategories(): Promise<any> {

    return this.client.get(`/catalogue/categories?withMedia=true`);
  };

}

export const layoutService = new LayoutService();
