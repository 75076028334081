import { IMetaApiResponse } from './models/meta';
import LencoAxios from '@/services/base-service';
import { TLocation } from '@/store/location/reducer';

export type GetMetaOptions = {
  hardRefresh: boolean;
};


class MetaService extends LencoAxios {
  constructor() {
    super({
      serviceName: 'Meta'
    });
  }

  getMeta(location?: TLocation, options?: GetMetaOptions): Promise<IMetaApiResponse> {
    return this.client.get('/meta', {
      withCredentials: options?.hardRefresh,
      headers: {
        ...(location && {
          'x-city-id': location?.cityId ?? 1,
          'x-pincode': location?.pincode
        })
      }
    });
  }
}

export const metaService = new MetaService();
