import BaseService from '@/services/base-service';
import { TGetWishlistApiResponse } from './models/wishlist';
import { appendLocationToQueryParams } from '@/utils/interceptors';

class WishlistService extends BaseService {
	constructor() {
		super({
			serviceName: 'Wishlists',
			customOptions: {
				interceptors: {
					request: appendLocationToQueryParams,
				},
			},
		});
	}

	public getWishlists(query?:any): Promise<TGetWishlistApiResponse> {
		return this.client.get(`/wishlists`,{
			params:query
		});
	}

	public addToWishList(id: number,query?:any): Promise<any> {
		return this.client.post(`/wishlists`, {
			catalogId: id,
		},{
			params:query
		});
	}

	public deleteFromWishlist(id: number,query?:any): Promise<any> {
		return this.client.delete(`/wishlists/${id}`,{
			params:query
		});
	}
}

export const wishlistService = new WishlistService();
