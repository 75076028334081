import { ApiState } from '@/models/generics';
import {
	saveUserSelectedLocationAction,
	triggerCitySelection,
} from '@/store/location/actions';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { TLocation } from '@/store/location/reducer';
import { locationService } from '@/services/location';
import { useUser } from './useUser';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import { useLencoSelector } from '@/cutom-hooks/use-lenco-selector';
import { useCart } from '@/hooks/useCart';
import { useCart as useUnlmtdCart } from '@/hooks/unlmtd/useCart';
import { useRouter } from 'next/router';
import { getCityFromUrl } from '@/utils/location';
import usePlan from './unlmtd/usePlan';
import { useTheme } from '@mui/material';

type UseLocation = {
	selectedLocation: TLocation;
	selectedLocationSyncState: ApiState;
	locationActions: {
		triggerCitySelector: (type: LOCATION_SELECTOR_TRIGGER_OPTIONS) => void;
		updateSelectedLocation: (location: TLocation) => void;
		getPincodeDetails: (pincode: number) => void;
	};
	isLocationSelectorOpen: boolean;
};

export function useLocation(): UseLocation {
	const router = useRouter();
	const theme = useTheme();
	const {
		selectedLocation,
		selectedLocationSyncState,
		isLocationSelectorOpen,
	} = useLencoSelector((state) => state.location);
	const {
		userActions: { getUser },
	} = useUser();
	const {
		cartActions: { getCart },
	} = useCart();

	const { planActions } = usePlan();

	const unlmtdCartActions = useUnlmtdCart();

	const dispatch = useLencoDispatch();

	function updateSelectedLocation(location: TLocation) {
		dispatch(saveUserSelectedLocationAction(location));
		unlmtdCartActions.cartActions.clearCart();

		if (location?.pincode != selectedLocation?.pincode) {
			getUser();
			if (theme?.name == 'unlmtd') {
				unlmtdCartActions.cartActions.fetchCart();
			} else if (theme?.name == 'sf') {
				getCart();
			}
			planActions.getPlans();
		}
		const pathCity = getCityFromUrl();
		if (pathCity && typeof window != 'undefined') {
			const updatedUrl = window.location.href.replaceAll(
				pathCity,
				location.cityName.toLowerCase()
			);
			router.replace(updatedUrl);
		}
	}

	function getPincodeDetails(pincode: number) {
		return locationService.getPincodeServiceabilityService(pincode);
	}

	const triggerCitySelector = (type: LOCATION_SELECTOR_TRIGGER_OPTIONS) => {
		dispatch(triggerCitySelection(type));
	};

	return {
		isLocationSelectorOpen,
		selectedLocation,
		...selectedLocation,
		selectedLocationSyncState,
		locationActions: {
			triggerCitySelector,
			updateSelectedLocation,
			getPincodeDetails,
		},
	};
}
