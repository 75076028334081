import FlexBox from '@/atomic-components/flex-box';
import { getColor } from '@/utils/getColor';
// import LogoHeader from '../logo-header';
import { memo, useState } from 'react';
import GlobalHeaderLeftGroup from './left-group';
import GlobalHeaderRightGroup from './right-group';
import { Box, Menu, Theme, useTheme } from '@mui/material';
import MenuIcon from '@/assets/menu-navigation-icon.svg';
// import NavigationBar from '../Navigations';
import withTheme from '@/hocs/withTheme';
import LogoHeader from '@/assets/unlmtd/logo.svg';
import NavigationBar from '@/components/Navigations';
import Link from 'next/link';

const style = (theme:Theme) => {
	return ({backgroundColor: theme?.colors?.lencoPrimaryP3,
	borderRadius: '48px',
	boxShadow:'0px 8px 16px 0px #0000000D'	,
	mb: '4px',
	pt: '4px',
	position: 'sticky !important',
	top: '2px',
	left: '0px',
	zIndex: 100,
	margin: '8px',
	pin: {
		fontFamily: 'Work Sans',
		fontWeight: '500',
		fontSize: '10px',
		color: getColor('Gray -4'),
		marginBottom: '0px',
	},
	pinCode: {
		fontFamily: 'Work Sans',
		fontWeight: '600',
		fontSize: '12px',
		color: getColor('Gray -4'),
		marginBottom: '0px',
	},})
};

function GlobalHeader(props: { pincode?: String; withLogo?: boolean }) {
	const [openNavigations, setOpenNavigations] = useState(false);
	const theme=useTheme();
	return (
		<>
			<FlexBox sx={{width:'100%'}}>
				{/* <Box
					mt='14px'
					mb='4px'
					sx={{ paddingLeft: '24px' }}
					onClick={() => setOpenNavigations(true)}
				>
					<MenuIcon />
				</Box> */}

				{props.withLogo &&
				<FlexBox align='center'>
					<Link href={'/unlmtd'}>
						<Box mt='14px' >
							<LogoHeader/>
						</Box>
					</Link>
				</FlexBox>
				}
			</FlexBox>

			<FlexBox sx={{...style(theme)}} id='global-header'>
				<FlexBox
					justify='between'
					sx={{
						width: '100%',
						margin: '0px 10px',
						padding: '10px 10px',
						alignItems: 'center',
						display: 'flex',
					}}
				>
					<GlobalHeaderLeftGroup />
					<GlobalHeaderRightGroup />
				</FlexBox>
			</FlexBox>
			<NavigationBar
				isOpen={openNavigations}
				closeDrawer={() => setOpenNavigations(false)}
			/>
		</>
	);
}

 export default memo(GlobalHeader);
