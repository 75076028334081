import { configureStore } from '@reduxjs/toolkit';
import unlmtdAppConfigSlice from './slices/appConfigSlice';
import locationReducer from './slices/locationSlice';
import userDataReducer from './slices/userSlice';
import HaptikReducer from '../haptik/reducer';
import WishlistsReducer from '../wishlist/reducer';
import cartSlice from './slices/cartSlice';
import planSlice from './slices/planConfigSlice';
import subscriptionSlice from './slices/subscriptionSlice';

const unlmtdStore = configureStore({
  reducer: {
    appConfig:unlmtdAppConfigSlice,
    location: locationReducer,
    user: userDataReducer,
    routerConfig: HaptikReducer,
    wishlist:WishlistsReducer,
    cart:cartSlice,
    plans:planSlice,
    subscription:subscriptionSlice
  },

});

export default unlmtdStore;


