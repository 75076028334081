import { useState } from 'react';

export interface ILencoState<IValue> {
  value: IValue | null;
  setValue: Function;
}

function useLencoState<T>(initialValue: any = null): ILencoState<T> {
  const [value, setValue] = useState<T>(initialValue);
  return { value, setValue };
};

export default useLencoState;