interface IUserAttributes {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  userName?: string;
  gender?: string;
  birthday?: Date;
}

interface IUseMoengage {
  init: () => void;
  updateUserAttributes: (updatedValues: IUserAttributes) => void;
  setAdditionalAttributes: (customAttributes: any) => void;
  sendEvent: (eventName: string, data?: any) => void;
  setUserId: (id: number) => void;
  updateUserId: (id: number) => void;
  destroySession: () => void;
}

function useMoengage(): IUseMoengage {
  let Moengage: any = null;

  function init() {
    Moengage = (window as any).Moengage;
    return Moengage;
  }

  function setUserId(id: number) {
    try { typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_unique_user_id(id); } catch (error) { }
  }

  function updateUserId(id: number) {
    try { typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.update_unique_user_id(id); } catch (error) { }
  }

  function destroySession() {
    try { typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.destroy_session(); } catch (error) { }
  }

  function updateUserAttributes(updatedValues: IUserAttributes) {
    try {
      if (updatedValues.firstName) {
        typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_first_name(updatedValues.firstName);
      }
      if (updatedValues.userName) {
        typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_user_name(updatedValues.userName);
      }
      if (updatedValues.email) {
        typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_email(updatedValues.email);
      }
      if (updatedValues.mobile) {
        typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_mobile(updatedValues.mobile);
      }
    } catch (error) { }
  }

  function setAdditionalAttributes(customAttributes: any) {
    try {
      for (const attributeName in customAttributes) {
        const value = customAttributes[attributeName];
        typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.add_user_attribute(attributeName, value);
      }
    } catch (error) { }
  }

  function sendEvent(eventName: string, eventData?: any) {
    try {
      typeof window !== 'undefined' && (window as any).Moengage && (window as any).Moengage.track_event(eventName, eventData ?? {});
    } catch (error) { }
  };

  return {
    init,
    updateUserAttributes,
    setAdditionalAttributes,
    sendEvent,
    setUserId,
    destroySession,
    updateUserId
  };
};

export default useMoengage;