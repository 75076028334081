import FlexBox from '@/atomic-components/flex-box';
import { Box, Collapse } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { Arrow } from '@/utils/pagesHeader';
import { SelectedLocationDisplay } from './components/selected-location-display';
import { useAppConfig } from '@/hooks/useAppConfig';
import { useLocation } from '@/hooks/useLocation';
import { useRouter } from 'next/router';

type GlobalHeaderLeftGroup = {};

const routePathOptionsExclusions = {
	backButton: {
		paths: ['/unlmtd'],
	},
};

const GlobalHeaderLeftGroup = (props: GlobalHeaderLeftGroup) => {
	const { selectedLocation } = useLocation();
	const { appConfigActions } = useAppConfig();
	const router = useRouter();

	const showBackButton = !routePathOptionsExclusions.backButton.paths.includes(
		router.asPath
	);

	return (
		<FlexBox>
			<TransitionGroup
				style={{
					alignItems: 'center',
					flexDirection: 'row',
					display: 'flex',
				}}
			>
				{showBackButton && (
					<Collapse orientation='horizontal' in={showBackButton}>
						<Box onClick={appConfigActions.goBackOrGoHome} sx={{ mr: '16px' }}>
							<Arrow />
						</Box>
					</Collapse>
				)}
				<Collapse orientation='horizontal'>
					<SelectedLocationDisplay
						pincode={selectedLocation.pincode as number}
					/>
				</Collapse>
			</TransitionGroup>
		</FlexBox>
	);
};

export default GlobalHeaderLeftGroup;
