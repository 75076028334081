export type IntersectionObserverConfigOptions = {
  margin: string;
  threshold: number[];
};
export const getInterSectionObserver = (
  intersectionObserverConfig?: IntersectionObserverConfigOptions
) => {
  const defaultImageIntersectionObserverOptions = {
    rootMargin: `0px 150% 300px 0px`,
    threshold: [0.1, 0.25],
  };
  const imageIntersectionObserver =
    typeof window !== "undefined" &&
    new IntersectionObserver(
      (imageEntities: IntersectionObserverEntry[]) => {
        try {
          imageEntities.forEach((image: any) => {
            if (image.isIntersecting) {
              (imageIntersectionObserver as IntersectionObserver).unobserve(
                image.target
              );
              if (image.target.dataset.srcset) {
                image.target.srcset = image.target.dataset.srcset;
              }
              image.target.onload = () => image.target.classList.add("loaded");
            }
          });
        } catch (e) {
          console.error(e);
        }
      },
      {
        ...defaultImageIntersectionObserverOptions,
        ...intersectionObserverConfig,
      }
    );
  return imageIntersectionObserver;
};
