export const toHHMMSS = (sec: number): string => {
	// if (sec == 0) return `00`;
	const hours = Math.floor(sec / 3600);
	const minutes = Math.floor((sec - hours * 3600) / 60);
	const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
	return `${minutes > 0 ? `${minutes} min` : '00:'} ${
		seconds < 10 ? `0${seconds}` : seconds
	}`;
};

export const startCounter = (
	timeToWait: number,
	counterRef: any,
	timeOverCallback: () => void
) => {
	let remainingTime = timeToWait * 60 - 1;
	const timeInterval = setInterval(() => {
		if (remainingTime == -1) {
			timeOverCallback();
			clearInterval(timeInterval);
		} else if (counterRef.current) {
			counterRef.current.innerText = toHHMMSS(remainingTime);
			remainingTime--;
		}
	}, 1000);
	return timeInterval;
};

export function roundPrice(price: any) {
    return Math.floor(price).toString();
}

export const formatPrice = (value: string) => {
	if (value?.includes('₹')) {
		const parts = value.replace('₹', '').split('.');
		return parts;
	}
	return value;
};
