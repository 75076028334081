/** @format */

import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import FlexBox from '../../../atomic-components/flex-box';
import useForm from '../../../cutom-hooks/useForm';
import { getColor } from '../../../utils/getColor';
import { getStyle } from '../../../utils/getStyle';
import IndiaFlag from '../../../assets/login/india-flag.svg';
import LencoButton from '@/atomic-components/button';
import { ILencoState } from '@/cutom-hooks/useLencoState';
import { useLogin } from '@/hooks/useLogin';

interface IEnterMobileNumberProps {
	verifyMobileNumber: Function;
	verifyingAccount: ILencoState<boolean>;
	loginError: string;
	loginErrorCode: string;
	userDetails: any;
	values: any;
	errors: any;
	handleChange: any;
	handleSubmission: any;
	loading: boolean;
}

const SignIn = (props: IEnterMobileNumberProps) => {
	const { loginError } = props;
	const inputMobileRef = useRef<HTMLInputElement>(null);

	function changeHandler(e: any) {
		const { value } = e.target;
		const firstNumberFormat = /^[6789]/;
		const numberFormat = /\d+$/;

		if (
			(value.length === 1 && value.match(firstNumberFormat)) ||
			value === ''
		) {
			props.handleChange(e);
		} else if (
			(value.length !== 1 && value.match(numberFormat)) ||
			value === ''
		) {
			props.handleChange(e);
		}
	}

	useEffect(() => {
		if (inputMobileRef.current) {
			inputMobileRef.current.focus();
		}
	}, []);

	const border = useMemo(
		() =>
			props.errors?.mobile ? `1px solid ${getColor('Danger +1')}` : 'none',
		[props.errors?.mobile]
	);
	// const shadow = useMemo(
	// 	() => (errors?.mobile ? '0px 8px 16px rgba(0, 0, 0, 0.05)' : 'none'),
	// 	[errors?.mobile]
	// );

	const isDisabled = useMemo(
		() =>
			(!props.errors?.mobile && props.values?.mobile?.length != 10) ||
			props.verifyingAccount.value ||
			props.loading,
		[
			props.errors?.mobile,
			props.values?.mobile,
			props.verifyingAccount.value,
			props.loading,
		]
	);

	return (
		<FlexBox
			direction='column'
			align='start'
			justifyContent='start'
			sx={{ width: '100%' }}
		>
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '30px',
					fontFamily: 'Recline',
					lineHeight: '0',
					marginBottom: '8px',
					fontStyle: 'normal',
					color: `${getColor('Gray 0')}`,
				}}
			>
				{' '}
				Let’s{' '}
			</Typography>
			<Typography
				sx={{
					fontWeight: '500',
					fontStyle: 'normal',
					fontSize: '30px',
					fontFamily: 'Recline',
					marginBottom: '12px',
					color: `${getColor('Gray 0')}`,
				}}
			>
				{' '}
				get{' '}
				<Box
					component='span'
					sx={{
						color: getColor('Minty -1'),
						fontFamily: 'Recline',
						fontStyle: 'normal',
						marginBottom: '12px',
					}}
				>
					Started
				</Box>{' '}
			</Typography>
			<Typography
				sx={{
					...getStyle('Small-Medium'),
					color: '#B9B9B9',
					lineHeight: '0',
					marginBottom: '32px',
				}}
			>
				Enter your phone number to proceed
			</Typography>
			<form onSubmit={props.handleSubmission} style={{ width: '100%' }}>
				<Box
					sx={{
						position: 'relative',
						backgroundColor: `${getColor('Gray -6')}`,
						width: '100%',
						height: '50px',
						borderRadius: '8px',
						align: 'center',
						border: `${border}`,
					}}
				>
					<TextField
						sx={{
							paddingLeft: '82px',
							paddingTop: '9px',
							zIndex: '99',
							width: '90%',
							input: {
								'&::placeholder': {
									...getStyle('Small-Medium'),
								},
							},
						}}
						name='mobile'
						value={props.values?.mobile ? props.values.mobile : ''}
						onChange={changeHandler}
						inputRef={inputMobileRef}
						placeholder='Mobile Number'
						variant='standard'
						autoComplete='off'
						InputProps={{
							disableUnderline: true,
							type: 'tel',
						}}
						inputProps={{
							minLength: 10,
							maxLength: 10,
						}}
					/>
					<Box sx={{ position: 'absolute', left: '24px', top: '17px' }}>
						<IndiaFlag />
					</Box>
				</Box>
				{loginError && (
					<Typography
						sx={{
							...getStyle('Small-Regular'),
							paddingTop: '8px',
							color: `${getColor('Danger +1')}`,
						}}
					>
						{loginError}
					</Typography>
				)}
				{/* {errors?.mobile && <Typography sx={{ ...getStyle('Small-Regular'), marginTop: '8px', color: '#CB1538' }}>Please enter a valid phone number</Typography>} */}
				<FlexBox mt='24px'>
						<LencoButton
						type='submit'
						className='login'
							disabled={isDisabled as boolean}
							isLoading={props.loading}
							variant='contained'
							sx={{
								width: '230px',
								boxShadow: '0px 6px 5px 0px rgba(6,155,170,0.6)',
							}}
						>
							SUBMIT
						</LencoButton>
				</FlexBox>
			</form>
			<style jsx>{`
				button.login {
					outline: none;
					background: none;
					border: 0;
					paddingleft: 40px;
					paddingright: 40px;
					width: 100%;
				}
			`}</style>
		</FlexBox>
	);
};

export default SignIn;
