export const WishlistIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_89_21258)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.3329 26.2223C23.8216 26.2223 24.2181 26.6189 24.2181 27.1076C24.2181 27.5963 23.8216 27.9929 23.3329 27.9929L16.6668 27.9966C16.1781 27.9966 15.7816 27.6 15.7816 27.1113C15.7816 26.6226 16.1781 26.2259 16.6668 26.2259L23.3329 26.2223ZM32 11.2083C32 13.9891 30.7711 16.3943 29.1 18.377C26.4368 21.5373 22.6439 23.6085 21.0797 24.368L21.0263 24.3916C20.7054 24.5399 20.3479 24.6148 20.0001 24.6148C19.6335 24.6148 19.2561 24.5315 18.9212 24.3664L18.9203 24.368C17.6679 23.7596 14.981 22.3439 12.5921 20.1804C10.1341 17.9542 8 14.9477 8 11.2083C8 9.2467 8.73018 7.45635 9.90368 6.15377C11.1008 4.82573 12.7582 4 14.5833 4C16.0172 4 17.3358 4.49212 18.4115 5.33017C19.0014 5.7897 19.5162 6.35432 19.9348 6.99811C20.3545 6.36108 20.8702 5.80211 21.456 5.34563C22.5439 4.49814 23.8707 4 25.2778 4C27.1612 4 28.8434 4.81573 30.0567 6.13016C31.262 7.43589 32 9.23614 32 11.2083Z" fill="#BCBCBC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.7461 17.2385C29.1728 15.5455 30.2224 13.5149 30.2224 11.2084C30.2224 9.69337 29.6636 8.32015 28.7516 7.33173C27.8668 6.37322 26.6435 5.77795 25.2781 5.77795C24.274 5.77795 23.3254 6.13489 22.5467 6.74146C21.7386 7.37108 21.1081 8.26904 20.7782 9.31939C20.6942 9.5881 20.4839 9.81281 20.1947 9.90281C19.727 10.0485 19.2294 9.78707 19.0837 9.31939C18.7506 8.25904 18.1289 7.35507 17.3216 6.726C16.5551 6.1286 15.6121 5.77795 14.5837 5.77795C13.2833 5.77795 12.0919 6.37739 11.2232 7.34118C10.3324 8.32987 9.77832 9.69893 9.77832 11.2084C9.77832 14.3437 11.6378 16.9282 13.7798 18.8681C15.9914 20.8709 18.5139 22.1988 19.6914 22.7706L19.6905 22.7722L19.7018 22.7778C19.7803 22.8168 19.8855 22.8368 20.0005 22.8368C20.1018 22.8368 20.1961 22.8213 20.2706 22.7906L20.3094 22.7706C21.77 22.0615 25.3057 20.1342 27.7461 17.2385Z" fill="#39315C"/>
</g>
<defs>
<filter id="filter0_d_89_21258" x="0" y="0" width="40" height="39.9966" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_21258"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_21258" result="shape"/>
</filter>
</defs>
</svg>

	);
};
