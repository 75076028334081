import { TGetPaymentDataApiResponse } from '@/services/checkout/models/get-payment-data';
import { IGhostUser, IPanemUser } from '@/services/meta/models/meta';
import { IGetAddressesApiResponseData, TGetAddressesApiResponse } from '@/services/user/models/addresses';
import { IProfileMenuApiResponseData } from '@/services/user/models/profile-menu.types';
import { LOGOUT_USER, GET_META, GET_PROFILE_MENU, GET_USER_ADDRESSES, CREATE_USER_ADDRESS, GET_USER } from "./actionTypes";
import { ApiState } from '../../models/generics';
export interface IUserState {
    getUserApiState: ApiState;
    getAddressessApiState: ApiState;
    isLoggedIn: boolean,
    ghostUser?: IGhostUser,
    user?: IPanemUser,
    loadingProfileMenu: boolean,
    profileMenu?: IProfileMenuApiResponseData,
    errorLoadingProfileMenu: boolean,
    loggingOut: boolean,
    loggingOutFailed: boolean,
    loggingOutSuccess: boolean;
    addresses?: IGetAddressesApiResponseData;
    creatingAddress: boolean;
}

const initialState: IUserState = {
    getUserApiState: ApiState.LOADING,
    getAddressessApiState: ApiState.IDLE,
    isLoggedIn: false,
    ghostUser: undefined,
    user: undefined,
    loadingProfileMenu: true,
    profileMenu: undefined,
    errorLoadingProfileMenu: false,
    loggingOut: false,
    loggingOutFailed: false,
    loggingOutSuccess: false,
    addresses: undefined,
    creatingAddress: false,
};


export default function userDataReducer(state = initialState, action: any): IUserState {
    switch (action.type) {
        case GET_USER.LOADING:
            return {
                ...state,
                getUserApiState: ApiState.LOADING,
            };
        case GET_USER.SUCCESS:
            return {
                ...state,
                getUserApiState: ApiState.SUCCESS,
                ghostUser: action?.ghostUser,
                user: action.user,
                isLoggedIn: action.isLoggedIn,
            };
        case GET_USER.FAILED:
            return {
                ...state,
                getUserApiState: ApiState.FAILED,
            };
        case GET_PROFILE_MENU.LOADING: {
            return {
                ...state,
                loadingProfileMenu: true,
                errorLoadingProfileMenu: false,
            };
        }
        case GET_PROFILE_MENU.SUCCESS: {
            return {
                ...state,
                loadingProfileMenu: false,
                profileMenu: action.payload,
            };
        }
        case GET_PROFILE_MENU.FAILED: {
            return {
                ...state,
                loadingProfileMenu: false,
                errorLoadingProfileMenu: true,
            };
        }

        case LOGOUT_USER.LOADING: {
            return {
                ...state,
                loggingOut: true,
            };
        }

        case LOGOUT_USER.SUCCESS: {
            return {
                ...state,
                loggingOut: false,
                loggingOutSuccess: true,
                ghostUser: action?.payload,
                isLoggedIn: false,
                user: undefined,
            };
        }
        case LOGOUT_USER.FAILED: {
            return {
                ...state,
                loggingOut: false,
                loggingOutFailed: true,
            };
        }

        case GET_USER_ADDRESSES.LOADING: {
            return {
                ...state,
                getAddressessApiState: ApiState.LOADING
            };
        }

        case GET_USER_ADDRESSES.SUCCESS: {
            return {
                ...state,
                getAddressessApiState: ApiState.SUCCESS,
                addresses: action?.payload,
            };
        }
        case GET_USER_ADDRESSES.FAILED: {
            return {
                ...state,
                getAddressessApiState: ApiState.FAILED,
            };
        }

        case CREATE_USER_ADDRESS.LOADING: {
            return {
                ...state,
                creatingAddress: true,
            };
        }

        case CREATE_USER_ADDRESS.SUCCESS: {
            return {
                ...state,
                creatingAddress: false,
            };
        }

        case CREATE_USER_ADDRESS.FAILED: {
            return {
                ...state,
                creatingAddress: false,
            };
        }

        default: return state;
    }
}