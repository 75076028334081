import { userService } from '@/services/user';

import { GET_USER_ADDRESSES } from './actionTypes';

export const getUserAddressesAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_USER_ADDRESSES.LOADING,
        });

        const res = await userService.getAddress();
        const data = res?.data?.data;

        dispatch({
            type: GET_USER_ADDRESSES.SUCCESS,
            payload: data
        });
    } catch (error) {

        dispatch({
            type: GET_USER_ADDRESSES.FAILED,
            payload: error,
        });
    }
};