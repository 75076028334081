const fontWeightMapper = {
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
};
export const typoGraphyConfig = {
    "H5-Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H5-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H5-SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H5A-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H5A-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H5A-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 36,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H7-Medium": {
        fontFamily: "Recline",
        fontStyle: "normal",
        fontWeight: fontWeightMapper["Medium"],
    },
    "H9-Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H9-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H9-SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H9A-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H9A-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H9A-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 24,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H10-Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H10-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H10-SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H10A-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H10A-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H10A-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 21,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H11-Regular": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H11-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H11-SemiBold": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H11A-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H11A-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H11A-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 18,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "H14A-Medium": {
        fontFamily: "Recline",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H14-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Regular"],
    },
    "H14-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["Medium"],
    },
    "H14-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 16,
        fontWeight: fontWeightMapper["SemiBold"],
    },

    "P-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["Regular"],
    },
    "P-Medium": {
        fontFamily: "Work Sans !important",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["Medium"],
    },
    "P-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 14,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "Small-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["Regular"],
    },
    "Small-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["Medium"],
    },
    "Small-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 12,
        fontWeight: fontWeightMapper["SemiBold"],
    },
    "Tiny-Regular": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["Regular"],
    },
    "Tiny-Medium": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["Medium"],
    },
    "Tiny-SemiBold": {
        fontFamily: "Work Sans",
        // lineHeight: 1,
        fontSize: 10,
        fontWeight: fontWeightMapper["SemiBold"],
    },
};
