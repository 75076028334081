import { cartService } from '@/services/cart';
import {
	IApplyCouponCodeApiPayload,
	IApplyVasPayload,
	TUpdateCartApiResponse,
} from '@/services/cart/models/update-cart';
import {
	ADD_ITEM_TO_CART,
	APPLY_COUPON,
	APPLY_VAS,
	GET_CART,
	REMOVE_COUPON,
	REMOVE_ITEM_FROM_CART,
	UPDATE_CART_ITEM_QUANTITY,
	UPDATE_CART_ITEM_TENURE,
} from '@/store/cart/actionTypes';
import {
	UseCart,
	CART_STATUS,
	TUpdateCartItemPayload,
	TUpdateTenurePayload,
	CartAttributes,
} from './types';
import { ICartItem } from '@/models/cart/get-cart';
import {
	CART_TYPE,
	GetCartApiResponseData,
} from '@/services/cart/models/get-cart';
import { useLencoDispatch } from '../../cutom-hooks/use-lenco-dispatch';
import { useLencoSelector } from '../../cutom-hooks/use-lenco-selector';
import { IUpdateCartItemQuantityPayload } from '@/services/cart/models/update-cart-quantity';

function getIdListAndLopFromCartItems(cartItems: ICartItem[]): {
	ids: number[];
	lops: string[];
} {
	const cartItemsLops: Record<string, boolean> = {};

	const productIds = cartItems?.map((item) => {
		cartItemsLops[item.lineOfProduct] = true;
		return item.id;
	});

	const lopsArray = Object.keys(cartItemsLops) ?? [];

	return {
		ids: productIds,
		lops: lopsArray,
	};
}

export function useCart(): UseCart {
	const {
		getCartApiState,
		cart,
		rentCart,
		buyCart,
		rentalCartItemsCount,
		buyCartItemsCount,
		canCheckout,
		cartErrorDisplayMessage,
		buyCartItems = [],
		rentalCartItems = [],
	} = useLencoSelector((state) => ({
		getCartApiState: state.cart?.getCartApiState,
		cart: state.cart?.cart,
		rentCart: state.cart?.cart?.rentCart,
		buyCart: state.cart?.cart?.buyCart,
		rentalCartItemsCount: state.cart?.cart?.rentCart?.rentalCartItemsCount ?? 0,
		buyCartItemsCount: state.cart?.cart?.buyCart?.buyCartItemsCount ?? 0,
		buyCartItems: state.cart?.cart?.buyCart?.buyCartItems,
		rentalCartItems: state.cart?.cart?.rentCart?.rentalCartItems,
		canCheckout: state.cart?.cart?.canCheckout as boolean,
		cartErrorDisplayMessage: state.cart?.displayErrorMessage,
	}));

	const dispatch = useLencoDispatch();

	const totalCartItems = buyCartItemsCount + rentalCartItemsCount;

	async function getCart(query?: any): Promise<GetCartApiResponseData> {
		dispatch({
			type: GET_CART.LOADING,
		});

		return cartService
			.getCarts(query)
			.then((res) => res.data.data)
			.then((data) => {
				dispatch({
					type: GET_CART.SUCCESS,
					payload: data?.cart,
				});

				return data;
			});
	}

	function addItemToCart(
		payload: TUpdateCartItemPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.addItemToCart(cart?.id as number, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: ADD_ITEM_TO_CART.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function updateTenure(
		itemId: number,
		payload: TUpdateTenurePayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.updateTenure(cart?.id as number, itemId, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: UPDATE_CART_ITEM_TENURE.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function removeItemFromCart(
		itemId: number,
		cartType: CART_TYPE
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.removeItemFromCart(cart?.id as number, itemId as number, cartType)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: REMOVE_ITEM_FROM_CART.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function updateCartItemQuantity(
		itemId: number,
		cartType: CART_TYPE,
		payload: IUpdateCartItemQuantityPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.updateCartItemQuantity(
				cart?.id as number,
				itemId as number,
				cartType,
				payload
			)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: UPDATE_CART_ITEM_QUANTITY.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function applyCouponCode(
		cartType: CART_TYPE,
		payload: IApplyCouponCodeApiPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.applyCouponCode(cart?.id as number, cartType, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: APPLY_COUPON.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function removeCoupon(cartType: CART_TYPE): Promise<TUpdateCartApiResponse> {
		return cartService
			.removeCouponCode(cart?.id as number, cartType)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: REMOVE_COUPON.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function applyVas(
		cartType: CART_TYPE,
		payload: IApplyVasPayload
	): Promise<TUpdateCartApiResponse> {
		return cartService
			.applyVas(cart?.id as number, cartType, payload)
			.then((res) => {
				if (res.data?.success) {
					dispatch({
						type: APPLY_VAS.SUCCESS,
						payload: res.data?.data?.cart,
					});
				}
				return res;
			});
	}

	function getCartAttributes(): CartAttributes {
		let buyProductIds: number[] = [];
		let rentalProductIds: number[] = [];
		let lineOfProducts: string[] = [];
		let verticals: string[] = [];
		let buyProductIdsString = '';
		let rentalProductIdsString = '';
		let lineOfProductsString = '';
		let cartStatus: CART_STATUS = CART_STATUS.EMPTY_CART;
		let verticalsString = '';
		const cartTypes = [];

		rentalCartItemsCount > 0 && cartTypes.push('rent');
		buyCartItemsCount > 0 && cartTypes.push('buy');

		if (rentalCartItemsCount > 0) {
			const { ids, lops: rentalLops } = getIdListAndLopFromCartItems(
				rentCart?.rentalCartItems as ICartItem[]
			);
			rentalProductIds = ids;
			rentalProductIdsString = ids.join(',');
			lineOfProducts = [...rentalLops];
			verticals.push('FURLENCO_RENTAL');
			cartStatus = CART_STATUS.PRODUCTS_IN_CART;
		}
		if (buyCartItemsCount > 0) {
			const { ids, lops: buyLops } = getIdListAndLopFromCartItems(
				buyCart?.buyCartItems as ICartItem[]
			);
			buyProductIds = ids;
			buyProductIdsString = ids.join(',');
			lineOfProducts = [...lineOfProducts, ...buyLops];
			verticals.push('FURLENCO_SALE');
			cartStatus = CART_STATUS.PRODUCTS_IN_CART;
		}

		verticalsString = verticals.join(',');
		lineOfProductsString = lineOfProducts.join(',');

		return {
			verticals,
			lineOfProducts,
			lineOfProductsString,
			buyProductIds,
			buyProductIdsString,
			rentalProductIds,
			rentalProductIdsString,
			cartStatus,
			verticalsString,
			cartTypes,
			cartTypesString: cartTypes.join(','),
			appliedBuyCoupon:
				(buyCart?.appliedCoupon.appliedCouponCode as string) ?? undefined,
			appliedRentCoupon:
				(rentCart?.appliedCoupon.appliedCouponCode as string) ?? undefined,
		};
	}

	return {
		id: cart?.id,
		getCartApiState,
		cart,
		rentCart,
		buyCart,
		rentalCartItemsCount: rentalCartItemsCount,
		buyCartItemsCount: buyCartItemsCount,
		totalCartItems,
		canCheckout,
		cartErrorDisplayMessage,
		buyCartItems,
		rentalCartItems,
		getCartAttributes,
		cartActions: {
			getCart,
			updateTenure,
			addItemToCart,
			removeItemFromCart,
			updateCartItemQuantity,
			applyCouponCode,
			applyVas,
			removeCoupon,
		},
	};
}
