import { getColor } from '@/utils/getColor';
import { Box } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import SingleInput from './single-input';

interface OTPInputProps {
	length: number;
	onChangeOTP: (otp: string) => any;
	autoFocus?: boolean;
	otpError?: string;
}

const OTPInputComponent = (props: OTPInputProps) => {
	const { length, autoFocus, onChangeOTP, otpError, ...rest } = props;

	const [activeInput, setActiveInput] = useState(0);
	const [otpValue, setOTPValue] = useState(Array<string>(length).fill(''));
	const focusInput = useCallback(
		(inputIndex: number) => {
			const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
			setActiveInput(selectedIndex);
		},
		[length]
	);

	const focusNextInput = useCallback(() => {
		focusInput(activeInput + 1);
	}, [activeInput, focusInput]);

	const handleOtpChange = useCallback(
		(otp: string[]) => {
			const otpValue = otp.join('');
			onChangeOTP(otpValue);
		},
		[onChangeOTP]
	);

	const changeCodeAtFocus = useCallback(
		(str: string) => {
			const updatedOTPValues = [...otpValue];
			updatedOTPValues[activeInput] = str[0] || '';
			setOTPValue(updatedOTPValues);
			handleOtpChange(updatedOTPValues);
		},
		[activeInput, handleOtpChange, otpValue]
	);

	const handleOnFocus = useCallback(
		(index: number) => () => {
			focusInput(index);
		},
		[focusInput]
	);

	const focusPrevInput = useCallback(() => {
		focusInput(activeInput - 1);
	}, [activeInput, focusInput]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.currentTarget?.value;
			if (!value) {
				return;
			}
			changeCodeAtFocus(value);
			focusNextInput();
			// let str = otpValue;
			// str+= value;
			// onChangeOTP(str);
			// setOtpValue(str);
			// setActiveInput(str.length)
		},
		[changeCodeAtFocus, focusNextInput]
	);

	const handleOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const pressedKey = e.key;

			switch (pressedKey) {
				case 'Backspace':
				case 'Delete': {
					e.preventDefault();
					if (otpValue[activeInput]) {
						changeCodeAtFocus('');
					} else {
						focusPrevInput();
					}
					break;
				}
				default: {
					if (pressedKey.match(/^[^0-9]$/)) {
						e.preventDefault();
					}

					break;
				}
			}
		},
		[activeInput, changeCodeAtFocus, focusPrevInput, otpValue]
	);

	const border = otpError
		? `1px solid ${getColor('Danger +1')}`
		: `1px solid ${getColor('Minty -2')}`;
	return (
		<Box sx={{ display: 'flex' }}>
			{Array(length)
				.fill('')
				.map((_, index) => (
					<SingleInput
						key={`SingleInput-${index}`}
						focus={activeInput === index}
						value={otpValue && otpValue[index]}
						autoFocus={autoFocus}
						onFocus={handleOnFocus(index)}
						onChange={handleChange}
						onKeyDown={handleOnKeyDown}
						border={border}
					/>
				))}
		</Box>
	);
};

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
