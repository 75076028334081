import BaseService from '@/services/base-service';
import { TAddAddressesApiResponse, TGetAddressesApiResponse } from './models/addresses';
import { MetaApiResponse } from './models/meta.types';
import { IProfileMenuApiResponse } from './models/profile-menu.types';
import { TGetWhatsAppConsentApiResponse } from './models/whatsAppConsent';

class UserService extends BaseService {
  constructor() {
    super({
      serviceName: 'User'
    });
  }

  getUser(): Promise<MetaApiResponse> {
    return this.client.get('/users/meta');
  }

  getUserProfileMenu(): Promise<IProfileMenuApiResponse> {
    return this.client.get('/users/menu');
  }

  getAddress(): Promise<TGetAddressesApiResponse> {
    return this.client.get('/users/addresses');
  }
  addAddress(data: any): Promise<TAddAddressesApiResponse> {
    return this.client.post('/users/addresses', data);
  }
  getUserWhatsAppConsent(data: any): Promise<TGetWhatsAppConsentApiResponse> {
    return this.client.post('/users/whatsapp-consent', data);
  }
}

export const userService = new UserService();
