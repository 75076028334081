import { Box, Drawer, Typography } from '@mui/material';
import FlexBox from '../../../atomic-components/flex-box';
import { getStyle } from '../../../utils/getStyle';
import EmailIcon from '../../../assets/login/email-icon.svg';
import { getColor } from '@/utils/getColor';
import { AuthFlowSteps } from '..';

const EmailDrawer = (props: {
	isOpen: boolean;
	closeDrawer: (createFlag?: boolean) => void;
	setCurrentStep: any;
	userDetails: any;
	verifyEmailId: (values: any) => void;
}) => {
	const actionOnContinue = () => {
		props.verifyEmailId(props.userDetails);
		props.closeDrawer();
		props.setCurrentStep(AuthFlowSteps.VERIFY_OTP);
	};
	return (
		<Drawer
			anchor='bottom'
			open={props.isOpen}
			onClose={() => props.closeDrawer}
		>
			<FlexBox
				fullWidth
				direction='column'
				align='start'
				sx={{ padding: '24px' }}
			>
				<Typography
					sx={{
						...getStyle('H9-SemiBold'),
						color: `${getColor('Gray 0')}`,
						marginBottom: '12px',
					}}
				>
					Oops!
				</Typography>
				<Typography
					sx={{ ...getStyle('Small-Regular'), color: `${getColor('Gray -2')}` }}
				>
					Looks like this email ID is already registered with us.
				</Typography>
				<FlexBox
					justify='start'
					onClick={() => {
						actionOnContinue();
					}}
					fullWidth
					sx={{
						border: `1px solid ${getColor('Minty 0')}`,
						borderRadius: '8px',
						margin: '24px 0px 24px 0',
						padding: '16px',
					}}
				>
					<Box>
						<EmailIcon />
					</Box>
					<FlexBox
						direction='column'
						align='center'
						fullWidth
						sx={{ marginLeft: '16px' }}
					>
						<Typography sx={{ ...getStyle('P-Regular'), color: '#666666' }}>
							Continue as{' '}
						</Typography>
						<Typography
							sx={{
								...getStyle('H14-SemiBold'),
								color: '#333333',
								marginTop: '4px',
							}}
						>
							{props.userDetails?.email}
						</Typography>
					</FlexBox>
				</FlexBox>
				<FlexBox
					direction='column'
					fullWidth
					onClick={() => {
						props.closeDrawer(true), props.setCurrentStep('EMAIL_LOGIN');
					}}
				>
					<Typography
						sx={{
							...getStyle('P-SemiBold'),
							color: `${getColor('Gray -3')}`,
							marginBottom: '18px',
						}}
					>
						OR
					</Typography>
					<Typography
						sx={{
							...getStyle('P-Medium'),
							color: `${getColor('Minty 0')}`,
							marginBottom: '8px',
						}}
					>
						Skip and Create a New Account
					</Typography>
					<Typography sx={{ ...getStyle('Small-Regular') }}>
						with a new email ID
					</Typography>
				</FlexBox>
			</FlexBox>
		</Drawer>
	);
};

export default EmailDrawer;
