import { AxiosError, AxiosResponse } from 'axios';


export type CiagoErrorResponse = {
  name: string;
  message: string;
  resolution_message: string;
  code: string;
  // TODO: fix from api and remove from here
  error?: {
    name: string;
    message: string;
    resolution_message?: string;
    resolutionMessage?: string;
    code: string;
    validation_errors?: string[];
  };
};

export type BaseAxiosApiError = AxiosError<CiagoErrorResponse>;

export type IGenericApiResponse<T> = AxiosResponse<{
  success: boolean;
  data: T;
  error: CiagoErrorResponse;
}>;

export interface IStoreAction {
  type: string;
  payload: any;
}

export enum ApiState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export type TCallbacks<TOnSuccess = null, TOnFailed = null, TFinally = null> = {
  onSuccess?: (data: TOnSuccess) => void;
  onFailed?: (error: TOnFailed) => void;
  finally?: (data: TFinally) => void;
};

export enum ViewStates {
  WAITING = 'WAITING',
  VISIBLE = 'VISIBLE',
  NOT_VISIBLE = 'NOT_VISIBLE',
}

export type SvgIconProps = {
  strokeColor?: string;
  fillColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
  fillColorForHorizontalStrip?: string;
};



export type Faq = {
  answer: string;
  question: string;
};
