import * as React from 'react';
import Typography from '@mui/material/Typography';
import { getStyle } from '@/utils/getStyle';
import { getColor } from '@/utils/getColor';
import FlexBox from '@/atomic-components/flex-box';
import { Box } from '@mui/material';
import BuyIcon from '@/assets/navigations/buy-icon.svg';
import RentIcon from '@/assets/navigations/rent-icon.svg';
import UnlmtdIcon from '@/assets/navigations/unlmtd-icon.svg';
import SideArrowIcon from '@/assets/navigations/side-arrow-icon.svg';
import Category from './category';
import { useEffect, useState } from 'react';
import CustomAccordion from './customAccordian';
import { useRouter } from 'next/router';
import { CategoryInterface } from '@/services/category/model';

const Verticals = [
	{ varticalName: 'Buy', icon: <BuyIcon /> },
	{ varticalName: 'Rent', icon: <RentIcon /> },
];

type verticalProps = {
	buyCategoryData: CategoryInterface[];
	rentCategoryData: CategoryInterface[];
	closeDrawer: () => void;
};

export default function Vertical1(props: verticalProps) {
	const { buyCategoryData, rentCategoryData } = props;
	const [openCategories, setOpenCategories] = useState(false);
	const [config, setConfig] = useState({
		height: '40px',
		labelname: '',
		sublabel: '',
	});

	const router = useRouter();
	const expandCategories = (label: string, sublabel?: string) => {
		if (sublabel && label == config.labelname) {
			if (sublabel == config.sublabel) {
				setConfig({
					height: 'initial',
					labelname: label,
					sublabel: '',
				});
			} else {
				setConfig({
					height: 'initial',
					labelname: label,
					sublabel: sublabel ? sublabel : '',
				});
			}
		} else if (label == config.labelname) {
			setConfig({
				height: '50px',
				labelname: '',
				sublabel: '',
			});
		} else {
			setConfig({
				height: 'initial',
				labelname: label,
				sublabel: sublabel ? sublabel : '',
			});
		}
	};

	useEffect(() => {
		setConfig({
			height: '50px',
			labelname: '',
			sublabel: '',
		});
	}, [props.closeDrawer]);

	return (
		<Box sx={{ width: '100%' }}>
			{Verticals.map((vertical, id) => {
				const categoriesToDisplay =
					vertical.varticalName == 'Buy' ? buyCategoryData : rentCategoryData;
				const titleStyle = {
					name: vertical.varticalName,
					style: 'H11-Medium',
					color: 'Minty +1',
				};
				return (
					<CustomAccordion
						title={titleStyle}
						icon={vertical.icon}
						divider={true}
						key={id}
						showBackground={config.labelname == vertical.varticalName}
						expandCategories={() => expandCategories(vertical.varticalName)}
						height={
							vertical.varticalName == config.labelname ? config.height : '44px'
						}
						parent={true}
					>
						{categoriesToDisplay?.map(
							(cate: CategoryInterface, index: number) => {
								return (
									<Category
										subCategory={cate}
										closeDrawer={props.closeDrawer}
										key={index}
										expandCategories={() =>
											expandCategories(vertical.varticalName, cate.label)
										}
										config={config}
									/>
								);
							}
						)}
					</CustomAccordion>
				);
			})}
			<Box sx={{ width: '100%', overflow: 'hidden', height: '52px' }}>
				<FlexBox
					direction='column'
					sx={{ alignItems: 'center', justify: 'center' }}
				>
					<FlexBox
						sx={{
							justifyContent: 'space-between',
							width: '100%',
							alignItems: 'center',
						}}
						onClick={() => {
							router.replace('/unlmtd');
						}}
					>
						<FlexBox justify='start' sx={{ alignItems: 'center' }}>
							<FlexBox
								sx={{
									paddingTop: '6px',
								}}
							>
								<UnlmtdIcon />
							</FlexBox>

							<Typography
								sx={{
									...getStyle('H11-Medium'),
									color: getColor('Minty 0'),
								}}
							>
								Unlmtd
							</Typography>
						</FlexBox>
						<Box
							sx={{
								transform: openCategories ? 'rotate(180deg)' : '0',
								paddingRight: '8px',
							}}
						>
							<SideArrowIcon />
						</Box>
					</FlexBox>

					<Box
						sx={{
							borderBottom: `1px solid ${getColor('Gray -5')}`,
							width: '100%',
						}}
					/>
				</FlexBox>
			</Box>
		</Box>
	);
}
