import ChatButton from '@/assets/chat.svg';
import { YmProvider } from '../yellowMesssenger';

const NonChatBotPages = ['/cart','/checkout'] 


function ChatBotFloatingButton() {


	return (
		// <HaptikHoc onClick={launchChatBot} onLaunch={onHaptikLaunched}>
		<>
		<YmProvider>
			<div
				className={
					'haptik-lets-chat d-flex justify-content-center align-items-center'
				}
				style={{
					position: 'fixed',
					zIndex: '99',
					right: 28,
					bottom: 10,
					cursor: 'pointer',
				}}
			>
				<ChatButton />
			</div>
			</YmProvider>
			</>
		// </HaptikHoc>
	);
}

export default ChatBotFloatingButton
