import { withRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cookies } from 'next/headers';
import Head from 'next/head';
import React from 'react';
import useMoengageExperiments from '@/hooks/useMoengageExperiments';

declare const ENV: any;

const defaultSeoConfig = {
	title: 'Find comfort & style | Buy & rent furniture online| Furlenco',
	description:
		'Bring home designer furniture @ wallet-friendly prices. Now rent furniture or buy furniture online from Furlenco, with the freedom to return or sell-back anytime.',
	image:
		'https://assets.furlenco.com/s3-furlenco-images/grogu/Furlenco2.0_LOGO-EMBLEM-LOW-25.png',
};
const LencoHeader = ({
	router,
	moeVariant,
}: {
	router: any;
	moeVariant?: string;
}) => {
	const seo = useSelector((state: any) => state?.seo);
	const [pageCanonicalUrl, setPageCanonicalUrl] = useState('');

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setPageCanonicalUrl(
				window.location.href.replace(window.location.search, '')
			);
		}
	}, [router]);

	return (
		<Head>
			<script
				id='moengage-init'
				dangerouslySetInnerHTML={{
					__html: `
					!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");

					Moengage = moe({
						app_id: "AGTFRL1MHSJZ8CUPLX044546",
						debug_logs: 0,
					});
				`,
				}}
			></script>

			<script
				id='facebook-init'
				dangerouslySetInnerHTML={{
					__html: `
					!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '434445376717057');
					fbq('track', 'PageView');
					`,
				}}
			></script>
			<script
				id='google-tag-manager'
				dangerouslySetInnerHTML={{
					__html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					 })(window,document,'script','dataLayer','GTM-5LDD26Q');
				   `,
				}}
			></script>
			<meta
				name='viewport'
				content='initial-scale=1.0, width=device-width, user-scalable=no'
			/>
			<meta property='og:image' content={seo?.image || defaultSeoConfig.image} />
			<meta property='og:title' content={seo?.title || defaultSeoConfig.title} />
			<meta
				property='og:description'
				content={seo?.description || defaultSeoConfig.description}
			/>
			<meta property='title' content={seo?.title || defaultSeoConfig.title} />
			<meta
				name='description'
				content={seo?.description || defaultSeoConfig.description}
			/>
			<title>{seo?.title || defaultSeoConfig.title}</title>
			<meta property='og:price:currency' content='INR'></meta>
			<meta property='og:type' content='Website'></meta>
			<meta name='twitter:card' content='summary'></meta>
			<meta name='twitter:site' content='@Furlenco'></meta>
			<meta name='application-name' content='Furlenco'></meta>
			<meta name='application-name' content='Furlenco'></meta>
			<meta name='author' content='Furlenco'></meta>
			<meta name='theme-color' content='#069BAA'></meta>
			<meta name='msapplication-navbutton-color' content='#069BAA'></meta>
			<meta
				name='viewport'
				content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
			/>
			<meta name='apple-mobile-web-app-capable' content='yes'></meta>
			<meta
				name='apple-mobile-web-app-status-bar-style'
				content='default'
			></meta>
			<meta name='apple-mobile-web-app-title' content='Furlenco'></meta>
			<meta name='full-screen' content='yes'></meta>
			<meta name='mobile-web-app-capable' content='yes'></meta>
			{pageCanonicalUrl && (
				<link rel='canonical' href={pageCanonicalUrl}></link>
			)}
		</Head>
	);
};

export default withRouter(React.memo(LencoHeader));
