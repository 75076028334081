
import { Box, Drawer, Typography, useTheme } from "@mui/material";
import PlanTemp from "../PlanCardWidget/planTemp";
import usePlan from "@/hooks/unlmtd/usePlan";
import { PlanSelectorStatus } from "@/store/unlmtd/slices/planConfigSlice";
import FlexBox from "@/atomic-components/flex-box";
import { getStyle } from "@/utils/getStyle";
import CrossIcon from '@/assets/city/close.svg';
import { useLocation } from "@/hooks/useLocation";


const hiddenPlanSelectorPages=['/unlmtd']

function PlanSelectorDrawer() {
    const theme=useTheme()
    const {planSelectorStatus ,planActions,selectedPlanId} = usePlan();
    const {selectedLocation} = useLocation();
    return ( 
        <Drawer open={(planSelectorStatus == PlanSelectorStatus.PLAN_SHOW && selectedLocation?.pincode && !hiddenPlanSelectorPages.includes(window?.location?.pathname)) ? true : false}   
        onClose={()=>selectedPlanId && planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_HIDE)} 
        anchor="bottom"
        PaperProps={{
            sx:{
                background:theme?.colors?.lencoPrimaryP4
            }
        }}
        >
            <Box >
                <FlexBox sx={{padding:'24px 24px 10px 24px',justifyContent:'space-between',alignItems:'center'}}>
                <Typography sx={{color:theme?.colors?.lencoPrimaryN6,...getStyle('H11A-SemiBold')}}> Select a Plan </Typography>
               {selectedPlanId && <Box onClick={()=>{selectedPlanId && planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_HIDE)}}>
                    <CrossIcon />
                </Box>}
                </FlexBox>
                <PlanTemp source={'bottom sheet'}/>
            </Box>  
        </Drawer>
     );
}

export default PlanSelectorDrawer;