import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'config';

const SubscriptionState  = {
    SUBSCRIPTION_PENDING : 'SUBSCRIPTION_PENDING',
    SUBSCRIPTION_SUCCESS : 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAILED : 'SUBSCRIPTION_FAILED',
}


// Define the initial state
interface SubscriptionState {
    subscription:any,
    subscriptionStatus:typeof SubscriptionState[keyof typeof SubscriptionState]
}

const initialState: SubscriptionState = {
    subscription:null,
    subscriptionStatus:SubscriptionState.SUBSCRIPTION_PENDING
};

// Create the slice
const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        getSusbcriptionPending: (state) => {
            state.subscriptionStatus = SubscriptionState.SUBSCRIPTION_PENDING;
        },
        getSubscriptionFulfilled: (state, action: PayloadAction<any>) => {
            state.subscriptionStatus = SubscriptionState.SUBSCRIPTION_SUCCESS;
            state.subscription = action.payload;
        },
        getSubscriptionRejected: (state) => {
            state.subscriptionStatus = SubscriptionState.SUBSCRIPTION_FAILED;
            state.subscription = null;
        }
    },
});

// Export the actions and reducer
export const { 
    getSusbcriptionPending, 
    getSubscriptionFulfilled, 
    getSubscriptionRejected
 } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;