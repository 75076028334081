export const GenericColors={
  "lencoPrimaryN6":'#F2FAFB',
  "lencoPrimaryN5":'#E6F5F6',
  "lencoPrimaryN4":'#CEEDF0',
  "lencoPrimaryN3":'#B4E1E5',
  "lencoPrimaryN2":'#76CDD6',
  "lencoPrimaryN1":'#40B6C2',
  "lencoPrimaryP0":'#069BAA',
  "lencoPrimaryP1":'#047992',
  "lencoPrimaryP2":'#035C7A',
  "lencoPrimaryP3":'#39315C',
  "lencoPrimaryP4":'#222038',
  "lencoPrimaryP5":'#A09FC1',
}

export const BuyColors = {
  "lencoPrimaryN6":'#f9fafe',
  "lencoPrimaryN5":'#eef1fa',
  "lencoPrimaryN4":'#dde3f9',
  "lencoPrimaryN3":'#bdc7f4',
  "lencoPrimaryN2":'#93a0e0',
  "lencoPrimaryN1":'#6f7bc1',
  "lencoPrimaryP0":'#424e99',
  "lencoPrimaryP1":'#3b4483',
  "lencoPrimaryP2":'#3b4483',
  "lencoPrimaryP3":'#191f58',
  "lencoPrimaryP4":'#0f1449',
}

export const RentalColors = {
  "lencoPrimaryN6":'#fffbf6',
  "lencoPrimaryN5":'#fef4e8',
  "lencoPrimaryN4":'#feead2',
  "lencoPrimaryN3":'#fdcfa6',
  "lencoPrimaryN2":'#f9ad78',
  "lencoPrimaryN1":'#f48c56',
  "lencoPrimaryP0":'#ee5921',
  "lencoPrimaryP1":'#bd3614',
  "lencoPrimaryP2":'#9e220f',
  "lencoPrimaryP3":'#7f110a',
  "lencoPrimaryP4":'#530404',
}

export const UnlmtdColors={
primary:{
  "lencoPrimaryN6":'#EFECF9',
  "lencoPrimaryN5":'#DFD8F2',
  "lencoPrimaryN4":'#CFC5EB',
  "lencoPrimaryN3":'#B6A8E1',
  "lencoPrimaryN2":'#9D8AD6',
  "lencoPrimaryN1":'#856DCC',
  "lencoPrimaryP0":'#715BB6',
  "lencoPrimaryP1":'#6653A4',
  "lencoPrimaryP2":'#4F4280',
  "lencoPrimaryP3":'#39315C',
  "lencoPrimaryP4":'#222038',
  "lencoPrimaryP5":'#A09FC1',
 
},
secondary:{
  "lencoSecondaryN6":'#F2E3EE',
  "lencoSecondaryN5":'#E8CCE1',
  "lencoSecondaryN4":'#DEB6D4',
  "lencoSecondaryN3":'#D49FC7',
  "lencoSecondaryN2":'#CA88B9',
  "lencoSecondaryN1":'#C072AC',
  "lencoSecondaryP0":'#B65B9F',
  "lencoSecondaryP1":'#934482',
  "lencoSecondaryP2":'#702E65',
  "lencoSecondaryP3":'#4D1747',
"lencoSecondaryP4":'#2A002A',
},



}