import { SvgIconProps } from '@/models/generics';

const DownArrowIcon = (props: SvgIconProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props?.width ? props?.width : '14'}
			height={props?.height ? props?.height : '9'}
			viewBox='0 0 14 9'
			fill='none'
		>
			<path
				d='M13 1.53955L7 7.53955L1 1.53955'
				stroke={props?.strokeColor ? props?.strokeColor : '#222222'}
				stroke-width={props?.strokeWidth ? props?.strokeWidth : '2'}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default DownArrowIcon;
