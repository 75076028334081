/** @format */

import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { Input, TextField, InputProps } from '@mui/material';
import usePrevious from '../../../cutom-hooks/usePrevious';
import { getColor } from '@/utils/getColor';

type SingleOTPInputProps = InputProps & {
	focus: boolean;
	border: string;
};

export function SingleOTP(props: SingleOTPInputProps) {
	const inputOtpBoxRef = useRef<HTMLInputElement>(null);
	const { focus, autoFocus, border, ...rest } = props;
	const prevFocus = usePrevious(!!focus);
	useLayoutEffect(() => {
		if (inputOtpBoxRef.current) {
			if (focus && autoFocus) {
				inputOtpBoxRef.current.focus();
			}
			if (focus && autoFocus && focus !== prevFocus) {
				inputOtpBoxRef.current.focus();
				inputOtpBoxRef.current.select();
			}
		}
	}, [autoFocus, focus, prevFocus]);

	return (
		<Input
			inputRef={inputOtpBoxRef}
			type='number'
			disableUnderline
			inputProps={{ style: { textAlign: 'center', padding: '0', } }}
			sx={{
				width: '46px',
				height: '56px',
				backgroundColor: `${getColor('Gray -6')}`,
				borderRadius: '8px',
				border: `${border}`,
				marginRight: '8px',
				'input[type=number] ':{
					MozAppearance:'textfield'
				  },
				...rest.sx,
			}}
			{...rest}
		/>
	);
}

const SingleOTPInput = memo(SingleOTP);
export default SingleOTPInput;
