import { useLencoSelector } from '@/cutom-hooks/use-lenco-selector';
import { ApiState } from '@/models/generics';
import { IGhostUser, IPanemUser } from '@/services/meta/models/meta';
import { IGetAddressesApiResponseData } from '@/services/user/models/addresses';
import { IProfileMenuApiResponseData } from '@/services/user/models/profile-menu.types';
import { TLocation } from '@/store/location/reducer';
import { getUserAddressesAction } from '@/store/user/action';
import { useLencoDispatch } from './../cutom-hooks/use-lenco-dispatch';
import { GET_USER, LOGOUT_USER } from '@/store/user/actionTypes';
import { GetMetaOptions, metaService } from '@/services/meta';
import { setUser } from '@sentry/nextjs';
import { useCart as useUnlmtdCart } from '@/hooks/unlmtd/useCart';
import { useCart } from '@/hooks/useCart';

import { useWishlist } from '@/hooks/useWishlist';
import { authService } from '@/services/auth';
import {
	getUserLoading,
	getUserSuccess,
} from '@/store/unlmtd/slices/userSlice';
import { useTheme } from '@mui/material';

type UseUser = {
	getUserApiState: ApiState;
	getAddressessApiState: ApiState;
	ghostUser?: IGhostUser;
	user?: IPanemUser;
	isLoggedIn: boolean;
	loadingProfileMenu: boolean;
	addresses?: IGetAddressesApiResponseData;
	profileMenu?: IProfileMenuApiResponseData;
	userActions: {
		getUser: (location?: TLocation, options?: GetMetaOptions) => Promise<any>;
		resetSession: (location?: TLocation) => Promise<any>;
		logoutUser: () => Promise<any>;
		getAddresses: (callback: () => void) => void;
	};
};

export function useUser(): UseUser {
	const {
		user,
		ghostUser,
		profileMenu,
		addresses,
		getUserApiState,
		getAddressessApiState,
		loadingProfileMenu,
	} = useLencoSelector((store) => ({
		user: store?.user?.user,
		ghostUser: store?.user?.ghostUser,
		profileMenu: store?.user.profileMenu,
		addresses: store?.user?.addresses,
		getUserApiState: store?.user?.getUserApiState,
		getAddressessApiState: store?.user?.getAddressessApiState,
		loadingProfileMenu: store?.user?.loadingProfileMenu,
	}));

	const dispatch = useLencoDispatch();
	const theme = useTheme();
	const unlmtdCart = useUnlmtdCart();
	const { cartActions } = useCart();

	const { wishlistActions } = useWishlist();

	const getAddresses = () => dispatch(getUserAddressesAction);

	const resetSession = (location?: TLocation) => {
		return metaService.getMeta(location, {
			hardRefresh: true,
		});
	};

	const getUser = (location?: TLocation, options?: GetMetaOptions) => {
		dispatch(getUserLoading());

		return metaService
			.getMeta(location, options)
			.then((res) => res.data.data)
			.then((data) => {
				const { user, ghost_user: ghostUser } = data?.meta || {};
				const isLoggedIn = !!user?.id;
				dispatch({
					type: GET_USER.SUCCESS,
					user: user,
					ghostUser,
					isLoggedIn,
				});

				setUser({
					id: isLoggedIn ? user?.id?.toString() : ghostUser?.id?.toString(),
					email: user?.email_id,
					username: user?.first_name,
				});
				if (theme?.name == 'unlmtd') {
					unlmtdCart.cartActions.fetchCart();
				} else if (theme?.name == 'sf') {
					cartActions.getCart();
				}

				if (ghostUser) {
					unlmtdCart.cartActions.clearCart();
				}

				if (isLoggedIn) {
					wishlistActions.getWishlists();
				}

				return {
					ghostUser,
					user: user,
				};
			})
			.catch((e) => {
				dispatch({
					type: GET_USER.FAILED,
				});
			});
	};

	const logoutUser = (): Promise<IGhostUser> => {
		return authService
			.logOutUser()
			.then((res) => res.data.data)
			.then((data) => {
				getUser();
				return data;
			});
	};

	const isLoggedIn = !!user?.id;

	return {
		getUserApiState,
		getAddressessApiState,
		user,
		ghostUser,
		isLoggedIn,
		profileMenu,
		addresses,
		loadingProfileMenu,
		userActions: {
			getUser,
			logoutUser,
			getAddresses,
			resetSession,
		},
	};
}
