import { unlmtdPlanService } from '@/services/unlmtd/plan';
import { useEffect, useState } from 'react';
import PlanTile from '../PlanTile';
import { getDataFromLocalStorage } from '@/utils/DataToLocalStorage';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import usePlan from '@/hooks/unlmtd/usePlan';
import { Plan } from '@/store/unlmtd/models/plan';
import { ApiState } from '@/models/generics';
// import { getStyle } from "@/utils/getStyle"

function PlanTemp({source}:{source:string}) {
	const { plans, selectedPlanId, planActions } = usePlan();
	const theme = useTheme();
	// const [localPlanId,setLocalPlanId] = useState<any>(selectedPlanId)

	return (
		<Box id="plans-start">
			{planActions.getPlanApiState() !== ApiState.LOADING
				? (plans || []).map((plan: Plan) => {
						return (
							<PlanTile
								key={plan.id}
								data={plan}
								isSelected={parseInt(selectedPlanId) == plan.id}
								source={source}
							/>
						);
				  })
				: [1, 2, 3, 4]?.map((i) => {
						return (
							<Box
								sx={{
									padding: '10px 28px',
									background: theme?.colors?.lencoPrimaryP4,
									borderRadius: '8px',
								}}
								key={i}
							>
								<Skeleton
									animation='wave'
									variant='rectangular'
									width='100%'
									height='140px'
									className='f-css-animations anm-progressive'
									sx={{
										background: '#9D8AD61A',
										borderRadius: '8px',
										':after': {
											background:
												'linear-gradient(90deg, rgba(182, 91, 159, 0) 0%, rgba(182, 91, 159, 0.2) 54.5%, rgba(182, 91, 159, 0) 100%)',
                                            animationDelay:`${i*0.1}s`
										},
									}}
								/>
							</Box>
						);
				  })}
				            <div id="sentinel" style={{ height: '1px', backgroundColor: 'transparent' }}></div>

		</Box>
	);
}

export default PlanTemp;
