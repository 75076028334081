import { segmentService } from '@/services/segment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatButton from '@/assets/chat.svg';
import { initalizeHaptik } from '@/hooks/useHaptik';
import { partnerService } from '@/services/partner';
import { setshowLogin } from '@/store/haptik/actions';
import { useUser } from '@/hooks/useUser';
import { useRouter } from 'next/router';

const pathBasedChatIconPosition = new Map([
	[
		'/products/[slug]',
		{
			bottom: 140,
		},
	],
	[
		'default',
		{
			bottom: 80,
		},
	],
]);

function Haptik() {
	const { isLoggedIn } = useSelector((state: any) => {
		return state.user;
	});
	const { getUserApiState } = useUser();

	const [segment, setSegment] = useState<string[]>();

	const [sdkPr, setSDKPr] = useState<any>(null);
	const [isFirstVisit, setIsFirstVisit] = useState<any>(null);
	const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<any>(false);

	const isRentalCustomer = segment?.includes('RENTAL_CUSTOMER');
	const isSaleCustomer = segment?.includes('SALE_CUSTOMER');
	const isUnlimitedCustomer = segment?.includes('UNLMTD_CUSTOMER');
	const userStore = useSelector((state: any) => state?.user);
	const hideComposer = isLoggedIn
		? !(isRentalCustomer || isSaleCustomer || isUnlimitedCustomer)
		: true;

	const dispatch: any = useDispatch();
	const router = useRouter();

	const destroyHaptikUserSession = () => {
		(window as any).HaptikSDK.destroy();
		setSDKPr(null);
		setIsSignUpSuccessful(false);
		document.getElementById('haptik-jssdk')?.remove();
	};

	useEffect(() => {
		if (isSignUpSuccessful) {
			destroyHaptikUserSession();
			setIsFirstVisit(null);
		}
		if (getUserApiState == 'SUCCESS') {
			segmentService.getSegment().then((res: any) => {
				setSegment(res?.data?.data?.segments);
			});
		}
	}, [isLoggedIn, getUserApiState]);

	function getChatIconPosition() {
		if (pathBasedChatIconPosition.has(router.pathname)) {
			return pathBasedChatIconPosition.get(router.pathname);
		} else {
			return pathBasedChatIconPosition.get('default');
		}
	}

	function _signupGuest(ghostUser: any) {
		// eslint-disable-next-line no-async-promise-executor

		return new Promise(async (resolve, reject) => {
			try {
				const { data: data } = await partnerService.generatePartnerToken({
					partnerName: 'haptik',
				});

				(window as any).HaptikSDK &&
					(window as any).HaptikSDK.signup(
						{
							auth_id: data.data,
							auth_code: data.data,
							custom_data: {
								name: 'Guest',
								medium: 'mweb',
								isLoggedIn: isLoggedIn?.toString(),
								isRentalCustomer: isRentalCustomer?.toString(),
								isSaleCustomer: isSaleCustomer?.toString(),
								isUnlimitedCustomer: isUnlimitedCustomer?.toString(),
							},
						},
						(status: any) => {
							if (status) {
								resolve(true);
							} else {
								reject(true);
							}
						}
					);
			} catch (error) {
				reject('[Haptik] SIGN UP ERROR');
			}
		});
	}

	function _subscriberSignUp(user: any) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await partnerService.generatePartnerToken({
					partnerName: 'haptik',
				});
				if (
					(window as any)?.HaptikSDK &&
					typeof (window as any)?.HaptikSDK?.signup === 'function'
				) {
					(window as any)?.HaptikSDK?.signup(
						{
							username: user?.first_name,
							mobile_no: user?.contact_no.toString(),
							email: user?.email_id,
							auth_id: user?.id.toString(),
							auth_code: response.data.data,
							custom_data: {
								medium: 'mweb',
								name: user?.first_name,
								mobile_no: user?.contact_no.toString(),
								email: user?.email_id,
								isLoggedIn: isLoggedIn?.toString(),
								isRentalCustomer: isRentalCustomer?.toString(),
								isSaleCustomer: isSaleCustomer?.toString(),
								isUnlimitedCustomer: isUnlimitedCustomer?.toString(),
							},
						},
						(status: any) => {
							if (status) {
								resolve('[Haptik] SIGN UP SUCCESS');
							} else {
								reject('[Haptik] SIGN UP ERROR');
							}
						}
					);
				}
			} catch (error) {
				console.error(error);
			}
		});
	}

	function initiateUserHandshake() {
		sdkPr
			.then(() =>
				isLoggedIn? _subscriberSignUp(userStore?.user): _signupGuest(userStore?.ghostUser)
			)
			.then(() => {
				setIsSignUpSuccessful(true);
				(window as any).HaptikSDK.launchMessage('Main Menu', null, true);
				setIsFirstVisit(false);
			})
			.catch(() => {
				return Promise.reject('[Haptik], An Error has occured');
			});
	}

	useEffect(() => {
		if (sdkPr) {
			initiateUserHandshake();
		}
	}, [sdkPr]);

	const showLoginModal = () => {
		(window as any)?.HaptikSDK?.hide();
		dispatch(setshowLogin(true));
	};

	const onClick = () => {
		if (sdkPr == null) {
			setSDKPr(initalizeHaptik(hideComposer, showLoginModal));
		} else {
			if (isFirstVisit === null) {
				(window as any)?.HaptikSDK?.launchMessage('Main Menu', null, true);
				setIsFirstVisit(false);
			} else {
				(window as any)?.HaptikSDK?.expandWidget();
			}
		}
	};

	return (
		<>
			{segment && (
				<div
					onClick={onClick}
					className={
						'haptik-lets-chat d-flex justify-content-center align-items-center'
					}
					style={{
						position: 'fixed',
						zIndex: '99',
						right: 28,
						bottom: 80,
						cursor: 'pointer',
						...getChatIconPosition(),
					}}
				>
					<ChatButton />
				</div>
			)}
		</>
	);
}

export default Haptik;
