import { useLocation } from '@/hooks/useLocation';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { getCityFromUrl, getLocationFromLocalStorage } from '@/utils/location';
import { useEffect } from 'react';
const cities = ENV.CITIES;

function isClientBot() {
	const botRegx = new RegExp(
		'(.*)AdsBot(.*)|(.*)googlebot(.*)|(.*)yahoo(.*)|(.*)bingbot(.*)|(.*)baiduspider(.*)|(.*)facebookexternalhit(.*)|(.*)twitterbot(.*)|(.*)rogerbot(.*)|(.*)embedly(.*)|(.*)bufferbot(.*)|(.*)quora(.*)|(.*)preview(.*)|(.*)showyoubot(.*)|(.*)outbrain(.*)|(.*)pinterest/0.(.*)|(.*)developers.google.com/+/web/snippet(.*)|(.*)www.google.com/webmasters/tools/richsnippets(.*)|(.*)slackbot(.*)|(.*)vkShare(.*)|(.*)W3C_Validator(.*)|(.*)redditbot(.*)|(.*)Applebot(.*)|(.*)WhatsApp(.*)|(.*)flipboard(.*)|(.*)tumblr(.*)|(.*)bitlybot(.*)|(.*)SkypeUriPreview(.*)|(.*)nuzzel(.*)|(.*)Discordbot(.*)|(.*)GooglesPagesSpeed(.*)|(.*)Qwantify(.*)|(.*)GooglesStructuredsDatasTestingsTool'
	);
	const isBot =
		botRegx.test(window.navigator.userAgent) ||
		botRegx.test(window.navigator.userAgent.toLowerCase());

	return isBot;
}
export const WithCitySelector = (WrappedComponent: any) => {
	const RenderingComponent = (props: any) => {
		const { locationActions } = useLocation();
		useEffect(() => {
			const pathCity = getCityFromUrl();
			const selectedLocationFromLocalStorage = getLocationFromLocalStorage();

			if (!pathCity) {
				if (isClientBot() || !selectedLocationFromLocalStorage?.pincode) {
					const selectedLocation = cities['bengaluru'];
					locationActions.updateSelectedLocation({
						cityId: selectedLocation.id,
						cityName: selectedLocation.name,
						pincode: selectedLocation.defaultPincode,
					});
				} else if (selectedLocationFromLocalStorage?.pincode) {
					locationActions.updateSelectedLocation({
						cityId: selectedLocationFromLocalStorage.cityId,
						cityName: selectedLocationFromLocalStorage.cityName,
						pincode: selectedLocationFromLocalStorage.pincode,
					});
				}
			} else {
				const selectedLocation = pathCity ? cities[pathCity] : null;
				if (selectedLocation) {
					locationActions.updateSelectedLocation({
						cityId: selectedLocation.id,
						cityName: selectedLocation.label,
						pincode: selectedLocation.defaultPincode,
					});
				} else {
					locationActions.triggerCitySelector(
						LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
					);
				}
			}
		}, []);

		return <WrappedComponent {...props} />;
	};

	return RenderingComponent;
};
