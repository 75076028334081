import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import EnterMobileNumber from './enter-mobile-number';
import VerifyOTP from './verify-otp';
import EmailLogin from './email-login';
import { authService } from '@/services/auth';
import LencoLoaderComponent from '../lenco-loader';
import useLencoState from '@/cutom-hooks/useLencoState';
import useForm from '@/utils/useForm';
import { useUser } from '@/hooks/useUser';
import { ApiState } from '@/models/generics';
import ArrowRight from '@/assets/login/arrow-right.svg';
import { emitMoeEvent } from '@/utils/events';

const formConfig = {
	mobile: {
		required: {
			isRequired: true,
			masg: 'Required',
		},
		valid: {
			regex: /^[6789]\d{9}$/,
			msg: 'Please enter a valid phone number.',
		},
	},
};

export enum AuthFlowSteps {
	SIGN_IN = 'SIGN_IN',
	VERIFY_OTP = 'VERIFY_OTP',
	EMAIL_LOGIN = 'EMAIL_LOGIN',
}

type LoginProps = {
	onLoginSuccess?: () => any;
	onClose?: () => void;
};

const Login = (props: LoginProps) => {
	const [currentStep, setCurrentStep] = useState<AuthFlowSteps>(
		AuthFlowSteps.SIGN_IN
	);
	const [userDetails, setUserDetails] = useState<any>({});
	const [otpCount, setOtpCount] = useState(0);
	const [loginError, setLoginError] = useState('');
	const [loginErrorCode, setLoginErrorCode] = useState('');
	const [loading, setLoading] = useState(false);
	const verifyingAccount = useLencoState<boolean>(false);
	const { getUserApiState } = useUser();

	const verify = async () => {
		verifyAccount(values);
	};
	const { values, errors, handleChange, handleSubmission } = useForm(
		formConfig,
		verify
	);

	const verifyAccount = async (values: any) => {
		emitMoeEvent('Login Mobile Number Submitted');
		const accountNumber = values.email
			? values.email.toLowerCase()
			: values.mobile;

		const payload = {
			account: accountNumber,
		};

		try {
			setLoading(true);
			verifyingAccount.setValue(true);
			const res = await authService.verifyPhone(payload);
			const data = res.data?.data;

			if (!!data) {
				const { otp_length, message } = data;

				setUserDetails((prev: any) => ({
					...prev,
					otp_length,
					message,
					mobile: accountNumber,
					email: values.email,
					otpCount,
				}));

				setCurrentStep(AuthFlowSteps.VERIFY_OTP);
				setLoading(false);
				setLoginError('');
				setLoginErrorCode('');
			}
		} catch (err) {
			const loginErr = (err as any).response?.data;
			setLoginError(loginErr?.error?.message);
			setLoginErrorCode(loginErr?.error?.code);
			return err;
		}
		verifyingAccount.setValue(false);
		setLoading(false);
	};

	function onClose() {
		props.onClose && props.onClose();
	}

	function onClickGoBack() {
		switch (currentStep) {
			case AuthFlowSteps.VERIFY_OTP: {
				setCurrentStep(AuthFlowSteps.SIGN_IN);
				break;
			}
			case AuthFlowSteps.SIGN_IN: {
				onClose();
				break;
			}
		}
	}

	if (getUserApiState == ApiState.LOADING) {
		return <LencoLoaderComponent />;
	}

	return (
		<>
			<Box sx={{ padding: '24px', width: '100%' }}>
				<Box sx={{ marginBottom: '96px' }} onClick={onClickGoBack}>
					<ArrowRight />
				</Box>
				{currentStep === AuthFlowSteps.SIGN_IN && (
					<EnterMobileNumber
						values={values}
						errors={errors}
						handleChange={handleChange}
						handleSubmission={handleSubmission}
						verifyMobileNumber={verifyAccount}
						verifyingAccount={verifyingAccount}
						loginError={loginError}
						loginErrorCode={loginErrorCode}
						userDetails={userDetails}
						loading={loading}
					/>
				)}
				{currentStep === AuthFlowSteps.VERIFY_OTP && (
					<VerifyOTP
						onLoginSuccess={props.onLoginSuccess}
						setCurrentStep={setCurrentStep}
						userDetails={userDetails}
						setUserDetails={setUserDetails}
						resendOTP={verifyAccount}
						loginError={loginError}
						loginErrorCode={loginErrorCode}
					/>
				)}
				{currentStep === AuthFlowSteps.EMAIL_LOGIN && (
					<EmailLogin
						onLoginSuccess={props.onLoginSuccess}
						setCurrentStep={setCurrentStep}
						userDetails={userDetails}
						setUserDetails={setUserDetails}
						verifyEmailId={verifyAccount}
					/>
				)}
			</Box>
		</>
	);
};

export default Login;
