import usePlan from "@/hooks/unlmtd/usePlan";
import useSubscription from "@/hooks/unlmtd/useSubscription"
import { useUser } from "@/hooks/useUser"
import Subscriptions from "@/pages/unlmtd/my/subscriptions";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect } from "react"

const nonWrappedPagesArray = ['/unlmtd/my/subscriptions','/unlmtd/payment_complete','/unlmtd/checkout/payment']

function WithSubscription(WrappedComponent:any) {
    const RenderedComponent =(props:any) =>{
        const {user,ghostUser} = useUser();
        const { subscription , subscriptionStatus ,subscriptionActions:{getSubscription}} = useSubscription()
        useEffect(()=>{
            if(user){
             getSubscription();
            }
        },[user,ghostUser])

        if((subscription?.subscriptionPlans?.length && subscription?.subscriptionPlans?.filter((sub:any)=>{
            return sub?.subscriptionPlanState != 'CANCELLED'
        }).length>0) && !nonWrappedPagesArray?.includes(window.location.pathname)){
            return <Box sx={{backgroundColor:'#222038'}}>
            <Subscriptions/>

            </Box>
            // window.location.href = '/unlmtd/my/subscriptions'
        }
        else{
        return <WrappedComponent {...props} />

        }
    }
    return RenderedComponent
}

export default WithSubscription;