import { styled, SxProps } from '@mui/system';
import { Box, Theme } from '@mui/material';

type IFlexBoxComponentProps = {
	direction?: string;
	justify?: keyof typeof justifyMap;
	align?: keyof typeof alignMap;
	flexType?: 'flex' | 'inline-flex';
	grow?: 0 | 1;
	wrap?: 'wrap' | 'no-wrap';
	sx?: SxProps<Theme>;
	width: string;
	fullWidth: boolean;
};
const justifyMap = {
	start: 'flex-start',
	end: 'flex-end',
	spaceBetween: 'space-between',
	between: 'space-between',
	spaceAround: 'space-around',
	spaceEvenly: 'space-evenly',
};

const alignMap = {
	start: 'flex-start',
	end: 'flex-end',
	spaceBetween: 'space-between',
	between: 'space-between',
	spaceAround: 'space-around',
	stretch: 'stretch',
};

const FlexBox = (props: any) => {
	const { flexType, direction, justify, align, wrap, grow, sx, ...restProps } =
		props;

	let width = props.width;

	if (props.fullWidth) {
		width = '100%';
	}

	return (
		<Box
			{...restProps}
			sx={{
				width,
				display: flexType || 'flex',
				flexDirection: direction || 'row',
				justifyContent: justify
					? justifyMap[justify as keyof typeof justifyMap]
					: 'center',
				alignItems: align ? alignMap[align as keyof typeof alignMap] : 'center',
				flexWrap: wrap || 'nowrap',
				flexGrow: grow || 0,
				...props.sx,
			}}
		></Box>
	);
};

// styled(Box)((props: FlexProps & React.CSSProperties) => {
//   const {flexType, direction, justify, align, wrap, grow, ...restProps} = props;
//   return {
//     'display': flexType || 'flex',
//     'flex-direction': direction || 'row',
//     'justify-content': justify ? justifyMap[justify] : 'center',
//     'align-items': align ? alignMap[align] : 'center',
//     'flex-wrap': wrap || 'nowrap',
//     'flex-grow': grow || 0,
//     ...restProps
//   }
// })

export default FlexBox;
