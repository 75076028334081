// import { Login } from '.';
import { useLogin } from '@/hooks/useLogin';
import Login from '.';
import { Drawer } from '@mui/material';
import { useEffect } from 'react';
import { emitMoeEvent } from '@/utils/events';

type AuthDrawerProps = {
	onClose?: () => void;
	open: boolean;
};

const AuthDrawer = (props: AuthDrawerProps) => {
	const { onLogin } = useLogin();

	function onClose() {
		props.onClose && props.onClose();
	}

	function onLoginSuccess() {
		onLogin && onLogin();
		onClose();
	}

	useEffect(() => {
		emitMoeEvent('Login Page Opened');
	}, [props.open]);

	return (
		<Drawer
			open={props.open}
			anchor='right'
			PaperProps={{ sx: { width: '100%' } }}
			disableScrollLock={true}
		>
			<Login onClose={onClose} onLoginSuccess={onLoginSuccess} />
		</Drawer>
	);
};

export default AuthDrawer;
