const CartIcon = () => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0.888971 1.77794C0.398144 1.77794 0 1.3798 0 0.888971C0 0.398144 0.398144 0 0.888971 0H2.51812C2.9795 0 3.40496 0.175831 3.72644 0.467772C4.04375 0.755639 4.25847 1.15906 4.30523 1.62017L4.36847 2.23544L22.3055 2.22599V2.22229C22.4016 2.22229 22.4992 2.23099 22.5966 2.24803C22.9633 2.31229 23.2877 2.4958 23.532 2.75404C23.5564 2.77738 23.5798 2.80238 23.6021 2.82886C23.8509 3.12515 24 3.50737 24 3.91597C24 3.99338 23.994 4.07505 23.9814 4.16032L23.9743 4.20736L22.5126 12.4581C22.3764 13.2534 21.3898 14.429 21.3898 14.429C21.3898 14.429 20.062 15.2108 19.2544 15.2108H5.69836L5.79734 16.1755C5.83771 16.5825 6.00595 16.9447 6.259 17.2266C6.49733 17.4916 6.80937 17.6859 7.15973 17.7772H9.13767L9.22563 17.7759L9.3135 17.7772H18.3577L18.446 17.7759L18.5341 17.7772H22.2378C22.7286 17.7772 23.1268 18.1754 23.1268 18.6662C23.1268 19.157 22.7286 19.5551 22.2378 19.5551H21.2583C21.4498 19.9588 21.557 20.4105 21.557 20.8869C21.557 21.7454 21.2086 22.5236 20.6457 23.0866C20.0827 23.6496 19.3045 23.9979 18.446 23.9979C17.5872 23.9979 16.8095 23.6496 16.2466 23.0866C15.6835 22.5236 15.335 21.7457 15.335 20.8869C15.335 20.4105 15.4423 19.9588 15.634 19.5551H12.0379C12.2298 19.9588 12.3372 20.4105 12.3372 20.8872C12.3372 21.746 11.9886 22.5239 11.4256 23.0869C10.8626 23.6499 10.0847 23.9984 9.22563 23.9984C8.36657 23.9984 7.58862 23.6499 7.02566 23.0869C6.46261 22.5239 6.1141 21.746 6.1141 20.8872C6.1141 20.3578 6.24669 19.8594 6.48002 19.4226C5.88104 19.2229 5.3516 18.8723 4.93985 18.414C4.44402 17.862 4.11412 17.151 4.03384 16.356L2.54173 1.80081C2.54117 1.79137 2.53729 1.78452 2.53229 1.78007C2.5309 1.77896 2.52543 1.77794 2.51812 1.77794H0.888971Z'
				fill='#777777'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M4.5498 4.00597L5.516 13.4329H19.2542C19.6313 13.4329 19.9778 13.2996 20.2436 13.0747C20.5105 12.8486 20.7004 12.5293 20.7622 12.1595L20.7633 12.1529L20.7622 12.1526L22.2071 3.99652L4.5498 4.00597ZM16.6725 7.82953C17.1612 7.82953 17.5578 8.22619 17.5578 8.71489C17.5578 9.20359 17.1612 9.60016 16.6725 9.60016L10.0065 9.60386C9.5178 9.60386 9.12114 9.20729 9.12114 8.7185C9.12114 8.22981 9.5178 7.83324 10.0065 7.83324L16.6725 7.82953ZM9.2829 19.5551H9.1679C8.82096 19.5696 8.50856 19.715 8.2805 19.9425L8.28208 19.9441C8.04115 20.185 7.8918 20.5186 7.8918 20.8872C7.8918 21.2557 8.04115 21.5893 8.28208 21.8302C8.523 22.0711 8.85688 22.2205 9.2254 22.2205C9.59391 22.2205 9.92771 22.0711 10.1687 21.8302C10.4096 21.5893 10.559 21.2557 10.559 20.8872C10.559 20.5186 10.4096 20.185 10.1687 19.9441L10.1705 19.9425C9.94243 19.715 9.62984 19.5696 9.2829 19.5551ZM18.5033 19.5551H18.3885C18.0432 19.5696 17.7313 19.7158 17.503 19.9441C17.2621 20.185 17.1127 20.5186 17.1127 20.8869C17.1127 21.2554 17.2621 21.589 17.503 21.8299C17.7436 22.0707 18.0772 22.2199 18.4458 22.2199C18.8143 22.2199 19.1481 22.0707 19.3888 21.8299C19.6294 21.5893 19.7788 21.2554 19.7788 20.8869C19.7788 20.5184 19.6294 20.1849 19.3888 19.9441C19.1605 19.7158 18.8489 19.5696 18.5033 19.5551Z'
				fill='white'
			/>
		</svg>
	);
};

export default CartIcon;
