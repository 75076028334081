/* eslint-disable @next/next/no-img-element */
import Box from '@mui/system/Box';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { getTopPaddingFromAspectRatio } from './utils/aspectRatioHelper';
import { sourceGenerator } from './utils/imageUrlHelper';
import {
	getInterSectionObserver,
	IntersectionObserverConfigOptions,
} from './utils/intersectionObserverHelper';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

interface ILencoImageProps {
	aspectRatio?: number;
	url: string;
	sources?: [];
	sizes?: string;
	className?: string;
	lazy?: boolean;
	alt?: string;
	reblur?: boolean;
	loading?: boolean;
	intersectionObserverConfig?: IntersectionObserverConfigOptions;
	imageCss?: CSSProperties;
	containerSx?: SxProps<Theme>;
	withLazyImage?: boolean;
}

export const LencoImage = (props: ILencoImageProps) => {
	let {
		aspectRatio,
		url,
		sizes = '',
		lazy = true,
		alt = '',
		imageCss = {},
		containerSx = {},
		withLazyImage = true,
	} = props;

	const imgRef: any = React.createRef();
	const imageIntersectionObserver = getInterSectionObserver();
	const [{ src, srcset }, setSources] = useState<{
		src: string | undefined;
		srcset: string | undefined;
	}>({ src: '', srcset: '' });
	useEffect(() => {
		if (url && imageIntersectionObserver) {
			lazy && imgRef.current.classList.add('lazy');
			imageIntersectionObserver.observe(imgRef.current);
		}
		return () => {
			if (imageIntersectionObserver) {
				imageIntersectionObserver.disconnect();
			}
		};
	});

	const paddingTop: any = {
		paddingTop: aspectRatio ? getTopPaddingFromAspectRatio(aspectRatio) : '0px',
	};

	const containerRef = useRef();
	useEffect(() => {
		const containerBox = containerRef.current;
		const clientWidth = containerBox && (containerBox as any)?.clientWidth;
		const overrideOptions = {
			...(clientWidth
				? {
						width: Math.ceil(clientWidth / 50) * 50,
						crop: 'scale',
				  }
				: {}),
		};
		const { src, srcset } = sourceGenerator(url, overrideOptions);
		setSources({ src, srcset });
	}, []);
	return (
		<Box
			ref={containerRef}
			sx={{
				position: 'relative',
				img: {
					position: 'absolute',
					top: '0px',
					'&.lazy': {
						'&:not(.loaded)': {
							filter: 'blur(5px)',
						},
						'&.loaded': {
							filter: 'blur(0)',
						},
					},
				},
				...containerSx,
			}}
			className={`${props.className ? props.className : ''}`}
			style={paddingTop}
		>
			<img
				src={withLazyImage && lazy ? src : undefined}
				alt={alt}
				style={{
					height: 'auto',
					width: '100%',
					visibility: src || srcset ? 'visible' : 'hidden',
					...imageCss,
				}}
				data-srcset={srcset}
				sizes={sizes}
				ref={imgRef}
			/>
		</Box>
	);
};
