import FlexBox from '@/atomic-components/flex-box';
import { LencoImage } from '@/atomic-components/image';
import { Box } from '@mui/material';
import CrossIcon from '@/assets/PDP/x.svg';
import Vertical from './verticals';
import { getColor } from '@/utils/getColor';
import { useEffect, useState } from 'react';
import BuyIcon from '@/assets/navigations/buy-icon.svg';
import RentIcon from '@/assets/navigations/rent-icon.svg';
import OverlayComponent from '../overlay';
import { layoutService } from '@/services/layout-service';
import { CategoriesData } from '@/services/category/model';

type NavigationBarProps = {
	isOpen: boolean;
	closeDrawer: () => void;
};

const Verticals = [
	{ varticalName: 'Buy', icon: <BuyIcon /> },
	{ varticalName: 'Rent', icon: <RentIcon /> },
];

const NavigationBar = (props: NavigationBarProps) => {
	const [buyCategoryData, setBuyCategoryData] = useState<CategoriesData>();
	const [rentCategoryData, setRentCategoryData] = useState<CategoriesData>();

	useEffect(() => {
		const fetchData = async () => {
			const categoryData = await layoutService.getCategories();
			setBuyCategoryData(categoryData?.data?.data?.buy);
			setRentCategoryData(categoryData?.data?.data?.rent);
		};
		if (!buyCategoryData) {
			fetchData();
		}
	}, []);

	return (
		<Box
			position='relative'
			sx={{
				position: 'fixed',
				top: '0px',
				zIndex: '9999',
				transform: `translateX(${props.isOpen ? '0' : ' -100%'})`,
				transition: 'none',
			}}
		>
			<OverlayComponent isOpen={props.isOpen} onClick={props.closeDrawer} />
			<Box
				sx={{
					top: 0,
					width: '85vw',
					position: 'fixed',
					height: '100vh',
					background: 'white',
					transform: `translateX(${props.isOpen ? '0' : ' -100%'})`,
					overflow: 'scroll',
					transition: 'all 160ms ease-in-out',
				}}
			>
				<FlexBox
					direction='column'
					align='start'
					justify='start'
					sx={{
						padding: '12px 24px 12px 24px',
						background: '#fff',
					}}
				>
					<FlexBox
						sx={{
							justifyContent: 'space-between',
							width: '100%',
							borderBottom: `1px solid ${getColor('Gray -5')}`,
							paddingBottom: '24px',
							paddingLeft: '8px',
							paddingRight: '6px',
							position: 'sticky',
							top: '10px',
							overflow: 'hidden',
						}}
					>
						<Box height='14px' width='130px'>
							<LencoImage
								lazy={false}
								url={
									'https://assets.furlenco.com/s3-furlenco-images/grogu/evolve-mweb-header-logo.png'
								}
							/>
						</Box>
						<Box onClick={() => props.closeDrawer()}>
							<CrossIcon />
						</Box>
					</FlexBox>
					{buyCategoryData && rentCategoryData && (
						<Vertical
							buyCategoryData={buyCategoryData.categories}
							rentCategoryData={rentCategoryData.categories}
							closeDrawer={props.closeDrawer}
						/>
					)}
				</FlexBox>
			</Box>
		</Box>
	);
};

export default NavigationBar;
