import useLencoState from '@/cutom-hooks/useLencoState';
import { getColor } from '@/utils/getColor';
import { Box, Skeleton, Typography } from '@mui/material';
import LocationPin from '@/assets/location_pin.svg';
import { useLocation } from '@/hooks/useLocation';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';

const style = {
	pin: {
		fontFamily: 'Work Sans',
		fontWeight: '500',
		fontSize: '10px',
		color: getColor('Gray -3'),
		marginBottom: '0px',
	},
	pinCode: {
		fontFamily: 'Work Sans',
		fontWeight: '600',
		fontSize: '12px',
		color: getColor('Gray -2'),
		marginBottom: '0px',
	},
};

type SelectedLocationDisplayProps = {
	pincode: number;
};

export const SelectedLocationDisplay = (
	props: SelectedLocationDisplayProps
) => {
	const { selectedLocation, locationActions } = useLocation();

	function openLocationSelector() {
		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
		);
	}

	return (
		<>
			<Box
				sx={{ display: 'flex', alignItems: 'center' }}
				onClick={openLocationSelector}
			>
				<Box sx={{ height: '24px', pr: '8px' }}>
					<LocationPin />
				</Box>
				<Box>
					<Typography sx={style.pin}>Delivery to</Typography>
					{props.pincode ? (
						<Typography sx={style.pinCode}>{props.pincode}</Typography>
					) : (
						<Skeleton width={80} />
					)}
				</Box>
			</Box>
		</>
	);
};
