import { useEffect, useState } from 'react';

type FormConfig = {
  [key: string]: {
    defaultValue: string,
    required?: {
      isRequired: boolean,
      msg: string
    },
    valid?: {
      regex: RegExp,
      msg: string
    }
    customValidation?: (value: string) => { isValid: boolean, message: string | undefined },
    onChange?: (currentValue: string, previousValue: string) => string;
  }
};

function isEmpty(config: any, value: any) {
  return config.isRequired && !value ? true : false;
}

function isInvalid(config: any, value: any) {
  if (config.regex) {
    return new RegExp(config.regex).test(value) ? false : true;
  }
  return false;
}

function validate(formConfig: FormConfig, values: any) {
  const fields = Object.keys(formConfig);
  return fields.reduce((errors: { [s: string]: string }, field) => {
    const constraintsConfig = formConfig[field];
    const constraints = Object.keys(constraintsConfig);
    const value = values[field];
    constraints.some(constraint => {
      switch (constraint) {
        case 'required':
          if (isEmpty(constraintsConfig.required, value)) {
            errors[field] = (constraintsConfig.required && constraintsConfig.required.msg) || 'Required';
            return true;
          }
          break;
        case 'valid':
          if (isInvalid(constraintsConfig.valid, value)) {
            errors[field] = (constraintsConfig.valid && constraintsConfig.valid.msg) || 'Invalid';
            return true;
          }
          break;
        case 'defaultValue':
          return false;
        case 'customValidation':
          if (constraintsConfig.customValidation) {
            const validityCheck = constraintsConfig.customValidation(value);
            if (!validityCheck.isValid) {
              errors[field] = validityCheck.message || 'Error Validation Failed';
              return true;
            }
          }
          break;
        default:
          return true;
      }
    });
    console.log("Form validte", errors);
    return errors;
  }, {});
}

function useForm(formConfig: any, callback?: any) {
  const [values, setValues] = useState<{ [s: string]: string }>({});
  const [errors, setErrors] = useState<{ [s: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    setValues(Object.keys(formConfig).reduce((acc: any, element: string) => {
      const currentConfig = formConfig[element];
      if (typeof currentConfig.defaultValue === 'string' || !!currentConfig.defaultValue) {
        return { ...acc, [element]: currentConfig.defaultValue };
      } else {
        return acc;
      }
    }, {}));
    setIsSubmitting(false);
  }, [formConfig]);

  const handleSubmission = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const validateValues = validate(formConfig, values);
    setErrors(validateValues);
    if (Object.keys(validateValues).length === 0 && callback) {
      callback();
    }
  };

  const handleChange = (event: any) => {
    event.persist();
    setValues((currentValues: any) => {
      const processedValue = formConfig[event.target.name].onChange && formConfig[event.target.name].onChange(event.target.value, currentValues[event.target.name]);
      const finalValue = typeof processedValue === 'string' ? processedValue : event.target.value;
      return {
        ...currentValues,
        [event.target.name]: finalValue
      };
    });

    isSubmitting && setErrors((prevError) => {
      const constraintsConfig = formConfig[event.target.name];
      const finalError = validateIndividual(constraintsConfig, event.target.value);
      if (finalError) {
        return { ...prevError, [event.target.name]: finalError };
      } else {
        const { [event.target.name]: name, ...rest } = prevError;
        return rest;
      }
    });
  };

  function validateIndividual(constraintsConfig: any, value: string) {
    const constraints = Object.keys(constraintsConfig);
    let error = null;
    constraints.some(constraint => {
      switch (constraint) {
        case 'required':
          if (isEmpty(constraintsConfig.required, value)) {
            error = (constraintsConfig.required && constraintsConfig.required.msg) || 'Required';
            return true;
          }
          break;
        case 'valid':
          if (isInvalid(constraintsConfig.valid, value)) {
            error = (constraintsConfig.valid && constraintsConfig.valid.msg) || 'Invalid';
            return true;
          }
          break;
        case 'defaultValue':
          return false;
        case 'customValidation':
          if (constraintsConfig.customValidation) {
            const validityCheck = constraintsConfig.customValidation(value);
            if (!validityCheck.isValid) {
              error = validityCheck.message || 'Error Validation Failed';
              return true;
            }
          }
          break;
        default:
          return true;
      }
    });
    return error;
  }

  return {
    values,
    errors,
    handleChange,
    handleSubmission,
  };
}

export default useForm;
