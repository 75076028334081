const imageOptions = {
  quality: 'auto',
  fetchFormat: 'auto',
  dpr: '',
};

const UrlBuilder = (cloudinaryPublicId: string, imageOptions: { quality: string, fetchFormat: string, dpr: number, width: number; }) => {
  const protocol = 'https:';
  let finalUrl = `${protocol}//assets.furlenco.com/image/upload/c_scale,f_auto,q_auto:eco,fl_progressive`;
  finalUrl = imageOptions.dpr ? `${finalUrl},dpr_${imageOptions.dpr}` : finalUrl;
  finalUrl = imageOptions.width ? `${finalUrl},w_${imageOptions.width}` : finalUrl;
  return `${finalUrl}/${cloudinaryPublicId}`;
};

const getCloudinaryPublicId = (url: string) => {
  const keyRegex = /(?<protocol>.*)assets\.furlenco\.com\/(?<key>.*)/g;
  const matches = url?.matchAll(keyRegex);
  const nextMatch = matches?.next();
  const key = nextMatch?.value?.groups?.key;
  return key;
};
const buildImageUrl = (cloudinaryPublicId: string, overrideOptions?: any) => {
  const cloudinarySrcSetURLs: string[] = [];
  const imgOptions: any = { ...imageOptions };
  for (let i = 1; i < 5; i++) {
    imgOptions.dpr = i.toFixed(1);
    const srcSet: string = UrlBuilder(cloudinaryPublicId, { ...imgOptions, ...overrideOptions });
    if (srcSet) {
      cloudinarySrcSetURLs.push(srcSet + ' ' + i + 'x');
    }
  }
  return cloudinarySrcSetURLs.join(', ');
};
const buildCloudinaryImageSrcSet = (cloudinaryPublicId: string, overrideOptions?: any) => {
  return buildImageUrl(cloudinaryPublicId, overrideOptions);
};

const buildCloudinaryTinyImageUrl = (cloudinaryPublicId: string, overrideOptions?: any) => {
  const preloadImageOptions = {
    width: 20,
    quality: 0.5,
    crop: 'scale',
    fetchFormat: 'auto',
    effect: 'blur:75'
  };
  return UrlBuilder(cloudinaryPublicId, Object.assign({}, preloadImageOptions, overrideOptions));
};

export const sourceGenerator = (url: string, overrideOptions?: any) => {
  if (url) {
    if (url.includes('assets.furlenco.com')) {
      const cloudinaryPublicId = getCloudinaryPublicId(url);
      return {
        srcset: buildCloudinaryImageSrcSet(cloudinaryPublicId, overrideOptions),
        src: buildCloudinaryTinyImageUrl(cloudinaryPublicId),
      };
    } else {
      return { src: url, srcset: undefined };
    }
  }
  else {
    return { src: "https://assets.furlenco.com/s3-furlenco-images/grogu/Group+5.png", srcset: undefined };
  }
};
