import '../styles/globals.css';
import '../styles/global-fonts-dev.css';
import '../styles/Home.module.css';
import '../styles/loader-animation.scss'
import store from '@/store';
import { Provider } from 'react-redux';
import { withRouter } from 'next/router';
import RootApp, { AppPropsWithLayout } from '../src/components/root';
import UnlmtdRootApp from '../src/components/unlmtd/root'
import ErrorScreen from '@/components/error-screen';
import LencoHeader from '@/components/lenco-header';
import useMoengageExperiments from '@/hooks/useMoengageExperiments';
import { useEffect, useState } from 'react';
import unlmtdStore from '@/store/unlmtd';

function MyApp(props: AppPropsWithLayout) {
	// const { fetchVariant } = useMoengageExperiments();
	// const [moeExperimentVairant] = useState(fetchVariant());
	const [theme, setTheme] = useState<string>();
	useEffect(() => {
		if (props?.router?.pathname?.includes('unlmtd')) {
			setTheme('unlmtd');
		} else {
			setTheme('sf');
		}
	}, [props?.router?.pathname]);

	return (
		<>
			{theme == 'unlmtd' ? (
				<Provider store={unlmtdStore}>
					<LencoHeader />
					<ErrorScreen>
						{/* <noscript
					dangerouslySetInnerHTML={{
						__html: `<img height=“1” width=“1" style=“display:none”
				src=“https://www.facebook.com/tr?id=434445376717057&ev=PageView&noscript=1”/>`,
					}}
				/>
				<noscript
					dangerouslySetInnerHTML={{
						__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5LDD26Q"
	 			 height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
					}}
				/> */}
						<UnlmtdRootApp {...props} />
					</ErrorScreen>
				</Provider>
			): (
				<Provider store={store}>
					<LencoHeader />
					<ErrorScreen>
						{/* <noscript
					dangerouslySetInnerHTML={{
						__html: `<img height=“1” width=“1" style=“display:none”
				src=“https://www.facebook.com/tr?id=434445376717057&ev=PageView&noscript=1”/>`,
					}}
				/>
				<noscript
					dangerouslySetInnerHTML={{
						__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5LDD26Q"
	 			 height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
					}}
				/> */}
						<RootApp {...props} />
					</ErrorScreen>
				</Provider>
			)}
		</>
	);
}

export default withRouter(MyApp);
