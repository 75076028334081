const colors = {
    "Minty 0": { color: '#069BAA' },
    "Minty +1": { color: '#047992' },
    "Minty +2": { color: '#035C7A' },
    "Minty +3": { color: '#003B58' },
    "Minty +4": { color: '#012D4C' },
    "Minty -1": { color: '#40B6C2' },
    "Minty -2": { color: '#76CDD6' },
    "Minty -3": { color: '#B4E1E5' },
    "Minty -4": { color: '#CEEDF0' },
    "Minty -5": { color: '#E6F5F6' },
    "Minty -6": { color: '#F2FAFB' },
    "Navy 0": { color: '#424E99' },
    "Navy +1": { color: '#3B4483' },
    "Navy +2": { color: '#28306E' },
    "Navy +3": { color: '#191F58' },
    "Navy +4": { color: '#0F1449' },
    "Navy -1": { color: '#6F7BC1' },
    "Navy -2": { color: '#93A0E0' },
    "Navy -3": { color: '#BDC7F4' },
    "Navy -4": { color: '#DDE3F9' },
    "Navy -5": { color: '#EEF1FA' },
    "Navy -6": { color: '#F9FAFE' },
    "Orange 0": { color: '#EE5921' },
    "Orange +1": { color: '#BD3614' },
    "Orange +2": { color: '#9E220F' },
    "Orange +3": { color: '#7F110A' },
    "Orange +4": { color: '#530404' },
    "Orange -1": { color: '#F48C56' },
    "Orange -2": { color: '#F9AD78' },
    "Orange -3": { color: '#FDCFA6' },
    "Orange -4": { color: '#FEEAD2' },
    "Orange -5": { color: '#FEF4E8' },
    "Orange -6": { color: '#FFFBF6' },
    "Yellow 0": { color: '#FFDD4B' },
    "Yellow +1": { color: '#DBB936' },
    "Yellow +2": { color: '#B79625' },
    "Yellow +3": { color: '#937517' },
    "Yellow +4": { color: '#7A5D0E' },
    "Yellow -1": { color: '#FFE878' },
    "Yellow -2": { color: '#FFEE93' },
    "Yellow -3": { color: '#FFF5B7' },
    "Yellow -4": { color: '#FFFBDB' },
    "Yellow -5": { color: '#FFFDED' },
    "Pink 0": { color: '#FF5AD0' },
    "Pink +1": { color: '#DB41BE' },
    "Pink +2": { color: '#B72DAB' },
    "Pink +3": { color: '#921C93' },
    "Pink +4": { color: '#6F117A' },
    "Pink -1": { color: '#FF83CF' },
    "Pink -2": { color: '#FF9CCF' },
    "Pink -3": { color: '#FFBDD8' },
    "Pink -4": { color: '#FFDEE8' },
    "Pink -5": { color: '#FFF2F6' },
    "Blue 0": { color: '#4DC9FF' },
    "Blue +1": { color: '#389EDB' },
    "Blue +2": { color: '#2678B7' },
    "Blue +3": { color: '#185593' },
    "Blue +4": { color: '#0E3D7A' },
    "Blue -1": { color: '#79DFFF' },
    "Blue -2": { color: '#94ECFF' },
    "Blue -3": { color: '#B7F7FF' },
    "Blue -4": { color: '#DBFDFF' },
    "Blue -5": { color: '#EDFEFF' },
    "Gray 0": { color: '#222222' },
    "Gray -1": { color: '#555555' },
    "Gray -2": { color: '#777777' },
    "Gray -3": { color: '#999999' },
    "Gray -4": { color: '#BCBCBC' },
    "Gray -5": { color: '#E8E8E8' },
    "Gray -6": { color: '#F3F3F3' },
    "Gray -7": { color: '#F9F9F9' },
    "Danger +1": { color: '#CB1538' },
    "Danger -1": { color: '#FA5B69' },
    "Danger -4": { color: '#FEDBD5' },
    "Danger -2": { color: '#FE7B79' },
    "Success +1": { color: '#1D9428' },
    "Success -4": { color: '#E6FCD8' },

    "Danger +2": { color: '#940924' },
    "Danger -3": { color: '#FEAFA8' },

    "Success +2": { color: '#096222' },
    "Warning +2": { color: '#713707' },
    "Danger 0": { color: '#F9254D' },
    "Success 0": { color: '#43CD39' },
    'Warning 0': { color: '#ED9F23' },

    "Violet-6": { color: '#EFECF9'},
    "Violet-5": { color: '#DFD8F2'},
    "Violet-4": { color: '#CFC5EB'},
    "Violet-3": { color: '#B6A8E1'},
    "Violet-2": { color: '#9D8AD6'},
    "Violet-1": { color: '#856DCC'},
    "Violet0": { color: '#715BB6'},
    "Violet+1": { color: '#6653A4'},
    "Violet+2": { color: '#4F4280'},
    "Violet+3": { color: '#39315C'},
    "Violet+4": { color: '#222038'},
    "Violet+5": { color: '#A09FC1'},
	"Purple-6": { color: '#F2E3EE'},
    "Purple-5": { color: '#E8CCE1'},
    "Purple-4": { color: '#DEB6D4'},
    "Purple-3": { color: '#D49FC7'},
    "Purple-2": { color: '#CA88B9'},
    "Purple-1": { color: '#C072AC'},
    "Purple0": { color: '#B65B9F'},
    "Purple+1": { color: '#934482'},
    "Purple+2": { color: '#702E65'},
    "Purple+3": { color: '#4D1747'},
    "Purple+4": { color: '#2A002A'},

};

export const getColor = (colorName: String) => {
    let camelCasedColorName:string = '';
    if(colorName){
        camelCasedColorName = colorName[0]?.toUpperCase() + colorName.substring(1) as keyof typeof colors;
    }
    

    return (
        colors[camelCasedColorName as keyof typeof colors] ? colors[camelCasedColorName as keyof typeof colors].color : '#FFFFFF'
    );
    
    
};