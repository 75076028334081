const CartIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_89_21248)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.88897 5.77794C8.39814 5.77794 8 5.3798 8 4.88897C8 4.39814 8.39814 4 8.88897 4H10.5181C10.9795 4 11.405 4.17583 11.7264 4.46777C12.0437 4.75564 12.2585 5.15906 12.3052 5.62017L12.3685 6.23544L30.3055 6.22599V6.22229C30.4016 6.22229 30.4992 6.23099 30.5966 6.24803C30.9633 6.31229 31.2877 6.4958 31.532 6.75404C31.5564 6.77738 31.5798 6.80238 31.6021 6.82886C31.8509 7.12515 32 7.50737 32 7.91597C32 7.99338 31.994 8.07505 31.9814 8.16032L31.9743 8.20736L30.5126 16.4581C30.3764 17.2534 29.3898 18.429 29.3898 18.429C29.3898 18.429 28.062 19.2108 27.2544 19.2108H13.6984L13.7973 20.1755C13.8377 20.5825 14.0059 20.9447 14.259 21.2266C14.4973 21.4916 14.8094 21.6859 15.1597 21.7772H17.1377L17.2256 21.7759L17.3135 21.7772H26.3577L26.446 21.7759L26.5341 21.7772H30.2378C30.7286 21.7772 31.1268 22.1754 31.1268 22.6662C31.1268 23.157 30.7286 23.5551 30.2378 23.5551H29.2583C29.4498 23.9588 29.557 24.4105 29.557 24.8869C29.557 25.7454 29.2086 26.5236 28.6457 27.0866C28.0827 27.6496 27.3045 27.9979 26.446 27.9979C25.5872 27.9979 24.8095 27.6496 24.2466 27.0866C23.6835 26.5236 23.335 25.7457 23.335 24.8869C23.335 24.4105 23.4423 23.9588 23.634 23.5551H20.0379C20.2298 23.9588 20.3372 24.4105 20.3372 24.8872C20.3372 25.746 19.9886 26.5239 19.4256 27.0869C18.8626 27.6499 18.0847 27.9984 17.2256 27.9984C16.3666 27.9984 15.5886 27.6499 15.0257 27.0869C14.4626 26.5239 14.1141 25.746 14.1141 24.8872C14.1141 24.3578 14.2467 23.8594 14.48 23.4226C13.881 23.2229 13.3516 22.8723 12.9399 22.414C12.444 21.862 12.1141 21.151 12.0338 20.356L10.5417 5.80081C10.5412 5.79137 10.5373 5.78452 10.5323 5.78007C10.5309 5.77896 10.5254 5.77794 10.5181 5.77794H8.88897Z" fill="#BCBCBC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5498 8.00597L13.516 17.4329H27.2542C27.6313 17.4329 27.9778 17.2996 28.2436 17.0747C28.5105 16.8486 28.7004 16.5293 28.7622 16.1595L28.7633 16.1529L28.7622 16.1526L30.2071 7.99652L12.5498 8.00597ZM24.6725 11.8295C25.1612 11.8295 25.5578 12.2262 25.5578 12.7149C25.5578 13.2036 25.1612 13.6002 24.6725 13.6002L18.0065 13.6039C17.5178 13.6039 17.1211 13.2073 17.1211 12.7185C17.1211 12.2298 17.5178 11.8332 18.0065 11.8332L24.6725 11.8295ZM17.2829 23.5551H17.1679C16.821 23.5696 16.5086 23.715 16.2805 23.9425L16.2821 23.9441C16.0412 24.185 15.8918 24.5186 15.8918 24.8872C15.8918 25.2557 16.0412 25.5893 16.2821 25.8302C16.523 26.0711 16.8569 26.2205 17.2254 26.2205C17.5939 26.2205 17.9277 26.0711 18.1687 25.8302C18.4096 25.5893 18.559 25.2557 18.559 24.8872C18.559 24.5186 18.4096 24.185 18.1687 23.9441L18.1705 23.9425C17.9424 23.715 17.6298 23.5696 17.2829 23.5551ZM26.5033 23.5551H26.3885C26.0432 23.5696 25.7313 23.7158 25.503 23.9441C25.2621 24.185 25.1127 24.5186 25.1127 24.8869C25.1127 25.2554 25.2621 25.589 25.503 25.8299C25.7436 26.0707 26.0772 26.2199 26.4458 26.2199C26.8143 26.2199 27.1481 26.0707 27.3888 25.8299C27.6294 25.5893 27.7788 25.2554 27.7788 24.8869C27.7788 24.5184 27.6294 24.1849 27.3888 23.9441C27.1605 23.7158 26.8489 23.5696 26.5033 23.5551Z" fill="#39315C"/>
</g>
<defs>
<filter id="filter0_d_89_21248" x="0" y="0" width="40" height="39.9984" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_21248"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_21248" result="shape"/>
</filter>
</defs>
</svg>

	);
};

export default CartIcon;
