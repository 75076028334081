import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setHaptik, setshowLogin} from '@/store/haptik/actions'
import { HaptikConfig } from '@/route-config/haptikConfig';
import AuthDrawer from '@/components/login/auth-drawer';

export const WithRouterConfig = (props:any) => {
		const { isHaptikEnabled,showLogin } = useSelector((state: any) => {
			return state?.routerConfig ;
		});
        const router=useRouter();
        const dispatch:any=useDispatch();
        useEffect(()=>{
            if(HaptikConfig.includes(router.pathname)){
                dispatch(setHaptik(true))
            }else{
                dispatch(setHaptik(false))
            }
        },[router])
	return (
        <Box sx={{ display: isHaptikEnabled ? 'block' : 'none' }}>
            <AuthDrawer open={showLogin} onClose={()=>dispatch(setshowLogin(false))}/>
            {props.children}
        </Box>
    )
};
