import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import useMoEngage from '@/hooks/useMoengage';
import Haptik from '@/components/haptik';
import { dataLayerPush } from '@/utils/callcustomevent';
import { useLocation } from '@/hooks/useLocation';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { ApiState, ViewStates } from '@/models/generics';
import { readUserSelectedLocationFromStorageAction } from '@/store/location/actions';
import { useUser } from '@/hooks/useUser';
import { useLogin } from '@/hooks/useLogin';
import { mainTheme, unlmtdTheme } from '@/utils/theme'
import { NextPage, NextComponentType, NextPageContext } from 'next';
import { useAppConfig } from '@/hooks/useAppConfig';
import BaseLayout, { BaseLayoutProps } from '@/layouts/BaseLayout';
import dynamic from 'next/dynamic';
import { WithRouterConfig } from '@/hocs/withRouterConfig';
import Notifications from './notifications';
import { WithCitySelector } from '@/hocs/withCitySelector';
import UnlmtdBaseLayout from '@/layouts/UnlmtdBaseLayout';
import { Provider } from 'react-redux';
import LencoHeader from '../../lenco-header';
import ErrorScreen from '../../error-screen';
import PlanSelector from '../PlanSelector';
import PlanSelectorDrawer from '../PlanSelector';
import usePlan from '@/hooks/unlmtd/usePlan';
import { PlanSelectorStatus } from '@/store/unlmtd/slices/planConfigSlice';
import { Box } from '@mui/material';
import WithSubscription from '@/hocs/unlmtd/withSubscription';
import ChatBotFloatingButton from '../YmChatbot';

const UnlmtdLocationSelector = dynamic(
	() => import('../location-selector/location-selector-drawer')
);

const UnlmtdAuthDrawer = dynamic(() => import('../login/auth-drawer'));

function isClientBot() {
	const botRegx = new RegExp(
		'(.*)AdsBot(.*)|(.*)googlebot(.*)|(.*)yahoo(.*)|(.*)bingbot(.*)|(.*)baiduspider(.*)|(.*)facebookexternalhit(.*)|(.*)twitterbot(.*)|(.*)rogerbot(.*)|(.*)embedly(.*)|(.*)bufferbot(.*)|(.*)quora(.*)|(.*)preview(.*)|(.*)showyoubot(.*)|(.*)outbrain(.*)|(.*)pinterest/0.(.*)|(.*)developers.google.com/+/web/snippet(.*)|(.*)www.google.com/webmasters/tools/richsnippets(.*)|(.*)slackbot(.*)|(.*)vkShare(.*)|(.*)W3C_Validator(.*)|(.*)redditbot(.*)|(.*)Applebot(.*)|(.*)WhatsApp(.*)|(.*)flipboard(.*)|(.*)tumblr(.*)|(.*)bitlybot(.*)|(.*)SkypeUriPreview(.*)|(.*)nuzzel(.*)|(.*)Discordbot(.*)|(.*)GooglesPagesSpeed(.*)|(.*)Qwantify(.*)|(.*)GooglesStructuredsDatasTestingsTool'
	);
	const isBot =
		botRegx.test(window.navigator.userAgent) ||
		botRegx.test(window.navigator.userAgent.toLowerCase());

	return isBot;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
	avoidBaseLayout?: boolean;
	baseLayoutProps: BaseLayoutProps;
};

export type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};



const getUnlmtdPage = (
	Comp: NextComponentType<NextPageContext, any, any> &
		NextPageWithLayout<{}, {}>,
	pageProps: any
) => {
	if (Comp.getLayout) {
		return Comp.getLayout(<Comp {...pageProps} />);
	}
	if (!Comp.getLayout && !Comp.avoidBaseLayout) {
		return (
			<UnlmtdBaseLayout {...Comp.baseLayoutProps}>
				<Comp {...pageProps} />
			</UnlmtdBaseLayout>
		)
	}

	return <Comp {...pageProps} />;
};

dataLayerPush({ medium: 'mweb' });

function UnlmtdApp({ Component, pageProps, router, ...rest }: AppPropsWithLayout) {
	const dispatch = useLencoDispatch();
	const { selectedLocationSyncState, selectedLocation, locationActions } =
		useLocation();
	const { getUserApiState, ghostUser, user, userActions } = useUser();
	const { authFlowViewState, loginActions } = useLogin();
	const { appConfigActions, appConfig } = useAppConfig();
	const { selectedPlanId,planActions }=usePlan();

	const moEngage = useMoEngage();
	// @ts-ignore
	const envConfig = ENV;

	function checkLocationAndGetUser() {
		if (selectedLocation.cityId && selectedLocation.pincode) {
			userActions.getUser();
		} else {
			const isBot = isClientBot();
			if (isBot) {
				locationActions.updateSelectedLocation({
					pincode: 560068,
					cityId: 1,
					cityName: 'Bengaluru',
				});
			} else {
				// locationActions.triggerCitySelector(
				// 	LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
				// );
			}
		}
	}
	function initiateSentry() {
		if (envConfig.MODE === 'production') {
			window?.SENTRY_RELEASE({
				environment: envConfig.MODE,
				dsn: envConfig.sentry.dsn,
				release: `grogu@${envConfig.MODE}-${envConfig.GITHUB_COMMIT_SHA}`,
				attachStacktrace: true,
				sampleRate: 0.25,
			});
		}
	}

	function closeLoginFlow() {
		loginActions.hideLoginFlow();
	}

	function onMetaLoaded() {
		if ((window as any)?.Moengage) {
			moEngage.init();

			if (!!user) {
				moEngage.setUserId(user.id);
				moEngage.updateUserAttributes({
					firstName: user.first_name,
					email: user.email_id,
					mobile: user.contact_no,
					userName: user.first_name,
				});
			} else if (!!ghostUser) {
				moEngage.setUserId(ghostUser?.id as number);
			}
		}
	}

	function onRouteChange() {
		appConfigActions.addHistory(router.asPath);
	}

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChange);
		return () => {
			router.events.off('routeChangeStart', onRouteChange);
		};
	}, []);

	useEffect(() => {
		if(!selectedPlanId){
			if(selectedLocation?.pincode){
				planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_SHOW)
			}
		}
	},[selectedLocation,selectedPlanId])

	useEffect(() => {
		if (appConfig.tokenValidity == 'INVALID') {
			userActions.resetSession(selectedLocation).then(() => {
				window.location.reload();
			});
		}

	}, [appConfig.tokenValidity]);

	useEffect(() => {
		if (getUserApiState == ApiState.SUCCESS) {
			onMetaLoaded();
		}
	}, [getUserApiState]);

	useEffect(() => {
		if (selectedLocationSyncState == ApiState.COMPLETED) {
			checkLocationAndGetUser();
		}
	}, [selectedLocationSyncState]);

	useEffect(() => {
		dispatch(readUserSelectedLocationFromStorageAction());
		// initiateSentry();
	}, []);

	// if (getUserApiState == ApiState.LOADING) {
	// 	return <LencoLoaderComponent />;
	// }

	return (

		<ThemeProvider theme={unlmtdTheme}>
				<PlanSelectorDrawer/>
				<Notifications />
				{getUnlmtdPage(Component, pageProps)}
				<UnlmtdLocationSelector />
				<UnlmtdAuthDrawer
					open={authFlowViewState == ViewStates.VISIBLE}
					onClose={closeLoginFlow}
				/>
				<WithRouterConfig>
					<ChatBotFloatingButton/>
				</WithRouterConfig>
		</ThemeProvider>

		
	);
}

export default WithSubscription(WithCitySelector(UnlmtdApp));
