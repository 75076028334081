import { Box, Typography } from '@mui/material';
import FlexBox from '../atomic-components/flex-box';
import { getColor } from './getColor';
import { getStyle } from './getStyle';

export const Arrow = () => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19 12L5 12'
				stroke='#777777'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M12 19L5 12L12 5'
				stroke='#777777'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

function PagesHeader(props: {
	name: string;
	handleTenureSelectorClose?: any;
	handleClose?: any;
}) {
	return (
		<FlexBox
			sx={{
				borderRadius: '28px',
				background: '#ffffff',
				padding: '18px 24px',
				justifyContent: 'start',
				alignItems: 'center',
				boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.05)',
				width: '100%',
			}}
		>
			<Box
				sx={{ marginRight: '8px' }}
				onClick={() =>
					props.handleTenureSelectorClose
						? props.handleTenureSelectorClose()
						: props.handleClose()
				}
			>
				<Arrow />
			</Box>
			<Typography
				sx={{ ...getStyle('H14-Medium'), color: getColor('Gray -2') }}
			>
				{props.name}
			</Typography>
		</FlexBox>
	);
}

export default PagesHeader;
