import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import { Button, ButtonProps, SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useMemo } from 'react';
import FlexBox from '../flex-box';

type TButtonVariants = 'text' | 'contained' | 'outlined';
type TButtonTypes = 'primary' | 'secondary';

type IButtonTyleStyleMap = Record<TButtonTypes, SxProps>;

const defaultContainedStyle = {
	width: '100%',
	borderRadius: 30,
	padding: '0px 24px',
	...getStyle('P-SemiBold'),
};

const outlinedButtonStyle: IButtonTyleStyleMap = {
	primary: {
		...defaultContainedStyle,
		background: 'transparent',
		border: `1px solid ${getColor('Minty 0')}`,
		color: getColor('Minty 0'),
	},
	secondary: {},
};

const defaultWrapperSx: SxProps = {
	width: '100%',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
};

const textButtonStyle: IButtonTyleStyleMap = {
	primary: {
		...getStyle('P-Medium'),
		color: getColor('Minty 0'),
		textTransform: 'initial',
	},
	secondary: {},
};

const containedButtonStyle: IButtonTyleStyleMap = {
	primary: {
		...defaultContainedStyle,
		backgroundColor: getColor('Minty 0'),
		color: getColor(''),
	},
	secondary: {
		...defaultContainedStyle,
		color: getColor('Minty 0'),
		backgroundColor: getColor(''),
	},
};

interface ILencoButtonProps extends ButtonProps {
	isLoading?: boolean;
	children: ReactNode;
	wrapperSx?: SxProps;
	width?: string;
	height?: string;
	fullWidth?: boolean;
	href?: string;
	loaderWrapperSx?: SxProps;
}

const LencoButton = (props: ILencoButtonProps) => {
	function getButtonStyle(): SxProps {
		const { variant = 'contained', type: buttonType = 'primary' } = props;

		if (props.disabled) {
			return defaultContainedStyle;
		} else {
			switch (variant) {
				case 'contained': {
					return containedButtonStyle[buttonType as TButtonTypes];
				}
				case 'outlined': {
					return outlinedButtonStyle[buttonType as TButtonTypes];
				}
				case 'text': {
					return textButtonStyle['primary'];
				}
				default: {
					return containedButtonStyle[buttonType as TButtonTypes];
				}
			}
		}
	}

	const style = useMemo(() => {
		const width = props.fullWidth ? '100%' : props.width;
		const height = props.height ?? '48px';

		return {
			// ...getButtonStyle(),
			...(width && { width }),
			...(height && { height }),
			...props.sx,
		};
	}, [props.width, props.disabled, props.sx]);

	return (
		<Button
			{...props}
			variant={props.variant || 'contained'}
			// color={props.color ?? 'primary'}
			sx={style}
			disabled={props.disabled || props.isLoading}
		>
			{props.isLoading ? (
				<Box sx={{ ml: '10px', ...props.loaderWrapperSx }}>
					<div className='circular-loader'></div>
				</Box>
			) : (
				<FlexBox
					justify='center'
					align='center'
					sx={{
						...defaultWrapperSx,
						...props.wrapperSx,
					}}
				>
					{props.children}
				</FlexBox>
			)}
		</Button>
	);
};

export default LencoButton;
