import { useLencoSelector } from '@/cutom-hooks/use-lenco-selector';
import { useLencoDispatch } from '@/cutom-hooks/use-lenco-dispatch';
import { NotifyToastProps } from '@/components/NotifyToast';
import { NOTIFCATION_TOAST } from '@/store/appConfig/actionTypes';

type UseNotification = {
  notifications: NotifyToastProps[];
  notifyActions: {
    notifyUser: (toast: NotifyToastProps) => void;
    closeNotification: (id: string) => void;
  };
};

export function useNotification(): UseNotification {
  const dispatch = useLencoDispatch();

  const { notifications } = useLencoSelector((store) => ({
    notifications: store.appConfig.notifications,
  }));

  function notifyUser(toast: NotifyToastProps) {
    const toastId = toast.id ?? crypto.randomUUID();
    const newToast: NotifyToastProps = {
      ...toast,
      id: toastId,
    };
    dispatch({
      type: NOTIFCATION_TOAST.ADD_TOAST,
      payload: newToast,
    });
    setTimeout(() => {
      closeNotification(toastId);
    }, 2000);
  }

  function closeNotification(id: string) {
    const updatedNotifications = (notifications?[...notifications] : [notifications]).filter(
      (notification) => notification?.id != id
    );

    dispatch({
      type: NOTIFCATION_TOAST.REMOVE_TOAST,
      payload: updatedNotifications,
    });
  }

  return {
    notifications,
    notifyActions: {
      notifyUser,
      closeNotification,
    },
  };
}
