import FlexBox from "@/atomic-components/flex-box";
import Loader from "@/atomic-components/loaders/bounce-loader";
import UnlmtdLoader from "@/atomic-components/unlmtd";
import { NotificationSeverity } from "@/components/NotifyToast";
import { useCart } from "@/hooks/unlmtd/useCart";
import usePlan from "@/hooks/unlmtd/usePlan";
import { useLocation } from "@/hooks/useLocation";
import { useNotification } from "@/hooks/useNotification";
import { Plan } from "@/store/unlmtd/models/plan";
import { PlanSelectorStatus } from "@/store/unlmtd/slices/planConfigSlice";
import { dataLayerPushWithEvent } from "@/utils/callcustomevent";

import { getColor } from "@/utils/getColor";
import { getStyle } from "@/utils/getStyle";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const shouldRedirect = ['/unlmtd']

function PlanTile({data,isSelected,source}:{data:Plan,isSelected:boolean,source:string}) {
    const theme=useTheme();
    const { selectedPlanId,planActions } = usePlan()
    const {selectedLocation} = useLocation();
    const {cart,cartActions,totalCartItems} = useCart();
    const router = useRouter();
    const {notifyActions} = useNotification();
    const [fetching,setFetching] = useState<boolean>(false)
    const onSelectPlan=async ()=>{
        
        setFetching(true)
        try{
        planActions.setPlan(String(data?.id))
        dataLayerPushWithEvent('UNLMTD Plan Selected',{
            city:selectedLocation?.cityName,
            plan:data?.label,
            plan_value:data?.salePrice?.value
        })
        planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_HIDE)
        await cartActions.fetchCart();
        dataLayerPushWithEvent('moe_event',{moe_event_name:'UNLMTD Plan Selected',
            moe_event_data:{
            plan:data?.label,
            plan_value:data?.salePrice?.displayValue,
            city:selectedLocation?.cityName,
            position:source,
            plan_state:selectedPlanId ? 'select plan':totalCartItems==data?.totalProductCount? 'view cart' : 'browse catalog' 
        }})
        notifyActions.notifyUser({
            message: 'Plan Selected',
            severity: NotificationSeverity.INFO,
        })
        
        }
       catch(e){
           notifyActions.notifyUser({
               message: 'Something went wrong',
               severity: NotificationSeverity.ERROR,
           })
       }
       finally{
            setFetching(false)
            if(shouldRedirect?.includes(window?.location?.pathname)){
            router?.push(`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`)

            }
            // cart?.cart?.data?.totalCartItems == data?.totalProductCount ? router?.push('/unlmtd/cart') :router?.push(`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`)
       }
    }

    return (
        <Box sx={{padding:'24px 28px',background:isSelected?'linear-gradient(90.02deg, #702E65 0.01%, #6653A4 99.99%)':data?.stateConfig?.webBackgroundColors,borderRadius:'8px',margin:'16px 24px'}}>
            <FlexBox sx={{justifyContent:'space-between',alignItems:'center',pb:'24px'}}>
                <Box>
                    <Typography sx={{color:getColor('')}}><span style={{...getStyle('H9A-SemiBold')}}>{data?.totalProductCount}</span> <span style={getStyle('H10A-Regular')}>Items</span></Typography>
                    <Typography sx={{...getStyle('Tiny-Regular'),color:getColor('')}}><span style={getStyle('Tiny-SemiBold')}>₹ {Math.floor(data?.salePrice?.value / data?.totalProductCount)}</span> per product</Typography>    
                </Box>
                <Box sx={{textAlign:'right'}}>
                    <Typography sx={{...getStyle('H10A-SemiBold'),color:getColor('')}}>{data?.salePrice?.displayValue}</Typography>
                    <Typography sx={{...getStyle('Tiny-Regular'),textDecoration:'line-through',color:getColor('')}}>{data?.strikePrice?.displayValue}</Typography>
                </Box>
            </FlexBox>
            <FlexBox sx={{justifyContent:'space-between',alignItems:'center',}}>
                 <Box>
                    <Typography sx={{background:isSelected?'linear-gradient(90deg, #9D8AD6 0%, #CFC5EB 100%)':'linear-gradient(90deg, #934482 0%, #C072AC 100%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',...getStyle('H11A-SemiBold')}}>{data?.label}</Typography>
                    <Typography sx={{color:theme.colors.lencoSecondaryN5,...getStyle('Tiny-Regular')}}>{data?.stateConfig?.subText?.text}</Typography>    
                </Box>
                <Box>   
                    <Button
                    sx={{border:`1px solid ${theme.colors.lencoSecondaryN1}`,backgroundColor: isSelected? theme?.colors?.lencoSecondaryP2 : theme.colors.lencoSecondaryP0,borderRadius:'8px',color:'white',...getStyle('Small-SemiBold'),padding:'11px 12px',maxHeight:'34px'
                    
                    ,':hover':{
                        backgroundColor:isSelected? theme?.colors?.lencoSecondaryP2 : theme.colors.lencoSecondaryP0
                    }}} onClick={()=>{
                        isSelected ?  cart?.cart?.data?.totalCartItems == data.totalProductCount ? router?.push(`/unlmtd/cart`)  : router?.push(`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`)  : onSelectPlan()
                    }}>{fetching ? <Box sx={{}}>
					<div className='circular-loader-unlmtd'></div>
				</Box> :isSelected? cart?.cart?.data?.totalCartItems == data.totalProductCount ? 'View Cart': 'Browse Catalog' :'Select Plan'}</Button>
                </Box>
            </FlexBox>    
        </Box>
    );
}

export default PlanTile;