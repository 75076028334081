import Typography from '@mui/material/Typography';
import { getStyle } from '@/utils/getStyle';
import { getColor } from '@/utils/getColor';
import FlexBox from '@/atomic-components/flex-box';
import { Box } from '@mui/material';
import { LencoImage } from '@/atomic-components/image';
import { useRouter } from 'next/router';
import CustomAccordion from './customAccordian';
import { useLocation } from '@/hooks/useLocation';
import { CategoryInterface } from '@/services/category/model';

type CategoriesProps = {
	subCategory: CategoryInterface;
	closeDrawer: () => void;
	expandCategories: () => void;
	config: any;
};

export default function Categories(props: CategoriesProps) {
	const subCategory = props.subCategory;

	const router = useRouter();
	const { selectedLocation } = useLocation();

	const getTheUrlPathBasedOnVertical = (lop: string) => {
		if (lop == 'rent') {
			return selectedLocation.cityName;
		} else {
			return lop;
		}
	};

	const navigateToCategory = (categoryName: string, lop: string) => {
		props?.closeDrawer();
		const lineOfProduct = lop == 'CATEGORY_BUY' ? 'buy' : 'rent';
		const urlPath = getTheUrlPathBasedOnVertical(lineOfProduct);
		const url = `${urlPath}/${categoryName}`;
		return router.push(url);
	};

	const titleStyle = {
		name: subCategory.label,
		style:
			props.config.sublabel == subCategory.label ? 'P-Medium' : 'P-Regular',
		color: props.config.sublabel == subCategory.label ? 'Minty 0' : 'Gray 0',
	};

	return (
		<Box sx={{ width: '100%', overflow: 'hidden' }}>
			<FlexBox
				sx={{
					justifyContent: 'space-between',
					width: '100%',
					alignItems: 'center',
				}}
			>
				<CustomAccordion
					title={titleStyle}
					image={subCategory?.image}
					divider={titleStyle.name != props.config.sublabel}
					expandCategories={() => props.expandCategories()}
					height={titleStyle.name == props.config.sublabel ? 'initial' : '44px'}
				>
					<FlexBox
						justify='start'
						direction='row'
						align='start'
						gap={2}
						sx={{
							flexWrap: 'wrap',
							background:
								'linear-gradient(180deg, rgba(242, 250, 251, 0.00) 0%, #F2FAFB 100%)',
							padding: '10px 0px',
						}}
					>
						{subCategory?.children?.map(
							(categoryData: CategoryInterface, index: number) => {
								return (
									<FlexBox
										direction='column'
										sx={{
											marginRight: '4px',
											width: '50px',
											alignItems: 'center',
										}}
										onClick={() =>
											navigateToCategory(
												categoryData?.name,
												categoryData?.collectionType
											)
										}
										key={index}
									>
										<Box
											sx={{
												width: '40px',
											}}
										>
											<LencoImage
												url={categoryData?.image?.url}
												aspectRatio={categoryData?.image?.aspectRatio}
											/>
										</Box>
										<FlexBox
											sx={{
												width: '50px',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography
												sx={{
													...getStyle('Small-Regular'),
													color: getColor('Gray 0'),
													display: '-webkit-box;',
													WebkitLineClamp: 2,
													WebkitBoxOrient: 'vertical',
													overflow: 'hidden',
													whiteSpace: 'wrap',
													wordBreak: 'break-word',
													textAlign: 'center',
												}}
											>
												{categoryData?.label}
											</Typography>
										</FlexBox>
									</FlexBox>
								);
							}
						)}
					</FlexBox>
				</CustomAccordion>
			</FlexBox>
		</Box>
	);
}
