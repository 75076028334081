// import { NotifyToastProps } from '@/components/NotifyToast';
import { NotifyToastProps } from '@/components/NotifyToast';
import { AUTH_FLOW_TRIGGER, NOTIFCATION_TOAST, APP_HISTORY, UPDATE_TOKEN_VALIDITY } from './actionTypes';
import { ViewStates } from '@/models/generics';

export type TokenValidity = 'VALID' | 'INVALID';

export type AppConfig = {
	authFlowViewState: ViewStates;
	notifications: NotifyToastProps[];
	history: string[];
	onLogin?: () => void;
	tokenValidity: TokenValidity;
};

const initialState: AppConfig = {
	authFlowViewState: ViewStates.NOT_VISIBLE,
	notifications: [],
	history: [],
	onLogin: undefined,
	tokenValidity: 'VALID'
};

export default function AppConfigReducer(state = initialState, action: any): AppConfig {
	switch (action.type) {
		case AUTH_FLOW_TRIGGER.OPEN_AUTH_FLOW: {
			return {
				...state,
				authFlowViewState: ViewStates.VISIBLE,
				onLogin: action.onLogin
			};
		}
		case AUTH_FLOW_TRIGGER.CLOSE_AUTH_FLOW: {
			return {
				...state,
				authFlowViewState: ViewStates.NOT_VISIBLE,
				onLogin: undefined,
			};
		}
		case NOTIFCATION_TOAST.ADD_TOAST: {
			return { ...state, notifications: [...state.notifications, action.payload] };
		}
		case NOTIFCATION_TOAST.REMOVE_TOAST: {
			return { ...state, notifications: action.payload };
		}
		case APP_HISTORY.ADD_TO_APP_HISTORY: {
			return {
				...state,
				history: [...state.history, action.payload]
			};
		}
		case UPDATE_TOKEN_VALIDITY.TOKEN_INVALID: {
			return {
				...state,
				tokenValidity: 'INVALID'
			};
		}
		// case NOTIFCATION_TOAST.REMOVE_TOAST: {
		//     return { ...state, notifications: [...state.notifications, action.payload] };
		// }
		default: {
			return state;
		}
	}
}